import React from 'react'
import PropTypes from 'prop-types'

const CtaStripe = ({bodyText, buttonText, buttonLink}) => {
  return (
    <div className="container-fluid py-4 mt-5 casestudy">
        <div className="col-lg-12 text-white text-center">
          <div className="row">
            <div className="col-lg-2" />
            <div className="col-lg-4">
              <h5 className="pt-3 text-white">
                {bodyText}
              </h5>
            </div>
            <div className="col-lg-4">
              <a href={buttonLink} className="btn btn-secondary">
                {buttonText}
              </a>
            </div>
            <div className="col-lg-2" />
          </div>
        </div>
      </div>
  )
}

CtaStripe.propTypes = {
  bodyText: PropTypes.string,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string
}

CtaStripe.defaultProps = {
  bodyText: 'Read our Customer Stories',
  buttonText: 'VIEW CUSTOMER STORIES',
  buttonLink: '/case-study-index'
}

export default CtaStripe
