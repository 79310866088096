import React from 'react'
import Logo from '../../Component/Logo'
import NavBar from '../../Component/Navbar'

const Header = () => {

  return (
    <nav className="navbar navbar-light navbar-expand-lg navbar-fixed-top fixed-top">
      <Logo />
      <div className="container">
        <Logo view="mobile" />
        <NavBar />
      </div>
    </nav>
  )
}

export default Header
