import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import ModalVideo from 'react-modal-video'
import CtaStripe from './CtaStripe'
import BlogListing from './BlogListing'
import BlogHeader from './BlogHeader'
import client from './Contentful'
import ClsProductCards from './ProductCards'
import CaseStudyTeaser from './CaseStudyTeaser'
import PDF from '../Pdf/ARCUS Supplier Information Management System Fact Sheet.pdf'
import DocumentMeta from 'react-document-meta'
import FaqLists from './FaqLists'
import CarouselHeader from './CarouselHeader'
import MiniCard from './MiniBlogCard'
import Loader from './Loader'




const ContractorManagement = () => {
  const [relatedBlogs, setRelatedBlogs] = useState([])
  const [extraBlogs,setExtraBlogs] = useState([])
  const [caseStudy, setCaseStudy] =  useState([])
  const [isOpen, setOpen] = useState(false)
  const location = useLocation()

  const faqMappingUrl = location.pathname;
  const faqListQuery = gql`
  query FaqLists ($url: String!){
    addOnFaqListCollection(where:{addOnFaqListSlug: $url}){
      items{
        faqList{
          title
          listCollection{
            items{
              question
              answerText
            }
          }
        }
      }
    }
  }
  `

  const blogpostCardMiniQuery = gql`
query BlogpostCardMini {
  blogPostCollection(where:{blogPostProduct_contains_all:"SIM", SEOIndex:true} order:[publishDate_DESC]){
    total
    items{
      title
      slug

    }
  }

}
`

const pageBuildQuery = gql`
query PageBuildQuery{
pageBuilderCollection(where: {SEOIndex:true, pageBuilderProduct_contains_all:"SIM"} ){
 total
 items{
   title
   slug
   __typename
   pageBanner {
     bannerText
   }
 }
}
}`

  const { data:faqDataMain } = useQuery(faqListQuery, {
    skip: !location || !location.pathname,
    variables: {
      url:
        location && location.pathname
          ? faqMappingUrl.slice(1)
          : null,
    },
  });


  const { data:blogGraphData } = useQuery(blogpostCardMiniQuery)

  const {data: pagebuilderData} = useQuery(pageBuildQuery)


  const faqData = faqDataMain?.addOnFaqListCollection?.items[0]?.faqList

  const seoData = faqDataMain?.addOnFaqListCollection?.items[0]?.faqList?.listCollection?.items


  let mainEntity = []
   seoData&&
    seoData.map((res,id)=> {
    return mainEntity[id] = {
        "@type": "Question",
        "name": res.question,
        "acceptedAnswer": {
          "@type": "Answer",
          "text": res.answerText,
        }
      }
    })

 let schema = mainEntity.length === 0 ?  null : {
     "@context": "https://schema.org",
     "@type": "FAQPage",
     "mainEntity": mainEntity
   }

  const path = location.pathname
  const url = path.substr(path.lastIndexOf('/') + 1)
  localStorage.setItem('ContractorManagement',`/${url}`)

  useEffect(() => {

    client.getEntries({
      content_type: 'blogPost',
      order:'-fields.publishDate',
      'fields.blogPostProduct[nin]': 'SIM',
      limit: 3
    })
    .then((response)=>{
     const latestBlogs = response.items.map(blg => {
        return {
          title: blg.fields.title,
          date: blg.fields.publishDate,
          shortDesc: blg.fields.description,
          bloglink: blg.fields.slug,
          imageSrc: blg.fields.heroImage.fields.file.url,
          altDesc: blg.fields.heroImage.fields.description,
           estimatedreadtime: blg.fields.estimatedreadtime
        }
      })
      setExtraBlogs(latestBlogs)
    }).catch(console.error)

    client.getEntries({
      content_type: 'blogPost',
      order:'-fields.publishDate',
      'fields.blogPostProduct': 'SIM',
      limit: 3
    })
    .then((response)=>{
     const initialBlogs = response.items.map(blg => {
        return {
          title: blg.fields.title,
          date: blg.fields.publishDate,
          shortDesc: blg.fields.description,
          bloglink: blg.fields.slug,
          imageSrc: blg.fields.heroImage.fields.file.url,
          altDesc:blg.fields.heroImage.fields.description,
          estimatedreadtime: blg.fields.estimatedreadtime
        }
      })
      setRelatedBlogs(initialBlogs)
    }).catch(console.error)

    client.getEntries({
      content_type: 'casestudy',
      order: 'fields.casestudyorderpriority',
      'fields.casestudyproduct': 'SIM',
      limit: 1
    })
    .then((response) => {
      const caseStudyData = response.items.map(csItem => {
        return {
          slug: csItem.fields.slug,
          nameofquotedperson:csItem.fields.nameofquotedperson,
          quotedpersonjobtitle:csItem.fields.quotedpersonjobtitle,
          organisation:csItem.fields.organisation,
          calloutquote:csItem.fields.calloutquote,
          extractforteaser:csItem.fields.extractforteaser
        }
      })
      setCaseStudy(caseStudyData[0])
    } )
    .catch(console.error)
  },[])



  if (!faqDataMain || !blogGraphData || !pagebuilderData) {
    return <Loader />;
  }


  const blogBoxData = blogGraphData?.blogPostCollection?.items

  const pageBuildData = pagebuilderData?.pageBuilderCollection?.items

  const blogCardData = [...blogBoxData, ...pageBuildData]



const bloglist = (relatedBlogs.length < 3) ? [...relatedBlogs,...extraBlogs].slice(0, 3) : relatedBlogs

const downloadPDF = () => {
      fetch(PDF)
        .then(response => {
          response.blob().then(blob => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            var sub = PDF.substr(14);
            a.download =sub;
            a.click();
          });
      });
    }

    const meta = {
      title: 'Rasp | Explore Our Supplier Information Management System',
      description: 'At Rasp, we specialise in procurement tools including a supplier information management system. Find out how we help you achieve supply chain resilience.',
      meta: {
        charset: 'utf-8',
        name: {
          keywords: 'Supplier Information Management'
        }
      }
    }

    return(
      <DocumentMeta {...meta}>
      {schema && (
          <div
          dangerouslySetInnerHTML={{
            __html: `
              <script type="application/ld+json">${JSON.stringify(
                schema
              )}</script>
              `
            }}
          />
      )}
      <div>
      {/* <!====================================text start================================>  */}

      <div className="bgshadow3 pt-5">{/*-shadow start here-*/}
        <div className="container mt-5 text-center">
          <div className="row">
            <div className="col-lg-6 m-auto"><img src="images/Product Logos/SIM Logo.svg" className="img-fluid mt-2" alt="ARCUS Supplier Information Management Logo" /></div>
            <div className="col-lg-6 mt-2 mt-sm-4 text-left center">
              <h2 className="font-weight-bold"><hr id="arcus" className="m-lg-0 mt-n5 pb-2" />ARCUS SIM</h2>
              <h3>Supplier Information Management</h3>
            </div>
          </div>

      {/*-----------horizontal divider-------------------------------*/}

      <div className="container"><hr /></div>
        <div className="row">
          <div className="col-lg-6 ">
            <p className="font-weight-normal text-left px-lg-5 text6">Effortlessly record and manage information about suppliers and contractors. SIM creates immediate efficiencies on traditionally time-intensive and error-prone manual tasks.</p>
          </div>
          <div className="col-lg-6">
            <p className="font-weight-lighter text-left font-italic clr  px-lg-5 text6">"We needed a solution that would help us with the administrative burden of managing and maintaining a large, diverse selection of suppliers" <br /><br />
              <span className="small text3">Phil Hirst, Vendor Governance Manager, Sodexo UK &amp; Ireland</span></p>
          </div>
        </div>
        <div className="text-center mt-5">
          <h3 className="font-weight-bold "><hr id="arcus" />What is it?</h3>
        </div>
        <div className="text-left center mt-5">
          <p className="clr">ARCUS Supplier Information Management (SIM) is a highly configurable, cloud based system designed to support a variety of vendor and contractor processes with the aims of maximising the use of your established supplier* base and protecting your organisation from supplier induced risk.</p>
          <div className="row">
            <div className="col-lg-6"><p className="clr">Typically ARCUS SIM is implemented to provide one or more of the following capabilities:</p></div>
            <div className="col-lg-6" /></div>
        </div>
      </div>


    {/*---------------------------------sim dashboard start-----------------------------------*/}

    <div className="container-fluid p-0">
        <div className="mt-5">
          <div className="row reverse">
            <div className="col-lg-6 m-auto">
              <div className="row">
                <div className="col-lg-3 hide-at" />
                <div className="col-lg-9">
                  <div className><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2 ml-3" alt="" /><p className="font-weight-bold"><span className="ml-3">Supplier Registration</span></p></div>
                  <div className><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2 ml-3" alt="" /><p className="font-weight-bold"><span className="ml-3">Supplier Pre-Qualification</span></p></div>
                  <div className><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2 ml-3" alt="" /><p className="font-weight-bold"><span className="ml-3">Supplier Approval / Accreditation</span></p></div>
                  <div className><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2 ml-3" alt="" /><p className="font-weight-bold"><span className="ml-3">Supplier Compliance Management</span></p></div>
                  <div className><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2 ml-3" alt="" /><p className="font-weight-bold"><span className="ml-3">Supplier Product Management</span></p></div>
                  <div className><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2 ml-3" alt="" /><p className="font-weight-bold"><span className="ml-3">Supplier Risk Management</span></p></div>
                  <div className><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2 ml-3" alt="" /><p className="font-weight-bold"><span className="ml-3">Supplier Performance</span></p></div>
                  <div className><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2 ml-3" alt="" /><p className="font-weight-bold"><span className="ml-3">Supplier Enterprise-Wide Visibility</span></p></div>
                  <div className><p className="clr ml-5">* For convenience, the term 'supplier' is used to represent supplier, vendor, contractor, sub-contractor, consultant etc.</p></div>
                </div></div></div>
            <div className="col-lg-6 text-center p-0"><img src="images/SIM Dashboard Graphic@2x.png" className="img-fluid" alt="contractor management dashboard" />
            <ModalVideo channel='vimeo' autoplay isOpen={isOpen} videoId="451711568" onClose={() => setOpen(false)} />
            <button className="btn btn-primary my-5 spacing Rbtnmargin video-btn" onClick={()=> setOpen(true)}>VIEW DEMO</button>
            </div>
          </div>
        </div>
      </div>

    {/*---------------------------------sim dashboard end-----------------------------------*/}

    {/*-----------------------------sim questionnaire start-----------------------------*/}

    <div className="container-fluid">
        <div className="row reverse">
          <div className="col-lg-6 text-center p-0">
            <img src="images/SIM Questionnaire Graphic@2x.png" className="img-fluid mt-5" alt="Contractor management questionnaire" />
            <button type="button" onClick={downloadPDF} className="btn btn-primary my-5 Lbtnmargin">DOWNLOAD FACTSHEET</button>
          </div>
          <div className="col-lg-6 mt-3 px-3 m-auto">
            <div className="mx-4 mt-sm-4 text-left center">
              <h2 className="font-weight-bold"><hr id="arcus" className="m-lg-0 mt-n5 pb-2" />How does it work?</h2>
            </div>
            <div className="mt-4"><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">Suppliers are invited to complete online questionnaires that are designed and built to your organisation's requirements.</p></div>
            <div className="mt-4"><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">The supplier approval workflow is configured and automated to reflect your process steps.</p></div>
            <div className="mt-4"><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">Ongoing maintenance and updating of supplier information is undertaken by the suppliers themselves.</p></div>
            <div className="mt-4"><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">Suppliers are sent automated update reminders that are triggered by the system - e.g. insurance expiry.</p></div>
            <div className="mt-4"><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">A comprehensive dashboard presents you with a bird's eye view of the status of your entire supplier base.</p></div>
            <div className="mt-4"><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">A dynamic easy-to-use reporting system allows filtering, searching, sorting, grouping and exporting on any data held in the system.</p></div>
            <div className="mt-4"><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">A full audit log of data changes is kept for each supplier.</p></div>
            <div className="mt-4"><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">You manage your suppliers by exception.</p></div>
            <div className="mt-4"><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">You own the data.</p></div>
          </div>
        </div>
      </div>
      {/*-shadow end here-*/}
  </div>

  {/* <!-------------------------------sim questionnaire end--------------------------------->



     <!====================================text end================================>  */}
     {/* ==========================faqlist start============================ */}

          {faqData && (<div className="col-lg-6 m-auto py-3">
            <FaqLists {...faqData}/>
          </div>)
          }

     {/* ==========================faqlist end============================ */}

<div className="container bgshadow">
    {/* <!==============================Product Cards start============================>  */}

      <ClsProductCards></ClsProductCards></div><br/><br/>

     {/* <!==============================Product Cards End============================>  */}

     {/* <!==============================case study with city start==============================>  */}

     {caseStudy && (
       <CaseStudyTeaser {...caseStudy} />
      )}

     {/* <!==============================case study with city end==============================>  */}

     <BlogHeader />

     {/* <!==============================blog Cards start==============================>   */}

     <BlogListing bloglist={bloglist} />

     {/* <!==============================blog Cards end==============================>   */}

     <CarouselHeader/>

       <div className="container mt-4">
         <div className="container col-lg-12">
           {
             blogCardData&& <MiniCard data={blogCardData} />
           }

         </div>
         </div>

      <CtaStripe />
     </div>
  </DocumentMeta>
  )
}
export default ContractorManagement;
