import React from 'react';
import './App.css';
import Layout from '../src/Layout'
import Home from './Component/Home';
import VideoPopUp from './Component/VideoPopUp';
import CaseStudy from './Component/CaseStudyIndex';
import CaseStudyDetails from './Component/CaseStudyDetails'
import SolutionsSupplyChainSoftware from './Component/Solutions-Supply-Chain-Software';
import FoodIndustrySoftware from './Component/FoodIndustrySoftware'
import Contractormanagement  from'./Component/Contractor-management';
import ProductManagement from './Component/ProductManagement';
import SupplierPerformance from './Component/SupplierPerformance'
import Etender from './Component/eTender';
import ContactUS from './Component/ContactUs';
import contractmanagement from './Component/Contract-management';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import ReverseAuction from './Component/reverse-auction-eauction';
import Insights from './Component/Insights'
import BlogDetails from './Component/BlogDetails'
import AboutUs from './Component/AboutUs'
import Privacy from './Component/Privacy'
import SourceManagement from './Component/SourceManagement'
import PageBuilder from './Component/PageBuilder'

function App() {
	return (
		<Router>
            <Layout>
            <div className="mainarea" >
                {/* <Route exact path="/" render={() => (
                    <Redirect to="/home" />
                )} /> */}

                <Switch >

                    <Route exact path='/' component={Home} />
                    <Route exact path='/videopopup' component={VideoPopUp} />
                    <Route autocapitalize="none" exact path='/case-study-index' component={CaseStudy} />
                    <Route exact path='/supplier-management-platform' component={SolutionsSupplyChainSoftware} />
                    <Route exact path='/supplier-information-management' component={Contractormanagement} />
                    <Route exact path='/food-industry-software' component={FoodIndustrySoftware} />
                    <Route exact path='/product-information-management' component={ProductManagement} />
                    <Route exact path='/supplier-performance-management' component={SupplierPerformance} />
                    <Route exact path='/etender' component={Etender} />
                    <Route exact path='/page/:slug'component={PageBuilder} />
                    <Route exact path='/about-us' component={AboutUs} />
                    <Route exact path='/privacy' component={Privacy} />
                    <Route exact path='/contact-us' component={ContactUS} />
                    <Route exact path='/insights' component={Insights} />
                    <Route exact path='/insights/:slug' component={BlogDetails} />
                    <Route exact path='/contract-management' component={contractmanagement} />
                    <Route exact path='/sourcing-management' component={SourceManagement} />
                    <Route exact path='/reverse-auction-eauction' component={ReverseAuction} />
                    <Route exact path='/case-study/:slug' component={CaseStudyDetails} />
                    {/* <Route exact path='/pagebuilder/:slug' component={LandingPage} /> */}
                    <Route component={Home} >
                      <Redirect to="/" />
                    </Route>

                </Switch>
            </div>
            </Layout>
        </Router>
	);
}

export default App;
