import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import ModalVideo from 'react-modal-video'
import CtaStripe from './CtaStripe'
import BlogListing from './BlogListing'
import BlogHeader from './BlogHeader'
import client from './Contentful'
import ClsProductCards from './ProductCards'
import CaseStudyTeaser from './CaseStudyTeaser'
import PDF from '../Pdf/ARCUS PIM Factsheet - Trade Interchange.pdf'
import DocumentMeta from 'react-document-meta'
import FaqLists from './FaqLists'
import CarouselHeader from './CarouselHeader'
import MiniCard from './MiniBlogCard'
import Loader from './Loader'


const ProductManagement = () => {
  const [relatedBlogs, setRelatedBlogs] = useState([])
  const [extraBlogs,setExtraBlogs] = useState([])
  const [isOpen, setOpen] = useState(false)
  const [caseStudy, setCaseStudy] = useState([])

  const location = useLocation()

  const faqMappingUrl = location.pathname;
  const faqListQuery = gql`
  query FaqLists ($url: String!){
    addOnFaqListCollection(where:{addOnFaqListSlug: $url}){
      items{
        faqList{
          title
          listCollection{
            items{
              question
              answerText
            }
          }
        }
      }
    }
  }
  `


  const blogpostCardMiniQuery = gql`
query BlogpostCardMini {
  blogPostCollection(where:{blogPostProduct_contains_all:"PIM" ,SEOIndex:true} order:[publishDate_DESC]){
    total
    items{
      title
      slug

    }
  }

}
`
const pageBuildQuery = gql`
query PageBuildQuery{
pageBuilderCollection(where: {SEOIndex:true, pageBuilderProduct_contains_all:"PIM"} ){
 total
 items{
   title
   slug
   __typename
   pageBanner {
     bannerText
   }
 }
}
}`





  const { data: faqDataMain } = useQuery(faqListQuery, {
    skip: !location || !location.pathname,
    variables: {
      url:
        location && location.pathname
          ? faqMappingUrl.slice(1)
          : null,
    },
  });




  const { data:blogGraphData } = useQuery(blogpostCardMiniQuery)

  const {data: pagebuilderData} = useQuery(pageBuildQuery)

  const faqData = faqDataMain?.addOnFaqListCollection?.items[0]?.faqList

  const seoData = faqDataMain?.addOnFaqListCollection?.items[0]?.faqList?.listCollection?.items


  let mainEntity = []
   seoData&&
    seoData.map((res,id)=> {
    return mainEntity[id] = {
        "@type": "Question",
        "name": res.question,
        "acceptedAnswer": {
          "@type": "Answer",
          "text": res.answerText,
        }
      }
    })

 let schema = mainEntity.length === 0 ? null : {
     "@context": "https://schema.org",
     "@type": "FAQPage",
     "mainEntity": mainEntity
   }

  const path = location.pathname
  const url = path.substr(path.lastIndexOf('/') + 1)
  localStorage.setItem('ProductManagement',`/${url}`)

  useEffect(() => {

    client.getEntries({
      content_type: 'blogPost',
      order:'-fields.publishDate',
      'fields.blogPostProduct[nin]': 'PIM',
      limit: 3
    })
    .then((response)=>{
     const latestBlogs = response.items.map(blg => {
        return {
          title: blg.fields.title,
          date: blg.fields.publishDate,
          shortDesc: blg.fields.description,
          bloglink: blg.fields.slug,
          imageSrc: blg.fields.heroImage.fields.file.url,
          altDesc:blg.fields.heroImage.fields.description,
          estimatedreadtime: blg.fields.estimatedreadtime
        }
      })
      setExtraBlogs(latestBlogs)
    }).catch(console.error)

    client.getEntries({
      content_type: 'blogPost',
      order:'-fields.publishDate',
      'fields.blogPostProduct': 'PIM',
      limit: 3
    })
    .then((response)=>{
     const initialBlogs = response.items.map(blg => {
        return {
          title: blg.fields.title,
          date: blg.fields.publishDate,
          shortDesc: blg.fields.description,
          bloglink: blg.fields.slug,
          imageSrc: blg.fields.heroImage.fields.file.url,
          altDesc:blg.fields.heroImage.fields.description,
          estimatedreadtime: blg.fields.estimatedreadtime
        }
      })
      setRelatedBlogs(initialBlogs)
    }).catch(console.error)

    client.getEntries({
      content_type: 'casestudy',
      order: 'fields.casestudyorderpriority',
      'fields.casestudyproduct': 'PIM',
      limit: 1
    })
    .then((response) => {
      const caseStudyData = response.items.map(csItem => {
        return {
          slug: csItem.fields.slug,
          nameofquotedperson:csItem.fields.nameofquotedperson,
          quotedpersonjobtitle:csItem.fields.quotedpersonjobtitle,
          organisation:csItem.fields.organisation,
          calloutquote:csItem.fields.calloutquote,
          extractforteaser:csItem.fields.extractforteaser
        }
      })
      setCaseStudy(caseStudyData[0])
    } )
    .catch(console.error)

  },[])


  if (!faqDataMain || !blogGraphData || !pagebuilderData) {
    return <Loader />;
  }


  const blogBoxData = blogGraphData?.blogPostCollection?.items

  const pageBuildData = pagebuilderData?.pageBuilderCollection?.items

  const blogCardData = [...blogBoxData, ...pageBuildData]



    const bloglist = (relatedBlogs.length < 3) ? [...relatedBlogs,...extraBlogs].slice(0, 3) : relatedBlogs

    const downloadPDF = () => {
      fetch(PDF)
        .then(response => {
          response.blob().then(blob => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            var sub = PDF.substr(14);
            a.download =sub;
            a.click();
          });
      });
    }

    const meta = {
      title: 'Rasp: Discover Cloud-based Product Information Management',
      description: 'With ARCUS PIM, automate product information management using a cloud-based solution. Collect information, reduce supply chain risk, and ensure compliance.',
      meta: {
        charset: 'utf-8',
        name: {
          keywords: 'Product information management'
        }
      }
    }

    return(
      <DocumentMeta {...meta}>
        {schema && (
          <div
          dangerouslySetInnerHTML={{
            __html: `
              <script type="application/ld+json">${JSON.stringify(
                schema
              )}</script>
              `
            }}
          />
        )}
      <div>
      {/* <!====================================text start================================>  */}

      <div className="bgshadow3 pt-5">{/*-shadow start here-*/}
        <div className="container mt-5 text-center">
        <div className="card-header food ml-auto">
                  <div className="row float-right">
                    <div className="lh">food <br /> industry</div>
                    <div><i className="fa fa-utensils fa-2x" /></div>
                  </div>
          </div>
          <div className="row">
            <div className="col-lg-6 m-auto"><img src="images/Product Logos/PIM Logo.svg" className="img-fluid mt-2" alt="ARCUS Product Information Management logo" /></div>
            <div className="col-lg-6 mt-2 mt-sm-4 text-left center">
              <h2 className="font-weight-bold"><hr id="arcus" className="m-lg-0 mt-n5 pb-2" />ARCUS PIM</h2>
              <h3>Supplier Product Control</h3>
            </div>
          </div>

      {/*-----------horizontal divider-------------------------------*/}

      <div className="container"><hr /></div>
        <div className="row">
          <div className="col-lg-4 ">
            <p className="font-weight-normal text-left px-lg-5 text6">Automate your supplier product information collection using a cloud-based solution.</p>
          </div>
          <div className="col-lg-8">
            <p className="font-weight-lighter text-left font-italic clr  px-lg-5 text6">"We can now request, review, approve and access all product specification information in an efficient way."</p><p className="font-weight-lighter text-left font-italic clr  px-lg-5 text6"> "ARCUS® PIM provides an audit trail of the steps we have taken to mitigate the risk and provides a deeper, more granular level of control and insight over product information in one streamlined solution." <br /><br />
              <span className="small text3">Maria Loftus, Senior Technical Manager, Fridays</span></p>
          </div>
        </div>
        <div className="text-center mt-5">
                    <h3 className="font-weight-bold "><hr id="arcus" />What is it?</h3>
                    </div>
                    <div className="text-left center mt-5 col-lg-6">
                      <p className="clr">The ARCUS® Product Information Management (PIM) module provides quality and technical professionals with the ability to automate the process for collecting detailed information about supplier products, reducing supply chain risk and ensuring compliance thanks to the module’s custom product questionnaires and workflows.</p>
                    </div>

                   </div>


    {/*---------------------------------sim dashboard start-----------------------------------*/}
    <div className="container-fluid p-0">
        <div className="mt-5">
          <div className="row reverse">
            <div className="col-lg-6 mt-lg-5 pt-lg-5">
              <div className="row">
                <div className="col-lg-2 hide-at" />
                <div className="col-lg-10">
                  <p className="ml-3 clr">ARCUS® PIM provides a central product database that allows quality and technical professionals to collect, approve and manage large volumes of product data and specifications. Product questionnaires are tailored to a company’s requirements, and suppliers directly enter vital product information such as allergens, ingredients, nutritional data and pricing information. ARCUS® PIM can also be integrated with third-party ERP, recipe management and menu production systems commonly used in the food and beverage industry, meaning that there is no need to re-key any product specifications that have been entered by suppliers.</p>
                </div></div></div>
            <div className="col-lg-6 text-center p-0"><img src="images/PIM Dashboard Graphic.png" className="img-fluid" alt="Product Lifecycle Management dashboard" />
            <ModalVideo channel='vimeo' isOpen={isOpen} videoId="489690568" onClose={() => setOpen(false)} />
            <button className="btn btn-primary my-5 spacing Rbtnmargin video-btn" onClick={()=> setOpen(true)}>VIEW DEMO</button>
            </div>
          </div>
        </div>
      </div>

    {/*---------------------------------sim dashboard end-----------------------------------*/}

    {/*-----------------------------sim questionnaire start-----------------------------*/}

    <div className="container-fluid">
        <div className="row reverse">
          <div className="col-lg-6 text-center p-0">
            <img src="images/PIM Questionnaire Graphic.png" className="img-fluid mt-5" alt="Product Lifecycle Management questionnaire" />
            <button type="button" onClick={downloadPDF} className="btn btn-primary my-5 Lbtnmargin">DOWNLOAD FACTSHEET</button>
          </div>
          <div className="col-lg-6 mt-3 px-3 m-auto">
            <div className="mx-4 mt-sm-4 text-left center">
              <h2 className="font-weight-bold"><hr id="arcus" className="m-lg-0 mt-n5 pb-2" />What Are The Benefits?</h2>
            </div>
            <div className="mt-4"><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">Reduce risk and ensure compliance</p></div>
            <div className="mt-4"><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">Enhanced search and reporting</p></div>
            <div className="mt-4"><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">Custom product questionnaires and workflows</p></div>
            <div className="mt-4"><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">Products uploaded in batches can be approved one-by-one or in bulk if needed</p></div>
            <div className="mt-4"><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">Multi-department collaboration</p></div>
            <div className="mt-4"><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">Efficient and auditable process</p></div>
            <div className="mt-4"><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">Suppliers enter product information directly</p></div>
            <div className="mt-4"><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">Multi-system integration</p></div>
            <div className="mt-4"><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">Removes silos of product information</p></div>
          </div>
        </div>
      </div>
      {/*-shadow end here-*/}
  </div>

  {/* <!-------------------------------sim questionnaire end--------------------------------->



     <!====================================text end================================>  */}
      {/* ==========================faqlist start============================ */}

          {faqData && (<div className="col-lg-6 m-auto py-3">
            <FaqLists {...faqData}/>
          </div>)
          }

      {/* ==========================faqlist end============================ */}

<div className="container bgshadow">
    {/* <!==============================Product Cards start============================>  */}

      <ClsProductCards></ClsProductCards></div><br/><br/>

     {/* <!==============================Product Cards End============================>  */}

     {/* <!==============================case study with city start==============================>  */}

     {caseStudy && (
        <CaseStudyTeaser {...caseStudy} />
     )}


     {/* <!==============================case study with city end==============================>  */}

    <BlogHeader />

     {/* <!==============================blog Cards start==============================>   */}

     <BlogListing bloglist={bloglist} />

     {/* <!==============================blog Cards end==============================>   */}

     <CarouselHeader/>

       <div className="container mt-4">
         <div className="container col-lg-12">
           {
             blogCardData&& <MiniCard data={blogCardData} />
           }

         </div>
         </div>

      <CtaStripe />
     </div>
     </DocumentMeta>
  )
}
export default ProductManagement;
