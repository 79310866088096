/**
 *
 * @param {object} initialDate
 *
 * @return {string} formatted Date
 */

export const dateFormatter = (initialDate) => {
const getDate = ((typeof initialDate) === 'string') ? initialDate : initialDate.date
const mydate = new Date(getDate);
const month = ["January", "February", "March", "April", "May", "June",
"July", "August", "September", "October", "November", "December"][mydate.getMonth()]
let day = mydate.getDate();
day = (day < 10) ? "0"+day : day
const formttedDate = `${month} ${day}, ${mydate.getFullYear()}`
return formttedDate
}
