import React from 'react'
import BlogListing from './BlogListing'
import BlogHeader from './BlogHeader'
import CtaStripe from './CtaStripe'
import CaseStudyTeaser from './CaseStudyTeaser'
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import 'react-vertical-timeline-component/style.min.css'
import DocumentMeta from 'react-document-meta'
import { useQuery, gql } from "@apollo/client"
import Loader from './Loader'
import CarouselHeader from './CarouselHeader'
import MiniCard from './MiniBlogCard'

const AboutUs = () => {
  const miniCardQuery = gql`
  query MiniCardData {
    blogPostCollection(
      where: {SEOIndex:true}
      order: publishDate_DESC
    ) {
      total
      items {
        title
        slug
        __typename
      }
    }

    pageBuilderCollection(where: {SEOIndex:true}){
    total
    items{
      title
      slug
      __typename

      pageBanner {
        bannerText
      }
    }
  }
  }
  `
  const blogPostsQuery = gql`
  query BlogPosts{
    blogPostCollection(
      order: publishDate_DESC
      limit: 3
    ) {
      items {
        title
        publishDate
        description
        slug
        heroImage {
          url
          description
        }
        estimatedreadtime
      }
    }
    casestudyCollection(
      order: [casestudyorderpriority_ASC]
      limit: 1
    ) {
      items {
        slug
        nameofquotedperson
        quotedpersonjobtitle
        organisation
        calloutquote
        extractforteaser
      }
    }
  }
  `

  const aboutUsTimelineQuery = gql`
  query AboutUsTimeline {
    aboutustimelineCollection(order:position_ASC){
    total
     items{
       title{
         json
       }
       maincontent{
         json
       }
       imagealttext
       iconText
       image{
         url
       }
       imagehyperlink
       buttontext
       buttonlink
     }
   }
   }

  `
  const { data: miniCardDataMain } = useQuery(miniCardQuery)
  const { data: fetchedBlogData } = useQuery(blogPostsQuery)

  const {data: aboutUsTime} = useQuery(aboutUsTimelineQuery)

  if(!miniCardDataMain || !fetchedBlogData || !aboutUsTime) return(<Loader/>)

  const blogPostDataMain = fetchedBlogData?.blogPostCollection
  const caseStudyDataMain = fetchedBlogData?.casestudyCollection

 const blogCardData = miniCardDataMain?.blogPostCollection?.items
 const pageBuildData = miniCardDataMain?.pageBuilderCollection?.items

 const blogGraphData = [...blogCardData,...pageBuildData]

 const relatedBlogs = blogPostDataMain?.items?.map((blg) => ({
  title: blg.title,
  date: blg.publishDate,
  shortDesc: blg.description,
  bloglink: blg.slug,
  imageSrc: blg.heroImage.url,
  altDesc: blg.heroImage.description,
  estimatedreadtime: blg.estimatedreadtime,
}))

const caseStudyData = caseStudyDataMain?.items?.map((csItem) => ({
  slug: csItem.slug,
  nameofquotedperson: csItem.nameofquotedperson,
  quotedpersonjobtitle: csItem.quotedpersonjobtitle,
  organisation: csItem.organisation,
  calloutquote: csItem.calloutquote,
  extractforteaser: csItem.extractforteaser,
}));

 const timeLineAbout = aboutUsTime.aboutustimelineCollection

const aboutTimelineElements = timeLineAbout.items.map(abtTimeLine => {
  return {
    title: abtTimeLine.title,
    content: abtTimeLine.maincontent,
    imagealttext: abtTimeLine.imagealttext,
    iconText: abtTimeLine.iconText,
    imageSrc: abtTimeLine.image?.url,
    imagehyperlink: abtTimeLine.imagehyperlink,
    buttontext: abtTimeLine.buttontext,
    buttonlink: abtTimeLine.buttonlink
  }
})


  const meta = {
    title: 'Rasp: Explore ARCUS Cloud-based Supply Chain Software',
    description: 'We combine our specialised supplier management and procurement expertise with proven cloud-based supply chain software to reduce business purchase costs.',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'Cloud based supply chain software'
      }
    }
  }

  return (
    <DocumentMeta {...meta}>
    <div className="container pt-5 mt-5">
      <div className="text-center">
        <h3 className="font-weight-bold "><hr id="arcus" />About Us</h3>
        </div>
        <div className="text-center p-lg-5 p-md-3">
          <p className="clr">Rasp combines its specialised supplier management and procurement expertise with proven cloud based supply chain software. Our industry experience coupled with the ARCUS software platform enable us to deliver our highly acclaimed value to our customers. Read our story below.</p>
      </div>
    </div>

  <VerticalTimeline animate={false}>
      {aboutTimelineElements.map((timelineItem,index) => {
        const position= (index % 2 === 0) ? "right" : "left"
          return (
            <VerticalTimelineElement
              position={position}
              className="vertical-timeline-element--work"
              contentStyle={{ padding: '0px 10px', boxShadow:'none' }}
              //contentArrowStyle={{ borderRight: '16px solid  #2d4180' }}
              iconClassName = "customIcon"
              icon={timelineItem.iconText}
            >
            <h3 className={`vertical-timeline-element-title timeline-title-${position}`}>{documentToReactComponents(timelineItem.title.json)}</h3>
            <div className={`timeline-content-${position} text-center`}>
              {timelineItem.imageSrc && (
               <a href={timelineItem.imagehyperlink}> <img className="img-fluid mt-4" width="340" height="240" src={timelineItem.imageSrc} alt={timelineItem.imagealttext} /> </a>
              )}
            <span className="text-left">
            {documentToReactComponents(timelineItem.content.json)}
            </span>
              {timelineItem.buttontext && (
              <a href={timelineItem.buttonlink} target="_blank" rel="noopener noreferrer"> <button type="button" className="btn-primary mt-3">{timelineItem.buttontext}</button> </a>
              )}
            </div>
          </VerticalTimelineElement>
          )
      })}
    </VerticalTimeline>

    <div className="container aboutbg mt-5">
      <div className="col-sm-6 about-founder-intro pt-5">
        <p className="mt-5">Rasp was founded by Kevin Burke in Sydney in 2007 to provide Australian and New Zealand organisations with excellent cloud based software and supporting services in the fields of supplier and contractor management, e-Sourcing and contract lifecycle management.</p>
        <p>Prior to founding Rasp (formerly called Trade Interchange), Kevin spent 28 years in the IT industry in technical, marketing and business management roles. The last 13 years of which were with Microsoft Australia where he was the Director of their Small Business Division.</p>
      </div>
    </div>
    <div className="container-fluid p-0 mb-5">
            <div className="mt-lg-5 mt-sm-2">
              <div className="row reverse">
                  <div className="col-lg-6 m-auto">
                  <div className="row">
                      <div className="col-lg-3 hide-at" />
                      <div className="col-lg-9">
                      <h4 className="font-weight-bold ml-3">Our Team</h4>
                      <p className="clr ml-3 mt-4">Rasp and Trade Interchange are a team with a unique mix of skills and capabilities. The combination of software designers and developers and strong procurement and supplier management skills means we understand both the business needs and the technology needs of the customers we work with.</p>
                      <h4 className="font-weight-bold ml-3 mt-4">Our Culture</h4>
                      <p className="clr ml-3 mt-4">Our Culture The culture of Rasp is shaped by our desire to guard and grow our reputation for reliability, honesty, expertise and professionalism. As our existing customers will tell you, if we can't do something, we'll say. If we can do it, it will be delivered on time and as promised.</p>
                      </div></div></div>
                  <div className="col-lg-6 text-right pb-5"><img src="/images/The team.png" className="img-fluid" alt="" />
                  </div>
            </div>
          </div>
    </div>
    <CaseStudyTeaser {...caseStudyData[0]} />
    <BlogHeader />
    <BlogListing bloglist={relatedBlogs} />
    <CarouselHeader/>
        {/* <!-------------------------------------------- Carousel Slider ----------------------------------->  */}
         <div className="container mt-4">
         <div className="container col-lg-12">
          <MiniCard data={blogGraphData} />
         </div>
         </div>

    <CtaStripe />
    </DocumentMeta>
  )
}

export default AboutUs
