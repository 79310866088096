import React from 'react'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { useMediaQuery } from '../../Utils/getScreenSize'
import Button from '../Button'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import PropTypes from 'prop-types'

const TextAndImage = (props) => {
  const { textContent, textContent1, textBlockTitle, textBlockTitle1, sideImage, sideImage1, btnData, btnData1, textLayout, textLayout1, textJustification, textJustification1 } = props
  const Layout = (typeof textLayout !== 'undefined') ? textLayout[0] : ""
  const Layout1 = (typeof textLayout1 !== 'undefined') ? textLayout1[0] : ""

  const justificationClass = typeof textJustification !== 'undefined' ?
  textJustification[0] === 'Fully Justified' ? 'text-justify' :
  textJustification[0] === 'Centre Justified' ? 'text-centre' :
  textJustification[0] === 'Right Justified' ? 'text-right' :
  textJustification[0] === 'Left Justified' ? 'text-left' : '' :'';

  const justificationClass1 = typeof textJustification1 !== 'undefined' ?
  textJustification1[0] === 'Fully Justified' ? 'text-justify' :
  textJustification1[0] === 'Centre Justified' ? 'text-centre' :
  textJustification1[0] === 'Right Justified' ? 'text-right' :
  textJustification1[0] === 'Left Justified' ? 'text-left' : '' :'';

  let btnClass = (Layout === 'Left Column') ? 'Rbtnmargin' : 'Lbtnmargin'
  btnClass = (typeof sideImage === 'undefined') ? " " : btnClass

  const textBlockClass = (Layout === 'Full Width' || typeof sideImage === 'undefined') ? 'col-lg-10 container' : 'col-lg-6'
  const imgBlockClass = (Layout === 'Full Width') ? 'col-lg-12 container text-center' : 'col-lg-6 text-center p-0'

  const textBlockClass1 = (Layout1 === 'Full Width' || typeof sideImage1 === 'undefined') ? 'col-lg-10 container' : 'col-lg-6'
  const imgBlockClass1 = (Layout1 === 'Full Width') ? 'col-lg-12 container text-center' : 'col-lg-6 text-center p-0'

  const formattedRichText =  (typeof textContent !== 'undefined' && typeof textContent === 'object') ? documentToHtmlString(textContent) : textContent
  const formattedRichText1 = (typeof textContent1 !== 'undefined' && typeof textContent1 === 'object'&& textContent1 !==null) ? documentToReactComponents(textContent1.json) : textContent1

  const blockText =
    <>
      {
        textContent ?
          <div className={textBlockClass}>
                      <div className="row">
                      {/* <div className="col-lg-2 hide-at" /> */}
                      <div className= {`${justificationClass} col-lg-12`}>
                     {textBlockTitle && (
                       <h3 className="px-5 text-left"><hr id="arcus" className="m-lg-0 mt-n5 pb-2"/>{textBlockTitle}</h3>
                     )}
                        <div className="px-5 clr" dangerouslySetInnerHTML={{ __html: formattedRichText }} />
              </div></div></div>
          :
          ""
      }

      {
        textContent1 || textBlockClass1 ?
          <div className={textBlockClass1}>
                      <div className="row">
                        {(Layout1 === 'Left Column') ?
                        <>
                          <div className="col-lg-1 hide-at" />
                              <div className= {`${justificationClass1} col-lg-11`}>
                            {textBlockTitle1 && (
                              <h3 className="px-5 text-left"><hr id="arcus" className="m-lg-0 mt-n5 pb-2"/>{textBlockTitle1}</h3>
                            )}
                            <div className="px-5 clr">{formattedRichText1}</div>
                          </div>
                        </>
                        :
                        <>

                          <div className= {`${justificationClass1} col-lg-11`}>
                            {textBlockTitle1 && (
                              <h3 className="px-5 text-left"><hr id="arcus" className="m-lg-0 mt-n5 pb-2"/>{textBlockTitle1}</h3>
                            )}
                            <div className="px-5 clr">{formattedRichText1}</div>
                          </div>
                          <div className="col-lg-1 hide-at" />
                        </>
                        }

              </div></div>
          :
          <></>
      }
    </>

  const imageBlock =
    <>

      {
        sideImage ?
            <div className={imgBlockClass}>
              <img src={sideImage} className="img-fluid" alt="" />
                    {Object.keys(btnData).length > 0 && <Button {...btnData}/>}
              </div>
              :
                <>
                </>
      }
      {
         sideImage1 ?
            <div className={imgBlockClass1}>
              <img src={sideImage1.url} className="img-fluid" alt={sideImage1.description} />
                {btnData1 ?
                          <div className={`${btnClass} `}>

                            {/* <Button btnData={btnData1}/> */}
                            <Button {...btnData1}/>
                          </div>
                          :
                          <div></div>
                          }
              </div>
              :
                <>
                  {btnData1 ?
                  <div className={imgBlockClass1} >
                          <div className={`${btnClass} `}>

                            {/* <Button btnData={btnData1}/> */}
                            <Button {...btnData1}/>
                          </div>
                  </div>
                          :
                          <div></div>
                          }
                </>
      }
    </>

  const [width] = useMediaQuery()
  const isDesktop = (width > 1023)
  return (
    <div className="container-fluid p-0">
      <div className="my-5">
        <div className="row">
          {isDesktop && (
            <>
            {(Layout === "Left Column"||Layout1 === "Left Column") && (
              blockText
            )}
            {(Layout === "Left Column"||Layout1 === "Left Column") && (
              imageBlock
            )}
            {(Layout === "Right Column"||Layout1 === "Right Column") && (
              imageBlock
            )}
            {(Layout === "Right Column"||Layout1 === "Right Column") && (
              blockText
            )}
            {(Layout === "Full Width"||Layout1 === "Full Width") && (
              blockText
            )}
            {(Layout === "Full Width"||Layout1 === "Full Width") && (
              imageBlock
            )}
            </>
          )}

          {!isDesktop && (
            <>
            {blockText}
            {imageBlock}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

TextAndImage.propTypes = {
  textContent: PropTypes.objectOf(PropTypes.any),
  sideImage: PropTypes.string,
  btnData: PropTypes.arrayOf(PropTypes.any),
  textLayout: PropTypes.arrayOf(PropTypes.string),
  textJustification: PropTypes.arrayOf(PropTypes.string)
}


export default TextAndImage
