import React, { useState} from 'react'
import { useLocation } from 'react-router-dom'
import ModalVideo from 'react-modal-video'
import CtaStripe from './CtaStripe'
import BlogListing from './BlogListing'
import BlogHeader from './BlogHeader'
import ClsProductCards from './ProductCards';
import CaseStudyTeaser from './CaseStudyTeaser'
import PDF from '../Pdf/ARCUS SSM Factsheet.pdf'
import DocumentMeta from 'react-document-meta'
import { useQuery, gql } from "@apollo/client"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarouselHeader from './CarouselHeader'
import MiniCard from './MiniBlogCard'
import Loader from './Loader'
import FaqLists from './FaqLists'

const pageDataQuery = gql`
query PageDataMain{
  blogPostCollection(where:{blogPostProduct_contains_all:"SSM", SEOIndex:true} order:[publishDate_DESC] limit:3){
    items{
      title
      publishDate
      description
      slug
      heroImage{
        url
        description
      }
      estimatedreadtime
    }
  }
  casestudyCollection(where:{casestudytags_contains_all:"SSM", SEOIndex:true} order:[casestudyorderpriority_ASC] limit:1){
    items{
      slug
      nameofquotedperson
      quotedpersonjobtitle
      organisation
      calloutquote
      extractforteaser
    }
  }
}
`;

 const queryExtraCaseStudy = gql`
 query ExtraCaseStudy {
  casestudyCollection(where:{casestudytags_contains_all:"eRFx", SEOIndex:true} order:[casestudyorderpriority_ASC] limit:1){
    items{
      slug
      nameofquotedperson
      quotedpersonjobtitle
      organisation
      calloutquote
      extractforteaser
    }
  }
}
 `

const blogpostCardMiniQuery = gql`
query BlogpostCardMini {
  blogPostCollection(where:{blogPostProduct_contains_all:"SSM", SEOIndex:true} order:[publishDate_DESC]){
    total
    items{
      title
      slug
      seoIndex
    }
  }

  pageBuilderCollection(where: {SEOIndex:true, pageBuilderProduct_contains_all:"SSM"} ){
    total
    items{
      title
      slug
      pageBanner {
        bannerText
      }
    }
 }

}
`

const extraBlogpostCardMini = gql`
query ExtraBlogpostCardMini {
  blogPostCollection(where:{blogPostProduct_contains_all:"eRFx", SEOIndex:true} order:[publishDate_DESC]){
    total
    items{
      title
      slug
      seoIndex
    }
  }

}
`
const extraBlogpostCardMiniCurrent = gql`
query ExtraBlogpostCardMiniCurrent {
  blogPostCollection(where:{blogPostProduct_contains_all:"eAuction", SEOIndex:true} order:[publishDate_DESC]){
    total
    items{
      title
      slug
      seoIndex
    }
  }

}
`

const SourceManagement = () => {

    const [isOpen, setOpen] = useState(false)
    const { data:pageDataCurrent } = useQuery(pageDataQuery);

  const location = useLocation()

  const faqMappingUrl = location.pathname;
  const faqListsQuery = gql`
  query FaqLists ($url: String!){
    addOnFaqListCollection(where:{addOnFaqListSlug: $url}){
      items{
        faqList{
          title
          listCollection{
            items{
              question
              answerText
            }
          }
        }
      }
    }
  }
  `

  const queryExtraBlogs = gql`
  query ExtraBlogsCurrent{
    blogPostCollection(where:{blogPostProduct_contains_all:"eRFx", SEOIndex:true} order:[publishDate_DESC] limit:9){
    total
      items{
        title
        publishDate
        description
        slug
        heroImage{
          url
          description
        }
        estimatedreadtime
      }
    }
  }
`;


  const { data: faqDataMain } = useQuery(faqListsQuery, {
    skip: !location || !location.pathname,
    variables: {
      url:
        location && location.pathname
          ? faqMappingUrl.slice(1)
          : null,
    },
  })


  const { data: extraBlgLargeData } = useQuery(queryExtraBlogs)

  const { data:blogGraphData } = useQuery(blogpostCardMiniQuery)


  const { data:extraBlogGraphData } = useQuery(extraBlogpostCardMini)

  const { data:extraBlogGraphDataCurrent } = useQuery(extraBlogpostCardMiniCurrent)

  const { data:extraCaseData } = useQuery(queryExtraCaseStudy)


  if (!faqDataMain || !blogGraphData || !extraBlogGraphData || !extraBlgLargeData ||!extraBlogGraphDataCurrent || !extraCaseData) {
    return (<Loader />)
  }

  const blogBoxData = blogGraphData?.blogPostCollection?.items
  const pageBuildData = blogGraphData?.pageBuilderCollection?.items

  const extraBlogBoxData = extraBlogGraphData?.blogPostCollection?.items
  const extraBlgBoxDataCurrent = extraBlogGraphDataCurrent?.blogPostCollection?.items

  const blogCardData = [...blogBoxData, ...extraBlogBoxData, ...extraBlgBoxDataCurrent, ...pageBuildData]

  const extraCase = extraCaseData.casestudyCollection

  const faqData = faqDataMain?.addOnFaqListCollection?.items[0]?.faqList

  const seoData = faqDataMain?.addOnFaqListCollection?.items[0]?.faqList?.listCollection?.items

  let mainEntity = []
   seoData&&
    seoData.map((res,id)=> {
    return mainEntity[id] = {
        "@type": "Question",
        "name": res.question,
        "acceptedAnswer": {
          "@type": "Answer",
          "text": res.answerText,
        }
      }
    })

    let schema = {}
    mainEntity.length ===0 ? schema= null :
     schema = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": mainEntity
    }

      if (!pageDataCurrent)
    return (
      <Loader />
    )
  const caseData = pageDataCurrent.casestudyCollection;
  const { items } = pageDataCurrent.blogPostCollection;

  const blogPostDataFilter = extraBlgLargeData.blogPostCollection;

const caseStudyInitial = caseData.items.map(csItem => {
  return {
    slug: csItem.slug,
    nameofquotedperson:csItem.nameofquotedperson,
    quotedpersonjobtitle:csItem.quotedpersonjobtitle,
    organisation:csItem.organisation,
    calloutquote:csItem.calloutquote,
    extractforteaser:csItem.extractforteaser
  }
})

const caseMapData = extraCase.items.map(csItem => {
  return {
    slug: csItem.slug,
    nameofquotedperson:csItem.nameofquotedperson,
    quotedpersonjobtitle:csItem.quotedpersonjobtitle,
    organisation:csItem.organisation,
    calloutquote:csItem.calloutquote,
    extractforteaser:csItem.extractforteaser
  }
})

const caseStudyData = caseStudyInitial.length === 0? [...caseMapData] : caseStudyInitial

const initialBlogs = items.map(blg => {
  return {
    title: blg.title,
    date: blg.publishDate,
    shortDesc: blg.description,
    bloglink: blg.slug,
    imageSrc: blg.heroImage.url,
    altDesc:blg.heroImage.description,
    estimatedreadtime: blg.estimatedreadtime
  }
})

const extraBlogs = blogPostDataFilter.items.map((blg) => ({
  title: blg.title,
  date: blg.publishDate,
  shortDesc: blg.description,
  bloglink: blg.slug,
  imageSrc: blg.heroImage.url,
  altDesc:blg.heroImage.description,
  estimatedreadtime: blg.estimatedreadtime,
}));

   const downloadPDF = () => {

        fetch(PDF)
          .then(response => {
            response.blob().then(blob => {
              let url = window.URL.createObjectURL(blob);
              let a = document.createElement('a');
              a.href = url;
              var sub = PDF.substr(14);
              a.download =sub;
              a.click();
            });
        });
      }

      const meta = {
        title: 'Rasp: Explore our Source management system',
        description: 'Our Source management system automates Sources, streamlines processes, enforces best practices, and reduces off-contract spending.',
        meta: {
          charset: 'utf-8',
          name: {
            keywords: 'Online source management'
          }
        }
      }

      const bloglist =
      initialBlogs.length < 3
        ? [...initialBlogs, ...extraBlogs].slice(0, 3)
        : initialBlogs;

    return(
      <DocumentMeta {...meta}>
      <div>
      {schema && (
                <div
                dangerouslySetInnerHTML={{
                  __html: `
                    <script type="application/ld+json">${JSON.stringify(
                      schema
                    )}</script>
                    `
                  }}
                />
              )}

          {/* <!====================================text start================================>  */}
          <div className="bgshadow3 pt-5"> {/* <!---shadow start here---> */}
          <div className="container mt-5 text-center">
          <div className="row">
            <div className="col-lg-6 m-auto"><img src="/images/Product Logos/SSM Logo.svg" className="img-fluid mt-2" alt="" /></div>
            <div className="col-lg-6 mt-2 mt-sm-4 text-left center">
            <h2 className="font-weight-bold"><hr id="arcus" className="m-lg-0 mt-n5 pb-2" />ARCUS SSM</h2>
            <h3>Strategic Sourcing Management</h3>
            </div>
          </div>

          {/* <!-------------horizontal divider---------------------------------> */}

          <div className="container"><hr /></div>

            <div className="row">
                <div className="col-lg-6 ">
                <p className="font-weight-lighter text-left font-italic clr text6">"Using ARCUS® SSM, our procurement team now has a complete overview of all current and potential sourcing projects. This allows us to easily view the current status of each of these projects, while helping us to ensure best procurement practice."</p>
                </div>
                <div className="col-lg-6">
                <p className="font-weight-lighter text-left  clr text6"><b className="text-dark">Sourcing Management Transformation</b> - An easy to use, automated end-to-end sourcing management system that's customised to reflect your procurement processes and will transform your organisation's sourcing capabilities.<br /><br />
                   </p>
                </div>
            </div>

            <div className="text-center mt-5">
                <h3 className="font-weight-bold "><hr id="arcus" />What is it?</h3>
            </div>

            <div className="text-left center mt-5">
          <p className="clr">By providing complete visibility and full control of the procurement pipeline, ARCUS Strategic Sourcing Management (SSM) gives procurement leaders the tools to transform organisational sourcing, improve sourcing productivity and increase bookable savings.</p>
          <p  className="clr">ARCUS SSM is cloud-based software that creates standardised and automated procurement processes that are mapped to your specific business requirements. Strategic Sourcing Management provides full visibility and control of every project in your procurement pipeline. Intelligent workflow automatically sends the right tasks and approval requests to the right people, instantly. A high level dashboard and superb, easy to use reporting keeps you fully informed and in control.</p>
          </div>

          </div>

          {/* <!-----------------------------------sim dashboard start-------------------------------------> */}

          <div className="container-fluid p-0">
            <div className="mt-5">
            <div className="row reverse">
                <div className="col-lg-6 m-auto">
                <div className="row">
                    <div className="col-lg-3 hide-at" />
                    <div className="col-lg-9">
                    <h3 className="font-weight-bold  px-3 px-lg-0"><hr id="arcus-left" />About ARCUS Strategic Sourcing Management</h3>
                    <p className="clr mt-4  px-3 px-lg-0">  ARCUS SSM is powerful software system that ensures all procurement sourcing projects follow best practice processes including the capture of essential information and intelligently routed approval chains. . ARCUS Sourcing Management maps the various stages of your sourcing processes and creates a task-based workflow that supports policy adherence and complete visibility for all relevant stakeholders. <br /><br /> The system includes options for automated approval routing, document management and customised forms that capture the critical information related to each procurement project. The progress of each project is visible via the 360° sourcing management dashboard, which provides a central reference point to monitor the status of every procurement and sourcing activity across the organisation.<br /><br />  When used in combination with <a href="/etender">ARCUS eRFX</a> (e- tenders) and <a href="/reverse-auction-eauction"> ARCUS eAUC</a> (Reverse Auctions) you have a strategic sourcing suite with the power to transform the sourcing capability of your organisation.</p>
                    </div></div></div>
                <div className="col-lg-6 text-center p-0"><img src="/images/SSM Dashboard Graphic right.png" className="img-fluid" alt="" />
                <ModalVideo channel='vimeo' autoplay isOpen={isOpen} videoId="614888918" onClose={() => setOpen(false)} />
                <button className="btn btn-primary my-5 mx-4 spacing Rbtnmargin video-btn" onClick={()=> setOpen(true)}>VIEW STRATEGIC SOURCING VIDEO</button>
                </div>
            </div>
            </div>
        </div>

        {/* <!-----------------------------------sim dashboard end-------------------------------------> */}

        {/* <!-------------------------------sim questionnaire start-------------------------------> */}
                <div className="container-fluid">
                        <div className="row reverse">
                        <div className="col-lg-6 text-center p-0">
                            <img src="/images/SSM Dashboard Graphic left.png" className="img-fluid mt-5" alt="" />
                            <button type="button" onClick={downloadPDF} className="btn btn-primary my-5 mx-4 Lbtnmargin">DOWNLOAD SOURCING MANAGEMENT FACTSHEET</button>
                        </div>
                        <div className="col-lg-6 mt-3 px-3 m-auto">
                            <div className="mx-4 mt-sm-4 text-left center">
                            <h2 className="font-weight-bold"><hr id="arcus" className="m-lg-0 mt-n5 pb-2" />How does it work?</h2>
                            </div>

                            <div className="mt-4"><img src="/images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">
                            Multi - Year Procurement Pipeline Planning
                            </p></div>
                            <div className="mt-4"><img src="/images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">
                            Sourcing project allocation and easy reassignment
                            </p></div>
                            <div className="mt-4"><img src="/images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">
                            Automated step-by-step processes
                            </p></div>
                            <div className="mt-4"><img src="/images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">
                            Smart online forms to capture just the right information
                            </p></div>
                            <div className="mt-4"><img src="/images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">
                            Automated e-Mail reminders at exactly the right time
                            </p></div>
                            <div className="mt-4"><img src="/images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">
                            Approval workflows with automated intelligent routing
                            </p></div>
                            <div className="mt-4"><img src="/images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">
                            Dashboard with full visibility of entire sourcing pipeline
                            </p></div>
                            <div className="mt-4"><img src="/images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">
                            Advanced, easy to use reporting of all dates, status and data
                            </p></div>
                            <div className="mt-4"><img src="/images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">
                            Full audit record for instant, no-preparation audits
                            </p></div>

                        </div>
                        </div>
                </div>

          </div>{/* <!---shadow end here---> */}

          {/* <!-------------------------------sim questionnaire end---------------------------------> */}

          {/* <!====================================text end================================>  */}

                {/* <!-------------------------------sim questionnaire start-------------------------------> */}

                <div className="container-fluid  mb-3">
                        <div className="row reverse">

                        {/* <div className="col-lg-3 hide-at" /> */}

                        <div className="col-lg-6 mt-3 px-3 m-auto">

                        <div className="row">
                          <div className="col-lg-3 hide-at" />
                          <div className="col-lg-9">
                            <div className="mt-sm-4 text-left center">
                            <h2 className="font-weight-bold"><hr id="arcus" className="m-lg-0 mt-n5 pb-2" />Benefits of ARCUS Strategic Sourcing Management</h2>
                            </div>
                            <div className="mt-4"><img src="/images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">
                            Full visibility of sourcing and procurement pipeline
                            </p></div>
                            <div className="mt-4"><img src="/images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">
                            Efficiencies - processes, approvals, bottlenecks gone
                            </p></div>
                            <div className="mt-4"><img src="/images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">
                            Reputational benefits of impressive systems and full control
                            </p></div>
                            <div className="mt-4"><img src="/images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">
                            Ongoing cost-savings through improved sourcing methods
                            </p></div>
                            <div className="mt-4"><img src="/images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">
                            Full compliance with organisational policies
                            </p></div>
                            <div className="mt-4"><img src="/images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">
                            Time saving and stress free 'always ready' audits
                            </p></div>
                            <div className="mt-4"><img src="/images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">
                            Complete more sourcing projects in less time Live, easy-to-use reporting for instant answers
                            </p></div>
                            <div className="mt-4"><img src="/images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">
                            Live, easy-to-use reporting for instant answers
                            </p></div>
                            <div className="mt-5 text-center">
                            <a href="/contact-us"><button type="button" className="btn btn-primary my-5  text-center font-weight-bold ">CONTACT US TO DISCUSS STRATEGIC SOURCING</button></a>
                            </div>
                          </div> </div>
                        </div>

                        <div className="col-lg-6 text-center p-0">
                        <div className="row">
                          <div className="col-lg-9 ml-lg-3 m-2">
                            <img width="610px" height="632px" src="/images/Top 3 Reasons for Strategic Sourcing.svg" className="img-fluid mt-5" alt="Top 3 Reasons for Strategic Sourcing" />
                            </div>
                            <div className="col-lg-3 hide-at" />
                             </div>
                        </div>
                        </div>
                </div>

                 {/* ==========================faqlist start============================ */}

                 {faqData && (
                  <div className="col-lg-6 m-auto py-3">
                    <FaqLists {...faqData}/>
                  </div>
                  )}

                  {/* ==========================faqlist end============================ */}
          <div className="container bgshadow">

          {/* <!==============================Product Cards start============================> */}
          <ClsProductCards></ClsProductCards>
          </div><br/><br/>

          {/* <!==============================Product Cards end==============================> */}

        {/* <!==============================case study with city start==============================>  */}
        <div className="container-fluid mt-5 p-0 bgimage">
                {caseStudyData[0] && (
                  <CaseStudyTeaser {...caseStudyData[0]} />
                )}
         </div>

        {/* <!==============================case study with city End==============================>  */}

       <BlogHeader />

        {/* <!==============================blog Cards start==============================>   */}

        <BlogListing bloglist={bloglist} />
        {/* <!==============================blog Cards end==============================>   */}




        <CarouselHeader/>

       <div className="container mt-4">
         <div className="container col-lg-12">
           {
             blogCardData&& <MiniCard data={blogCardData} />
           }

         </div>
         </div>


        <CtaStripe />
      </div>
    </DocumentMeta>
)

}
export default SourceManagement
