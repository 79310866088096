import React from 'react'
import PropTypes from 'prop-types'

const BlogHeader = ({title, content}) => {

  return (
  <div className="container text-center pt-5"><h3 className="font-weight-bold "><hr id="arcus" />{title}</h3>
      <p className="clr">{content}</p>
    </div>
  )
}

BlogHeader.defaultProps = {
  title: "Read Our Latest Insights",
  content: "Our world is supply chain software so think procurement, sourcing, suppliers, products, contracts. If we learn something clever, insightful, controversial, cool, unexpected we'll talk about it here. We'll also let you know what we're doing and we'll tell the news of our products and services."
}

BlogHeader.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string
}

export default BlogHeader
