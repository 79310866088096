import React from 'react'
import PropTypes from 'prop-types'
import Button from '../Button'

const BtnStripe = ({ buttons }) => {

  return (
    <div className="container-fluid bg-color bgimageStripe pt-3 justify-content-center align-items-center">
      <div className="container py-5">
        <div className="row btnouter" >
          <div className="col-lg-12 text-center btnin" >
            {buttons?.map((btnData) => (
              <Button
                {...btnData}

              />
            ))
            }
          </div>

        </div>
      </div>
    </div>
  )
}


BtnStripe.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.object),
}

export default BtnStripe
