/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import MegaMenuComponent from './MegaMenuComponent'
import { useMediaQuery } from 'react-responsive'

const ClsNavbar = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 992px)'
  })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 991px)' })
        return (
            //.............................NavBar............................................>
            <>
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                Menu <span className="navbar-toggler-icon" /></button>
              <div className="collapse navbar-collapse ml-5" id="navbarResponsive">
                <ul className="navbar-nav nav">
                  <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Solutions
                    </a>
                    <div className="dropdown-menu " aria-labelledby="navbarDropdown">
                      { isDesktopOrLaptop &&
                        <MegaMenuComponent />
                      }

                      {isTabletOrMobile && <>
                        <a className="dropdown-item" href="#">Solutions</a>
                        <a className="dropdown-item menu-style-0" href="/supplier-management-platform">Our Solutions</a>
                        <a className="dropdown-item" href="#">Source</a>
                        <a className="dropdown-item menu-style-1" href="/etender">Supplier e-tender</a>
                        <a className="dropdown-item menu-style-1" href="/reverse-auction-eauction">e-Auction Supplier Negotiation</a>
                        <a className="dropdown-item menu-style-1" href="/sourcing-management">Strategic Sourcing Management</a>
                        <a className="dropdown-item" href="#">Supply</a>
                        <a className="dropdown-item menu-style-2" href="/supplier-information-management">Supplier Information Management</a>
                        <a className="dropdown-item menu-style-2" href="/contract-management">Supplier Contract Management</a>
                        <a className="dropdown-item menu-style-2" href="/supplier-performance-management">Supplier Performance Management</a>
                        <a className="dropdown-item" href="#">Product</a>
                        <a className="dropdown-item menu-style-3" href="/product-information-management">Product Information Management</a>
                        <a className="dropdown-item" href="#">Food Industry</a>
                        <a className="dropdown-item menu-style-4" href="/food-industry-software">Software for Food Makers</a>
                        </>
                      }
                    </div>
                    <div className="dropdown-menu " aria-labelledby="navbarDropdown">

                    </div>
                  </li>
                  <li className="nav-item dropdown">
                    <a className="nav-link dropdown" href="/about-us" id="navbarDropdown1" role="button"  aria-haspopup="true" aria-expanded="false">
                      About
                    </a>
                  </li>
                  <li className="nav-item dropdown">
                    <a className="nav-link " href="/case-study-index" id="navbarDropdown2" role="button"  aria-haspopup="true" aria-expanded="false">
                      Customer Stories
                    </a>

                  </li>
                  <li className="nav-item dropdown">
                    <a className="nav-link" href="/insights" id="navbarDropdown3" role="button" aria-haspopup="true" aria-expanded="false">
                      Insights
                    </a>
                  </li>

                  <li className="nav-item">
                    <a className="nav-link" href="/contact-us" role="button">
                      Contact Us
                    </a>
                  </li>

                </ul>
              </div>
              </>
        );
    }

export default ClsNavbar;
