import React from 'react';
import ReactDOM from 'react-dom';
import ModalVideo from 'react-modal-video';


class VideoPopUp extends React.Component {

    constructor () {
      super()
      this.state = {
        isOpen: false
      }
      this.openModal = this.openModal.bind(this)
    }
  
    openModal () {
      this.setState({isOpen: true})
    }
  
    render () {
      return (
        <div>
          <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='L61p2uyiMSo' onClose={() => this.setState({isOpen: false})} />
          <button onClick={this.openModal}>Open Video</button>
        </div>
      )
    }
  }
  
  ReactDOM.render(
    <VideoPopUp />,
      document.getElementById('root')
  )
  export default VideoPopUp;