import React from 'react'
import PropTypes from 'prop-types'

const CaseStudyTeaser = ({title, firstBtnText, secondBtnText, slug, nameofquotedperson, quotedpersonjobtitle, organisation, calloutquote, extractforteaser}) => {

  const link = `/case-study/${slug}`
  return (
    <div>
    <div className="container-fluid m-0 p-0 bgimage">
    <div className="container text-left text-white p-0">
      <div className="conteiner col-lg-12 p-5">
        <div className="row">
  <div className="col-lg-5 my-auto"><h3 className="text-white"><hr id="arcus" className="m-0 mb-2" />{title}</h3>
  <div className="mt-3"><a href={link} className="btn btn-secondary">{firstBtnText}</a></div>
  <div className="mt-3"><a href="/case-study-index" className="btn btn-secondary spacing">{secondBtnText}</a></div>
          </div>
          <div className="col-lg-7 pb-5 mt-5">
            <div className="container-fluid text-center text-white bg-white rad">
              <div className="row">
                <div className="col-lg-5 bgimage2 text-center"><h5 className="text-white mt-1">{nameofquotedperson}</h5>
                  <p className="text-white text5 p-1">{quotedpersonjobtitle}{organisation}</p>
                  <div className="row">
                    <div className="col-lg-12 mt-5"><h5 className="clr pt-3">"{calloutquote}"</h5></div>
                  </div></div>
                <div className="col-lg-7"><p className="clr text-justify pt-3">{extractforteaser}</p></div>
              </div>
            </div>
          </div>
        </div> </div>
    </div>
  </div>
</div>
  )
}

CaseStudyTeaser.propTypes = {
  title: PropTypes.string,
  firstBtnText: PropTypes.string,
  secondBtnText: PropTypes.string,
  slug: PropTypes.string.isRequired,
  nameofquotedperson: PropTypes.string.isRequired,
  quotedpersonjobtitle: PropTypes.string.isRequired,
  organisation: PropTypes.string.isRequired,
  calloutquote: PropTypes.string.isRequired,
  extractforteaser: PropTypes.string.isRequired
}

CaseStudyTeaser.defaultProps = {
  title: 'What Clients Say about us',
  firstBtnText: 'READ THE FULL CUSTOMER STORY NOW',
  secondBtnText: 'MORE CUSTOMER STORIES'
}

export default CaseStudyTeaser
