import React,  { useState, useEffect } from 'react'
import BlogListing from './BlogListing'
import BlogHeader from './BlogHeader'
import client from './Contentful'
import CtaStripe from './CtaStripe'
import ClsProductCards from './ProductCards'
import CaseStudyTeaser from './CaseStudyTeaser'
import DocumentMeta from 'react-document-meta'
import ModalVideo from 'react-modal-video'
import { useQuery, gql } from "@apollo/client"
import Loader from './Loader'
import CarouselHeader from './CarouselHeader'
import MiniCard from './MiniBlogCard'


const  SolutionsSupplyChainSoftware = () => {
  const [latestBlogs, setLatestBlogs] = useState([])
  const [caseStudy, setCaseStudy] =  useState([])
  const [extraCaseStudy, setExtraCaseStudy] =  useState([])
  const [isOpen, setOpen] = useState(false)

const blogpostCardMiniQuery = gql`
query BlogpostCardMini {
  blogPostCollection(where:{SEOIndex:true} order:[publishDate_DESC]){
    total
    items{
      title
      slug
    }
  }

}
`
const PageBuildQuery = gql`
query pageBuildQuery{
pageBuilderCollection(where: {SEOIndex:true} ){
 total
 items{
   title
   slug
   __typename
   pageBanner {
     bannerText
   }
 }
}
}`

  useEffect(() => {
    client.getEntries({
      content_type: 'blogPost',
      order:'-fields.publishDate',
      limit: 3
    })
    .then((response)=>{
     const initialBlogs = response.items.map(blg => {
        return {
          title: blg.fields.title,
          date: blg.fields.publishDate,
          shortDesc: blg.fields.description,
          bloglink: blg.fields.slug,
          imageSrc: blg.fields.heroImage.fields.file.url,
          altDesc: blg.fields.heroImage.fields.description,
          estimatedreadtime: blg.fields.estimatedreadtime
        }
      })
      setLatestBlogs(initialBlogs)
    }).catch(console.error)

    client.getEntries({
      content_type: 'casestudy',
      order: 'fields.casestudyorderpriority',
      'fields.casestudyproduct': 'SSM',
      limit: 1
    })
    .then((response) => {
      const caseStudyData = response.items.map(csItem => {
        return {
          slug: csItem.fields.slug,
          nameofquotedperson:csItem.fields.nameofquotedperson,
          quotedpersonjobtitle:csItem.fields.quotedpersonjobtitle,
          organisation:csItem.fields.organisation,
          calloutquote:csItem.fields.calloutquote,
          extractforteaser:csItem.fields.extractforteaser
        }
      })
      setCaseStudy(caseStudyData[0])
    } )
    .catch(console.error)

    client.getEntries({
      content_type: 'casestudy',
      order: 'fields.casestudyorderpriority',
      limit: 1
    })
    .then((response) => {
      const extraCaseStudyData = response.items.map(csItem => {
        return {
          slug: csItem.fields.slug,
          nameofquotedperson:csItem.fields.nameofquotedperson,
          quotedpersonjobtitle:csItem.fields.quotedpersonjobtitle,
          organisation:csItem.fields.organisation,
          calloutquote:csItem.fields.calloutquote,
          extractforteaser:csItem.fields.extractforteaser
        }
      })
      setExtraCaseStudy(extraCaseStudyData[0])
    } )
    .catch(console.error)
  },[])

  const { data:blogGraphData } = useQuery(blogpostCardMiniQuery)
  const {data: pagebuilderData} = useQuery(PageBuildQuery)

  if ( !blogGraphData || !pagebuilderData) {
    return <Loader />;
  }

  const blogBoxData = blogGraphData?.blogPostCollection?.items

  const pageBuildData = pagebuilderData?.pageBuilderCollection?.items

  const blogCardData = [...blogBoxData, ...pageBuildData]

  const finalCaseStudyTeaser = (typeof caseStudy !== 'undefined' && caseStudy.length > 0) ? caseStudy : extraCaseStudy

  console.log("initial blogs:",latestBlogs)

  const meta = {
    title: 'Rasp: Our Configurable Supplier Management Platform Modules',
    description: 'Qualify your suppliers, harness your purchasing power, and evaluate supplier performance with the easy-to-deploy modules of our supplier management platform.',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'Supplier Management Platform'
      }
    }
  }
    return(
      <DocumentMeta {...meta}>
        <div>

        {/* ====================================text start================================ */}

        <div className="container mt-5 text-center">
      <hr id="arcus" /><h1>Our Solutions</h1>
      <div className="row text-center">
        <div className="col-lg-12 mt-2"><img src="/images/RaspLogoOnly360px.png" className="img-fluid  mt-3" alt="Rasp Logo" /></div>
        {/* <div className="col-lg-6 mt-2"><img src="images/Outsystems-logo.svg" className="img-fluid w-75" alt="" /></div> */}
      </div>
      <div className="text-center px-lg-5">
        <p className="p-4 clr">Rasp is a software company with Supply Chain expertise. This means we add our industry knowledge to your business requirements to give you a perfect solution.</p>
      </div>
      <div className="justify-content-between m-auto">
        <hr id="arcus1" />
      </div>
      <div className="text-center">
        <img src="images/ARCUS Logo with Tagline.svg" className="img-fluid" alt="ARCUS Logo with Tagline" />
      </div>
    </div>
    <div className="container bgshadow">
      <div className="container text-center mt-5 clr">
        <p className="px-lg-5 clr">Our 'Off-The Shelf' cloud-based ARCUS e-Sourcing and Supplier Management platform features the following easily-configurable modules that are fast to deploy and simple to use.</p>
        <p className="px-lg-5 clr">Whether you are looking to better understand or qualify your suppliers, harness your purchasing power, centralise your contracts or evaluate your supplier performance, we have a solution that can help.</p>
        <p className="px-lg-5 clr">To find out more about the ARCUS® platform, please click on any of the icons below.</p>
      </div>


{/*====================================text end================================*/}
{/*==============================Product Cards start============================*/}

  <ClsProductCards></ClsProductCards>
  </div>

{/*==============================Product Cards End============================*/}

<div>
    <div className="container">
      <div className="mt-5 text-center">
        <ModalVideo channel='vimeo' autoplay isOpen={isOpen} videoId="494008157" onClose={() => setOpen(false)} />
        <button className="btn btn-primary my-5 spacing video-btn" onClick={()=> setOpen(true)}>VIEW ARCUS OVERVIEW VIDEO</button>
      </div>
      <div className="mt-5 text-center">
      <a href="/contact-us"><button type="button" className="btn btn-outline-primary speak text-center font-weight-bold w-75 p-2">Request a free demo of an individual module or the full ARCUS platform</button></a>
      </div>
    </div>
  </div>

{/* <!==============================case study with city start==============================>  */}

{finalCaseStudyTeaser && (
  <div className="pt-5">
    <CaseStudyTeaser {...finalCaseStudyTeaser} />
  </div>
)}

{/* <!==============================case study with city End==============================>  */}

<BlogHeader />

{/* <!==============================blog Cards start==============================>  */}

    <BlogListing bloglist={latestBlogs} />

  {/* <!==============================blog Cards End==============================>  */}
  <CarouselHeader/>
<div className="container mt-4">
  <div className="container col-lg-12">
    {
      blogCardData&& <MiniCard data={blogCardData} />
    }

  </div>
  </div>
    <CtaStripe />
  </div>
  </DocumentMeta>
    )
}
export default SolutionsSupplyChainSoftware;
