import React, { useState, useEffect } from 'react'
import CtaStripe from './CtaStripe'
import client from './Contentful'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'


const Privacy =() => {
  const [pageContent, setPageContent] = useState([])

  useEffect(() => {
    client.getEntries({
      content_type: 'richTextFormattedPage',
      'fields.title': 'Rasp Privacy Policy',
      limit: 1
    })
    .then((response) => {
      const getContent = response.items.map(item => {
        return {
          title: item.fields?.title,
          image1: item.fields?.image1?.fields?.file.url,
          image1Desc: item.fields?.image1?.fields?.description,
          section1: item.fields?.section1,
          section2: item.fields?.section2,
          section3: item.fields?.section3,
          section4: item.fields?.section4,
          section5: item.fields?.section5,
          image5: item.fields?.image5?.fields?.file.url,
          image5Desc: item.fields?.image5?.fields?.description
        }
      })
      setPageContent(getContent)
    } )
    .catch(console.error)

  },[])


  return (
    <div>

      <div className="container p-0 mt-4 pt-3">
        <div className="mt-5">
          <div className="row reverse">
            <div className="col-lg-6 mt-lg-5">
              <div className="row">
                <div className="col-lg-10 p-4">
                <h3 className="font-weight-bold mb-4"><hr id="arcus-left"  />{pageContent[0]?.title}</h3>
                  <p className="clr" dangerouslySetInnerHTML={{ __html: documentToHtmlString(pageContent[0]?.section1) }}></p>
                </div></div></div>
            <div className="col-lg-6 text-center p-0"><img src={pageContent[0]?.image1} className="img-fluid" alt={pageContent[0]?.image1Desc} />
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-3">
        <div className="text-left center">
            <p className="clr" dangerouslySetInnerHTML={{ __html: documentToHtmlString(pageContent[0]?.section2) }} ></p>
        </div>
      </div>
      <div className="container mt-3">
        <div className="text-left center rt-list">
            <p className="clr" dangerouslySetInnerHTML={{ __html: documentToHtmlString(pageContent[0]?.section3) }} ></p>
            <p className="clr" dangerouslySetInnerHTML={{ __html: documentToHtmlString(pageContent[0]?.section4) }} ></p>
        </div>
      </div>

      <div className="container p-0">
        <div className="mt-5">
          <div className="row reverse">
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-10 p-4">
                <hr id="arcus-left"  />
                  <span className="clr" dangerouslySetInnerHTML={{ __html: documentToHtmlString(pageContent[0]?.section5) }}></span>
                </div></div></div>
            <div className="col-lg-6 text-center p-4"><img src={pageContent[0]?.image5} className="img-fluid" alt={pageContent[0]?.image5Desc} />
            </div>
          </div>
        </div>
      </div>
      <CtaStripe />
    </div>

  );


}


export default Privacy;
