import React from 'react'
import PropTypes from 'prop-types'

const Logo = ({view}) =>{
  return (
    (view === 'mobile') ?
    (<a className="navbar-brand d-lg-none" href="/">
    <img src="/images/RaspLogoOnly360px.png" className="img-fluid" height="40%" width="40%" alt="Rasp Logo" />
    </a>) :
    (<a className="navbar-brand d-none d-lg-block d-xl-block" href="/">
    <img src="/images/RaspLogoOnly360px.png" className="img-fluid" height="40%" width="40%" alt="Rasp Logo" />
  </a>)
  )
}

Logo.propTypes = {
  view: PropTypes.string
}

Logo.defaultProps = {
  view: 'desktop'
}

export default Logo
