/* eslint-disable react-hooks/exhaustive-deps */
import React,  { useState, useEffect, useRef } from 'react'
import BlogListing from '../BlogListing'
import client from '../Contentful'
import CtaStripe from '../CtaStripe'
import CaseStudyTeaser from '../CaseStudyTeaser'
import { amountToScroll } from "../../Utils/amountToScroll"
import DocumentMeta from 'react-document-meta'
import { useQuery, gql } from "@apollo/client"
import Loader from '../Loader'

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import CarouselHeader from "../CarouselHeader";
import MiniCard from "../MiniBlogCard";



const Insights = () => {


  const miniCardQuery = gql`
  query MiniCardData {
    blogPostCollection(
      where: {SEOIndex:true}
      order: publishDate_DESC
    ) {
      total
      items {
        title
        slug
        __typename
      }
    }

    pageBuilderCollection(where: {SEOIndex:true}){
    total
    items{
      title
      slug
      __typename

      pageBanner {
        bannerText
      }
    }
  }
  }
  `


  const [blogs, getBlogs] = useState([])
  const [caseStudy, setCaseStudy] = useState([])
  const [postsToShow, setPostsToShow] = useState([])
  const [isLoading, setLoader] = useState(false)
  const [showLoadMore,setLoadMore] = useState(true)

  useEffect(() => {
    client.getEntries({
      content_type: 'blogPost',
      order:'-fields.publishDate'
    })
    .then((response)=>{
      getBlogs(response.items)
     const initialBlogs = response.items.slice(0, 9).map(blg => {
        return {
          title: blg.fields.title,
          date: blg.fields.publishDate,
          shortDesc: blg.fields.description,
          bloglink: blg.fields.slug,
          imageSrc: blg.fields.heroImage.fields.file.url,
          altDesc:blg.fields.heroImage.fields.description,
          estimatedreadtime: blg.fields.estimatedreadtime
        }
      })
      setPostsToShow(initialBlogs)
    }).catch(console.error)

    client.getEntries({
      content_type: 'casestudy',
      'fields.slug': 'manage-contractor-compliance',
    })
    .then((response) => setCaseStudy(response.items))
    .catch(console.error)

    loopWithSlice(0, postsPerPage)

  }, [])



  const bloglist = Object.values(blogs).map(blog => {
    return {
      title: blog.fields.title,
      date: blog.fields.publishDate,
      shortDesc: blog.fields.description,
      bloglink: blog.fields.slug,
      imageSrc: blog.fields.heroImage.fields.file.url,
      altDesc:blog.fields.heroImage.fields.description,
      estimatedreadtime: blog.fields.estimatedreadtime
    }
    })

    let caseStudyData = {}
    if(typeof caseStudy[0]?.fields !== "undefined"){
      const {slug, nameofquotedperson, quotedpersonjobtitle, organisation, calloutquote, extractforteaser} = caseStudy[0]?.fields
      caseStudyData = {
        slug,
        nameofquotedperson,
        quotedpersonjobtitle,
        organisation,
        calloutquote,
        extractforteaser
      }
    }

  const postsPerPage = 9
  let arrayForHoldingPosts = []
  const ref = useRef(postsPerPage)
  const loopWithSlice = (start, end) => {
    const slicedPosts = bloglist.slice(start, end)
    arrayForHoldingPosts = arrayForHoldingPosts.concat(slicedPosts)
    setPostsToShow(arrayForHoldingPosts)
  }

  const handleShowMorePosts = () => {
    if(ref.current < bloglist.length){
      setLoader(true)
      loopWithSlice(0, ref.current + postsPerPage)
      ref.current += postsPerPage
      const scrollByPx = amountToScroll(window.innerWidth)
      setTimeout(()=>{setLoader(false); window.scrollBy(0 , scrollByPx);}, 1000)
    } else {
      setLoadMore(false)
    }
  }





  const { data:miniCardDataMain } = useQuery(miniCardQuery)


  if (!miniCardDataMain) {
    return (<Loader />)
  }


  const blogBoxData = miniCardDataMain?.blogPostCollection?.items
  const pageBuildData = miniCardDataMain?.pageBuilderCollection?.items
  const blogGraphData = [...blogBoxData,...pageBuildData]

  const meta = {
    title: 'Rasp: Explore Our Blog For Supply Chain Automation Insights',
    description: 'For insights from our team of supply chain experts, explore our blog on supplier management automation, contract management, reverse auctions and more.',
  }

  return (
    <DocumentMeta {...meta}>
    <div className="container text-center pt-5">
      <h2 className="font-weight-bold ">
        <hr id="arcus" />
        Our Latest Insights
      </h2>
      <p className="clr px-5 mt-5">
      Our insights blossom from the things we learn from the many people we deal with every day - their best and worst practices, wisdom, opinions and news.

      Our world is supply chain software so think procurement, sourcing, suppliers, products, contracts.

      If we learn something clever, insightful, controversial, cool, unexpected we'll talk about it here.

      We'll also let you know what we're doing and we'll tell the news of our products and services.

      We'd like to hear from you too - What do you think? Do you agree, disagree? Do you have something to say? Contact us and let's publish it!
      </p>
    </div>
    <BlogListing bloglist={postsToShow} />
    {isLoading && (
       <div className="col-lg-12 text-center">
        <button class="btn btn-primary" type="button" disabled>
        <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
        Loading...
      </button>
      </div>
    )}
    {showLoadMore && (
      <div className="col-lg-12 text-center pb-4">
        <button className="btn btn-secondary col-lg-3 col-md-4" onClick={handleShowMorePosts}>LOAD MORE INSIGHTS</button>
      </div>
    )}


      <CarouselHeader/>

       <div className="container mt-4">
         <div className="container col-lg-12">
           {
             blogGraphData&& <MiniCard data={blogGraphData} />
           }

         </div>
         </div>



    <CaseStudyTeaser {...caseStudyData}  />
    <CtaStripe />
    </DocumentMeta>
    )
}

export default Insights
