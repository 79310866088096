import React, { useState } from 'react'
import ClsProductCards from './ProductCards'
import CaseStudyTeaser from './CaseStudyTeaser'
import BlogListing from './BlogListing'
import BlogHeader from './BlogHeader'
import CtaStripe from './CtaStripe'
import DocumentMeta from 'react-document-meta'
import ModalVideo from 'react-modal-video'
import Slider from "react-slick"
import { useQuery, gql } from "@apollo/client"
import Loader from './Loader'

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MiniCard from './MiniBlogCard'
import CarouselHeader from './CarouselHeader'

const Home =() => {

const [isOpen, setOpen] = useState(false)

  const miniCardQuery = gql`
  query MiniCardData {
    blogPostCollection(
      where: {SEOIndex:true}
      order: publishDate_DESC
    ) {
      total
      items {
        title
        slug
        __typename
      }
    }

    pageBuilderCollection(where: {SEOIndex:true}){
    total
    items{
      title
      slug
      __typename

      pageBanner {
        bannerText
      }
    }
  }
  }
  `
  const blogPostsQuery = gql`
  query BlogPosts{
    blogPostCollection(
      order: publishDate_DESC
      limit: 3
    ) {
      items {
        title
        publishDate
        description
        slug
        heroImage {
          url
          description
        }
        estimatedreadtime
      }
    }
    casestudyCollection(
      order: [casestudyorderpriority_ASC]
      limit: 1
    ) {
      items {
        slug
        nameofquotedperson
        quotedpersonjobtitle
        organisation
        calloutquote
        extractforteaser
      }
    }
  }
  `

  const { data: miniCardDataMain } = useQuery(miniCardQuery)
  const { data: fetchedBlogData } = useQuery(blogPostsQuery)
  if(!miniCardDataMain || !fetchedBlogData) return(<Loader/>)

  const blogPostDataMain = fetchedBlogData?.blogPostCollection
  const caseStudyDataMain = fetchedBlogData?.casestudyCollection


 const blogCardData = miniCardDataMain?.blogPostCollection?.items
 const pageBuildData = miniCardDataMain?.pageBuilderCollection?.items

 const blogGraphData = [...blogCardData,...pageBuildData]

 const relatedBlogs = blogPostDataMain?.items?.map((blg) => ({
  title: blg.title,
  date: blg.publishDate,
  shortDesc: blg.description,
  bloglink: blg.slug,
  imageSrc: blg.heroImage.url,
  altDesc: blg.heroImage.description,
  estimatedreadtime: blg.estimatedreadtime,
}))

const caseStudyData = caseStudyDataMain?.items?.map((csItem) => ({
  slug: csItem.slug,
  nameofquotedperson: csItem.nameofquotedperson,
  quotedpersonjobtitle: csItem.quotedpersonjobtitle,
  organisation: csItem.organisation,
  calloutquote: csItem.calloutquote,
  extractforteaser: csItem.extractforteaser,
}));
  const meta = {
    title: 'Rasp: Enterprise-level supplier management software',
    description: 'Choose from cloud-based modules of our supplier management software - or enjoy the full ARCUS suite - to manage your tenders, vendors, contractors and contracts.',
    canonical: 'https://rasp.com.au',
  }


  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,

    autoplay:true,
    autoplaySpeed:5000,
    swipeToSlide: true,
    // adaptiveHeight: true,
    // rows:1,
    // slidesPerRow:1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          // dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ],
  }

        return (
          <DocumentMeta {...meta}>
        <div>
               <div className="bg">
        <br /><br /><br />
        <div className="container">
          <div className="row">
            <div className="col-lg-9 txt float-left mt-3 ml-3 mx-lg-3 mx-xl-3">
              <h1 className="display-4">Supply Chain Software</h1>
              <h4 className="mt-4">ARCUS Sourcing</h4>
              <h4 className="mt-2">and</h4>
              <h4 className="mt-2">Supplier Management Software</h4>
            </div>
            <div className="col-lg-3" /></div>
        </div>
        <div className="container">
          <div className="col-lg-8 txt mt-4 mx-lg-2 mx-xl-2">
            <div className="row">
              <div className="col-lg-6 text-left">
                <p className="ml-2">South East Water is a Victorian Government
                  corporation delivering water to 1.7 million
                  people across Melbourne's south east</p>
              </div>
               {/*Button below used to visible only in mobile <button type="button" class="btn btn-block btn-primary d-lg-none   mt-n2 mb-4">READ THE CUSTOMER STORY</button>*/}
              <div className="col-lg-6 text-left"> <a href="/case-study/manage-contractor-compliance">
                  <button type="button" className="btn btn-block btn-primary d-lg-none mb-3">READ THE CUSTOMER STORY</button></a>
                <p className="ml-3"> Speak with a us to discuss your Supply Chain software requirements.</p>
              </div>
            </div>
            <div className="container col-lg-4" />
          </div>
        </div>
        <div className="container mt-3"> <div className="col-lg-8 txt  text-justify">
            <div className="row">
              <div className="col-lg-6">
                <a href="/case-study/manage-contractor-compliance">
                  <button type="button" className="btn btn-block btn-primary d-none d-lg-block d-xl-block mt-n4 mb-3">
                    READ THE CUSTOMER STORY</button></a>
              </div>
              <div className="col-lg-6">
                <a href="/contact-us">
                  <button type="button" className="btn btn-block btn-primary mt-n4 mb-3">SPEAK WITH US</button>
                </a>
              </div>
            </div>
          </div></div>
      </div>

      <div className="container mt-4">
        <h4 className="mb-3 ml-5">I need help with...</h4>
         {/* Control the column width, and how they should appear on different devices */}
        <div className="row">
          <div className="col-lg-4">
            <div className="col-lg-12"><a href="/etender"> <button type="button" className="btn btn-outline-warning btns mb-3 w-100">TENDERING CONTRACTS</button></a></div>
            <div className="col-lg-12"><a href="/reverse-auction-eauction"> <button type="button" className="btn btn-outline-warning btns mb-3 w-100">PRICE NEGOTIATION</button></a></div>
            <div className="col-lg-12 w-100"><a href="/sourcing-management"> <button type="button" className="btn btn-outline-warning mb-3 btns w-100">STRATEGIC SOURCING SOLUTION</button></a></div>
          </div>
          <div className="col-lg-4">
            <div className="col-lg-12" s> <a href="/supplier-information-management"><button type="button" className="btn btn-outline-warning btns mb-3 w-100">MANAGING MY SUPPLIERS</button></a></div>
            <div className="col-lg-12"> <a href="/contract-management"> <button type="button" className="btn btn-outline-warning btns mb-3 w-100">MANAGING MY CONTRACTS</button></a></div>
            <div className="col-lg-12"><a href="/supplier-performance-management"> <button type="button" className="btn btn-outline-warning btns mb-3 w-100">SUPPLIER PERFORMANCE</button></a></div>
          </div>
          <div className="col-lg-4">
            <div className="col-lg-12"><a href="/product-information-management"> <button type="button" className="btn btn-outline-warning  btn-line-h mb-3 w-100">MANAGING SUPPLIER PRODUCTS <br />(FOOD)</button></a></div>
            <div className="col-lg-12"><a href="/food-industry-software"> <button type="button" className="btn btn-outline-warning btns mb-3 w-100">MANAGING FOOD SUPPLIERS</button></a></div>
            <div className="col-lg-12"><a href="/reverse-auction-eauction"> <button type="button" className="btn btn-outline-warning btns mb-3 btn-h w-100">REDUCING COSTS</button></a></div></div>
        </div>
      </div>
            {/* <!-------------------------------------------- Carousel Slider ----------------------------------->  */}

            <div className="container my-3">
        <h4 className="text-center mb-3">Some of our fabulous customers....</h4>
        <Slider {...settings} className="" style={{backgroundColor:''}}>
        <div className="p-3 d-flex align-items-center justify-content-center"><img src="images/carousel/newscorplogo150px.png" alt="Logo of News Corp who are a customer of Rasp and user of ARCUS supplier management software" /></div>
          <div className="p-3 d-flex align-items-center justify-content-center"><img src="images/carousel/downerlogo170px.png" alt="Logo of Downer who are a customer of Rasp and user of ARCUS supplier management software" /></div>
          <div className="p-3 d-flex align-items-center justify-content-center"><img src="images/carousel/goodmanfielderlogo180px.png" className="py-auto" alt="Logo of Goodman Fielder who are a customer of Rasp and user of ARCUS supplier management software" /></div>
          <div className="p-3 d-flex align-items-center justify-content-center"><img src="images/carousel/iaglogo150px.png" alt="Logo of IAG who are a customer of Rasp and user of ARCUS supplier management software" /></div>
          <div className="p-3 d-flex align-items-center justify-content-center"><img src="images/carousel/TNTlogo150px.png" alt="Logo of TNT who are a customer of Rasp and user of ARCUS supplier management software" /></div>
          <div className="p-3 d-flex align-items-center justify-content-center"><img src="images/carousel/acclogo150px.png" alt="Logo of ACC who are a customer of Rasp and user of ARCUS supplier management software" /></div>
          <div className="p-3 d-flex align-items-center justify-content-center"><img src="images/carousel/spotlesslogo170px.png" alt="Logo of Spotless who are a customer of Rasp and user of ARCUS supplier management software" /></div>
          <div className="p-3 d-flex align-items-center justify-content-center"><img src="images/carousel/sewlogo170px.png" alt="Logo of South East Water who are a customer of Rasp and user of ARCUS supplier management software" /></div>
          <div className="p-3 d-flex align-items-center justify-content-center"><img src="images/carousel/baxterlogo150px.png" alt="Logo of Baxter who are a customer of Rasp and user of ARCUS supplier management software" /></div>
          <div className="p-3 d-flex align-items-center justify-content-center"><img src="images/carousel/kaingaoralogo180px.png" alt="Logo of Kainga Ora who are a customer of Rasp and user of ARCUS supplier management software" /></div>
          <div className="p-3 d-flex align-items-center justify-content-center"><img src="images/carousel/cwwlogo200px.png" alt="Logo of City West Water who are a customer of Rasp and user of ARCUS supplier management software" /></div>
          <div className="p-3 d-flex align-items-center justify-content-center"><img src="images/carousel/yvwlogo150px.png" alt="Logo of Yarra Valley Water who are a customer of Rasp and user of ARCUS supplier management software" /></div>
        </Slider>
      </div>
            {/* <!======================================= Milestone 2 start ========================================>   */}

            <div className="container mt-5">
        <div className="container-fluid col-lg-12">
          <div className="row">
            <div className="col-lg-6">
              <h3 className="pb-2"><hr id="arcus" className="m-0 mb-2" /> ARCUS—Australia and New Zealand’s modular supply chain management platform</h3>
              <p className="clr">The ARCUS platform allows unparalleled cloud-based management of your tenders, contractors, vendors, and contracts. While some companies take advantage of the full ARCUS suite, many simply choose the individual modules needed and have it configured to their industry and needs.<br /><br />
                The most flexible solution on the market, ARCUS finally allows organisations to implement the latest in enterprise-level supply chain management tools. You only pay for what you use and there’s no need for IT staff, thanks to the support capabilities of Rasp, the regional team for Australia and New Zealand.
                Based in Sydney, the Rasp team is directly working as your partner to get the most out of the ARCUS suite and revolutionise your supply chain processes. <br /><br />
                Flagship modules within ARCUS are e-RFx, a powerful online tool for streamlining tenders and proposals, and SIM, an intuitive and time-saving supplier information manager helping your team with supplier pre-qualification and compliance. SIM is also available with specialised extensions for supplier product management and new product development for the food industry.</p>
            </div>
            <div className="col-lg-6 p-auto">
              <img src="images/Screen and Screenshots.jpg" className="img-fluid ml-4" alt="Screenshots of ARCUS Contractor Management software and e-sourcing system." />
            </div>
          </div> </div>
      </div>
        {/* <!---------------------------------------our solution-------------------------------------->  */}
        <div className="container bgshadow">
        <div className="container text-center mt-5">
        {/*---------------------------------------Cards-----------------------------------------*/}
          <ClsProductCards></ClsProductCards>
        </div>
       {/*-----------------------------------Speak with us---------------------------------------------*/}
        <div className="container mt-5 text-center">
          <ModalVideo channel='vimeo' autoplay isOpen={isOpen} videoId="494008157" onClose={() => setOpen(false)} />
          <button className="btn btn-primary my-5 spacing video-btn" onClick={()=> setOpen(true)}>VIEW ARCUS OVERVIEW VIDEO</button>
          <a href="/contact-us"><button type="button" className="btn btn-outline-primary speak text-center font-weight-bold w-75 p-2">Speak with us to see which module works best with your organisational needs</button></a>
        </div>
        <div className="container mt-5">
          <hr id="arcus" />
          <h3 className="font-weight-bold text-center">Pioneering Supply Chain Software with the Food Industry <i className="fa fa-utensils fa-lg ml-2 mt-1" /></h3>
        </div>
        <div className="container mt-5">
          <p className="text-center">Rasp is pioneering the introduction of superior supply chain software within the Australian and New Zealand Food Industry. Through the ARCUS suite’s real-time Product Information Management module and its endless customisation options, more organisations within the Food Industry are turning to ARCUS to help streamline their supply chain and create operational efficiencies.</p>
        </div>
        <div className="container text-center mt-5">
          <a href="/food-industry-software" className="btn btn-primary">ARCUS IN THE FOOD INDUSTRY</a>
        </div>
      </div>

              {/* <!======================================= Milestone 2 end ======================================>
	    <!======================================= Milestone 3 start ====================================>  */}

     <div className="container-fluid bgshadow1 mt-4">
        <div className="container">
          <div className="container text-center pt-5">
            <hr id="arcus" /><h3>How It Works</h3>
          </div>
          <div className="container col-lg-12 text-center">
            <div className="row">
              <div className="col-lg-4 mt-5"> <div className="card h-100 bg-transparent border-0">
                  <img src="images/Icons/Support Team Icon.svg" className="img-fluid mx-auto w-25 h-25 mb-3" alt="Card" />
                  <div className="card-body pt-1 p-1">
                    <h5>Sydney Support Team</h5>
                    <p className="card-text mb-5">Operating out of the Sydney CBD, we’re on hand to support and guide you through using the ARCUS suite and achieving your supplier management goals. Our recommendations are based on providing source-to-contract services for over 12 years.</p>
                  </div>
                </div></div>
              <div className="col-lg-4 mt-5"><div className="card h-100 bg-transparent border-0">
                  <img src="images/Icons/Certificate Icon.svg" className="img-fluid mx-auto w-25 h-25 mb-3" alt="Card" />
                  <div className="card-body pt-1 p-1">
                    <h5>Secure: ISO27001 certified</h5>
                    <p className="card-text mb-5">Securely store and manage your supplier contracts in one, central location. A custom contract Lifecycle Management system helping you with important events such as contract expiries and more.</p>
                  </div>
                </div></div>
              <div className="col-lg-4 mt-5"><div className="card h-100 bg-transparent border-0">
                  <img src="images/Icons/Machine and Cog Icon.svg" className="img-fluid mx-auto w-25 h-25 mb-3" alt="Card" />
                  <div className="card-body pt-1 p-1">
                    <h5>Completely Customisable</h5>
                    <p className="card-text mb-5">The ARCUS platform seamlessly links modules together through a central hub, with each module endlessly customisable for your industry and individual needs. ARCUS can also integrate with other business systems such as your ERP platform, for effortless passing of data.</p>
                  </div>
                </div></div>
            </div>
          </div></div>
      </div>
             {/* <!--------------------------------------testemonials------------------------------------------------>  */}
                <CaseStudyTeaser {...caseStudyData[0]} />

             {/* <!--------------------------------why other choose ARCUS 1-------------------------------------------->  */}
             <div className="container-fluid bgimage3 p-0">
        <div className="bg-image2">
          <div className="container text-center pt-5"><h3 className="font-weight-bold "><hr id="arcus" />Why Others Choose ARCUS</h3>
            <p className="clr">Our clients tell us that these are the reasons why they use Rasp and ARCUS:</p>
          </div>
          <div className="text-right p-0 hide">
            <img src="images/IT Man 350px.png" className="img-fluid" alt="Business professional in suit representing Rasp Pty Ltd" /></div>
          <div className="container-fluid bg-image1 p-0">
            <div className="container-fluid p-0">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6"><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" />
                    <p className="clr pl-5"><span className="clr1">Flexibility</span> - Choose modules as needed and have these modules customised to your specific organisational needs ARCUS works seamlessly with your organisation’s workflow.</p></div>
                  <div className="col-lg-6" />
                </div></div>
              <div className="container mt-2">
                <div className="row">
                  <div className="col-lg-6"><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" />
                    <p className="clr pl-5">
                      <span className="clr1">Security</span> - Certified ISO27001. Your data is secure at any stage while with ARCUS. From the development environment to our hosting infrastructure, we maintain the highest standards of global security certification.</p></div>
                  <div className="col-lg-6" />
                </div></div>
              <div className="container mt-2">
                <div className="row">
                  <div className="col-lg-6"><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" />
                    <p className="clr pl-5"><span className="clr1">Expertise</span> - We’re one of Australia and New Zealand’s most trusted source-to-contract software suppliers, having supplied expert guidance and counsel since 2007 to several multi-national organisations in a range of industries.</p></div>
                  <div className="col-lg-6" />
                </div></div>
              <div className="container mt-2">
                <div className="row">
                  <div className="col-lg-6"><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" />
                    <p className="clr pl-5"><span className="clr1">Simplicity</span> - The ARCUS suite is easy to use and allows intuitive and effortless management of suppliers throughout all stages of your supply chain—no matter how complex.</p></div>
                  <div className="col-lg-6" />
                </div></div>
              <div className="container mt-2">
                <div className="row">
                  <div className="col-lg-6"><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" />
                    <p className="clr pl-5"><span className="clr1">Cloud-Based</span> - ARCUS is completely cloud-based, so there’s no need to install software on your own computers, manage the associated hardware to run and maintain it, or update systems. You simply access ARCUS wherever you have the internet.</p></div>
                  <div className="col-lg-6" />
                </div></div>
            {/*------------------------------why other choose ARCUS 2----------------------------------------*/}
              <div className="container mt-5">
                <div className="row">
                  <div className="col-lg-6" />
                  <div className="col-lg-6"><h4 className="font-weight-bold text-center"><hr id="arcus" />ARCUS is Trusted By Some of Australia &amp; New Zealand’s Largest Organisations</h4></div>
                </div>
              </div>
              <div className="container mt-5">
                <div className="row">
                  <div className="col-lg-6" />
                  <div className="col-lg-6"><p className="clr"><span className="clr1"> ANZ Bank</span> - Employing 48,000 people worldwide, ANZ provides a range of banking and financial offers to 8 million customers. They used ARCUS e-RFX and e-Auction to increase organisational efficiencies, reduce costs, and increase supplier transparency. Through e-Auction, they saved an average of 22% during contract renegotiations. <br /><br /> “It absolutely exceeded our expectations and was unbelievably successful. The feedback initially from the suppliers was that we were already getting their most competitive pricing, however by providing an alternative costing model, their prices reduced collectively, which gave us confidence in the real market.”<br /><br /> – Alison De La Garde, Regional Category Manager, Business Services</p>
                    <p className="pb-3 clr"><span className="clr1"> South East Water</span> - South East Water is a Victorian Government corporation delivering water, sewerage, and recycled water services to 1.7 million people across Melbourne’s south east. Using ARCUS SIM, they’re able to accurately mane hundreds of accredited organisations and key personnel, with thousands of compliance updates automatically managed in real-time.<br /><br /> “We could see that ARCUS SIM’s solution could be configured to work the way we wanted it to and address our questions and our approval processes. We could also see that our biggest issue of keeping the information up to date would be handled by the system and the suppliers themselves.”<br /><br /> – Bruce Johnson, Performance &amp; Accreditation Manager</p>
                  </div>
                </div></div></div>
            <div className="container-fluid text-left hide p-0">
              <img src="images/Laptop and Background 350px.png" className="img-fluid pb-4" alt="Laptop with pic depicting latest technology used in ARCUS eSourcing and supplier management software." />
            </div></div></div></div>
            {/* <!----------------------------------------About RASP---------------------------------------------->   */}


            <div className="container-fluid bgimage4 bg-color bgimage5 pt-3">
        <div className="container py-5">
          <div className="row">
            <div className="col-lg-6 bgcolor">
              <h3 className="text-left font-weight-bold"><hr id="arcus" className="mb-2 m-0" />About Rasp</h3>
              <p className=" d-none d-lg-block d-xl-block pb-5">Founded in Sydney in 2007 by Managing Director Kevin Burke, Rasp provides Australian and New Zealand organisations the most adaptable cloud-based source-to-contract supplier management software available through the ARCUS platform. <br /><br />
                Combining the Rasp team’s specialised supplier management and procurement expertise with the latest in cloud computing technology, Rasp is well-noted for its superior local support and guidance towards client’s supply chain management challenges.</p>
              <div className="d-lg-none text2">
                <p> Founded in Sydney in 2007 by Managing Director Kevin Burke, Rasp provides Australian and New Zealand organisations the most adaptable cloud-based source-to-contract supplier management software available through the ARCUS platform.</p>
                <div className="container-fluid col-lg-12 p-0">
                  <div className="row">
                    <div className="col-8"><p>Combining the Rasp team’s specialised supplier management and procurement expertise with the latest in cloud computing technology, Rasp is well-noted for its superior local support and guidance towards client’s supply chain management challenges.</p></div>
                    <div className="col-4" />
                  </div></div>
              </div>
              <div><a href="/about-us"><button type="button" className="btn btn-primary mb-5">LEARN MORE</button></a>
              </div>
            </div>
            <div className="col-lg-6 " />
          </div></div>
      </div>
        {/* <!======================================Milestone 3 End==========================================> <!=====================================Milestone 4 start==========================================>  */}
            <BlogHeader />
        {/*======================================Blogs===============================================*/}

            <BlogListing bloglist={relatedBlogs} />

        {/*======================================Blogs End===============================================*/}
         <CarouselHeader/>
        {/* <!-------------------------------------------- Carousel Slider ----------------------------------->  */}
         <div className="container mt-4">
         <div className="container col-lg-12">
          <MiniCard data={blogGraphData} />
         </div>
         </div>
        <CtaStripe />
        </div>
        </DocumentMeta>
        );
}

export default Home;
