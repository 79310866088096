import React, { useState} from 'react'
import { useLocation } from 'react-router-dom'
import { useQuery, gql } from "@apollo/client"
import ModalVideo from 'react-modal-video'
import CtaStripe from './CtaStripe'
import BlogListing from './BlogListing'
import BlogHeader from './BlogHeader'
import ClsProductCards from './ProductCards'
import CaseStudyTeaser from './CaseStudyTeaser'
import PDF from '../Pdf/ARCUS eRFx Overview.pdf'
import DocumentMeta from 'react-document-meta'
import Loader from './Loader'
import FaqLists from './FaqLists'


// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarouselHeader from './CarouselHeader'
import MiniCard from './MiniBlogCard'




const blogPostsQuery = gql`
query BlogPosts{
  blogPostCollection(where:{blogPostProduct_contains_all:"eRFx", SEOIndex:true} order:[publishDate_DESC] limit:3){
    items{
      title
      publishDate
      description
      slug
      heroImage{
        url
        description
      }
      estimatedreadtime
    }
  }
  casestudyCollection(where:{casestudytags_contains_all:"eRFx", SEOIndex:true} order:[casestudyorderpriority_ASC] limit:1){
    items{
      slug
      nameofquotedperson
      quotedpersonjobtitle
      organisation
      calloutquote
      extractforteaser
    }
  }
}
`;


const blogpostCardMiniQuery = gql`
query BlogpostCardMini {
  blogPostCollection(where:{SEOIndex:true, blogPostProduct_contains_all:"eRFx"} order:[publishDate_DESC]){
    total
    items{
      title
      slug
    }
  }

}
`


const PageBuildQuery = gql`
query pageBuildQuery{
pageBuilderCollection(where: {SEOIndex:true, pageBuilderProduct_contains_all:"eRFx"} ){
 total
 items{
   title
   slug
   __typename
   pageBanner {
     bannerText
   }
 }
}
}`





const Etender = () => {
  const [isOpen, setOpen] = useState(false)

  const { data:blogPostDataCurrent } = useQuery(blogPostsQuery);

  const location = useLocation()




  const faqMappingUrl = location.pathname;
  const faqListQuery = gql`
  query FaqLists ($url: String!){
    addOnFaqListCollection(where:{addOnFaqListSlug: $url}){
      items{
        faqList{
          title
          listCollection{
            items{
              question
              answerText
            }
          }
        }
      }
    }
  }
  `
  const { data: faqDataCurrent } = useQuery(faqListQuery, {
    skip: !location || !location.pathname,
    variables: {
      url:
        location && location.pathname
          ? faqMappingUrl.slice(1)
          : null,
    },
  });


  const { data:blogGraphData } = useQuery(blogpostCardMiniQuery)
  const {data: pagebuilderData} = useQuery(PageBuildQuery)



  if (!faqDataCurrent || !blogGraphData || !pagebuilderData) {
    return <Loader />;
  }


  const blogBoxData = blogGraphData?.blogPostCollection?.items

  const pageBuildData = pagebuilderData?.pageBuilderCollection?.items

  const blogCardData = [...blogBoxData, ...pageBuildData]




  const faqData = faqDataCurrent?.addOnFaqListCollection?.items[0]?.faqList

  const seoData = faqDataCurrent?.addOnFaqListCollection?.items[0]?.faqList?.listCollection?.items


  let mainEntity = []
   seoData&&
    seoData.map((res,id)=> {
    return mainEntity[id] = {
        "@type": "Question",
        "name": res.question,
        "acceptedAnswer": {
          "@type": "Answer",
          "text": res.answerText,
        }
      }
    })

 let schema = mainEntity.length === 0 ? null : {
     "@context": "https://schema.org",
     "@type": "FAQPage",
     "mainEntity": mainEntity
   }





  const path = location.pathname
  const url = path.substr(path.lastIndexOf('/') + 1)
  localStorage.setItem('eTender',`/${url}`)
      if (!blogPostDataCurrent)
    return (
      <Loader />
    );

  const caseData = blogPostDataCurrent.casestudyCollection;
  const { items } = blogPostDataCurrent.blogPostCollection;

const caseStudyData = caseData.items.map(csItem => {
  return {
    slug: csItem.slug,
    nameofquotedperson:csItem.nameofquotedperson,
    quotedpersonjobtitle:csItem.quotedpersonjobtitle,
    organisation:csItem.organisation,
    calloutquote:csItem.calloutquote,
    extractforteaser:csItem.extractforteaser
  }
})

const initialBlogs = items.map(blg => {
  return {
    title: blg.title,
    date: blg.publishDate,
    shortDesc: blg.description,
    bloglink: blg.slug,
    imageSrc: blg.heroImage.url,
    altDesc:blg.heroImage.description,
    estimatedreadtime: blg.estimatedreadtime
  }
})


 const downloadPDF = () => {

    fetch(PDF)
      .then(response => {
        response.blob().then(blob => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          var sub = PDF.substr(14);
          a.download =sub;
          a.click();
        });
    });
  }

  const meta = {
    title: 'Rasp: Explore The Features Of Our Secure eTendering System',
    description: 'ARCUS e-RFx is an easy-to-use eTendering system for RFP, RFI, RFQ or RFT. Our system is highly secure with probity characteristics and is ISO27001-certified.',
   // canonical: 'http://example.com/path/to/page',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'etendering system'
      }
    }
  }
            return(
              <DocumentMeta {...meta}>
              <div>

              {schema && (
                <div
                dangerouslySetInnerHTML={{
                  __html: `
                    <script type="application/ld+json">${JSON.stringify(
                      schema
                    )}</script>
                    `
                  }}
                />
              )}

                 {/* <!====================================text start================================>  */}

                 <div className="bgshadow3 pt-5">
                   <div className="container mt-5 text-center">
                    <div className="row">
                    <div className="col-lg-6 m-auto"><img src="images/Product Logos/eRFX Logo.svg" className="img-fluid mt-2" alt="ARCUS eRFx system logo" /></div>
                    <div className="col-lg-6 mt-2 mt-sm-4 text-left center">
                      <h2 className="font-weight-bold"><hr id="arcus" className="m-lg-0 mt-n5 pb-2" />ARCUS eRFx </h2>
                      <h3>Online Tenders</h3>
                    </div>
                    </div>

                    {/* <!-------------horizontal divider---------------------------------> */}
                    <div className="container"><hr /></div>

                    <div className="mt-5">
        <div className="row">
          <div className="col-lg-1" />
          <div className="col-lg-4 mt-3">
            <button className="btn btn-warning font-weight-lighter btnedit p-lg-4 p-md-5 p-sm-4 text-white">1-12 Week Reduction in Sourcing Cycle-Time!</button>
          </div>
          <div className="col-lg-1" />
          <div className="col-lg-5 mt-2">
            <div className="m-auto d-block d-lg-none"><p className="small text-left clr px-lg-4 mt-4">*ARCUS e-RFx customers claim reduced sourcing cycle times of up to 12 weeks for large tenders.</p></div>
            <p className="clr text-left font-italic fs pl-xl-5 pt-lg-0 pt-sm-3">"Our internal stakeholders were very happy, especially with the easy scoring. They definitely had a more positive experience, which resulted in a friendlier <br /> relationship with us and helped to change their perception of tendering"</p>
          </div>
          <div className="col-lg-1" />
        </div>
        <div className="row mt-3">
          <div className="col-lg-1" />
          <div className="col-lg-4 m-auto d-none d-lg-block"><p className="small text-left clr px-lg-4">*ARCUS e-RFx customers claim reduced sourcing cycle times of up to 12 weeks for large tenders.</p></div>
          <div className="col-lg-1" />
          <div className="col-lg-5"><p className="small text-left clr pl-xl-5">Matthew Fitzgerald, Category Manager, ANZ Bank</p></div>
          <div className="col-lg-1" />
        </div>
      </div>

                    <div className="text-center mt-5">
                    <h3 className="font-weight-bold "><hr id="arcus" />What is it?</h3>
                    </div>
                    <div className="text-left center mt-5">
                      <p className="clr">ARCUS e-RFx is an easy-to-use online tender system for Request for Proposal (RFP), Request for Information (RFI), Request for Quotation (RFQ) or Request for Tender (RFT). Suppliers enter their responses directly into online forms which results in an instant side-by-side view of all supplier submissions. The minute the tender closes, it is available to reviewers for immediate scoring - No downloading, copying, compiling or distribution of responses required.</p>
                    </div>

                   </div>

                   {/* <!-----------------------------------sim dashboard start-------------------------------------> */}

                   <div className="container-fluid p-0">
        <div className="mt-5">
          <div className="row reverse">
            <div className="col-lg-6 m-auto">
              <div className="row">
                <div className="col-lg-2 hide-at" />
                <div className="col-lg-10">
                  <p className="ml-3 clr">ARCUS e-RFX provides your organisation with an easy-to-use, fully featured cloud based e-Sourcing solution. The system is highly secure with impeccable probity characteristics and is ISO27001 certified, the global standard for digital information security.</p>
                  <p className="ml-3 clr">ARCUS e-RFx comes as a complete package: The ARCUS support team will provide you with exceptional service levels, your own branded e-Rfx site and full training and support for both your internal users and all suppliers.</p>
                  <p className="ml-3 clr font-italic">"Immediately, we were able to see how price compares at total cost, and quickly make some calculations without having to physically sift through papers. It was so easy, a breath of fresh air"</p>
                  <p className="ml-3 clr font-italic">Chris Kechagias, Procurement Specialist</p>
                </div></div></div>
            <div className="col-lg-6 text-center p-0"><img src="images/eRFx Questionnaire@2x.png" className="img-fluid" alt="e-RFx e-Sourcing questionnaire" />
            <ModalVideo channel='vimeo' autoplay isOpen={isOpen} videoId="602270661" onClose={() => setOpen(false)} />
            <button className="btn btn-primary my-5 spacing Rbtnmargin video-btn" onClick={()=> setOpen(true)}>VIEW eRFX VIDEO</button>
            </div>
          </div>
        </div>
      </div>

                    {/* <!-----------------------------------sim dashboard end-------------------------------------> */}

                    {/* <!-------------------------------sim questionnaire start-------------------------------> */}

                    <div className="container-fluid">
                      <div className="row reverse">
                        <div className="col-lg-6 text-center p-0">
                          <img src="images/eRFx Compare All@2x.png" className="img-fluid mt-5" alt="Side by side comparison of e-Tender submissions" />
                          <button type="button" onClick={downloadPDF} className="btn btn-primary my-5 Lbtnmargin">DOWNLOAD FACTSHEET</button>
                        </div>
                        <div className="col-lg-6 mt-3 px-5 m-auto">
                          <div className="mx-4 mt-sm-4 text-left center">
                            <h2 className="font-weight-bold"><hr id="arcus" className="m-lg-0 mt-n5 pb-2" />How does it work?</h2>
                          </div>
                          <div className="mt-4"><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">Significant time saving and reduced sourcing cycle times (tender start to finish time).</p></div>
                          <div className="mt-4"><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">Back in control - Live visibility of all tendering activity</p></div>
                          <div className="mt-4"><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">Project cost savings – More competition through more projects put to tender and more compliant submissions received.</p></div>
                          <div className="mt-4"><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">Improved stakeholder collaboration with a central point of eRFX access.</p></div>
                          <div className="mt-4"><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">Strong probity characteristics and a comprehensive audit trail.</p></div>
                          <div className="mt-4"><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5"> Improved business practices and efficiency (Procurement team looks more professional!).</p></div>
                          <div className="mt-4"><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">Environmental considerations – less paper wastage.</p></div>
                          <div className="mt-4"><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">Top notch support and advice on tenders directly from Rasp.</p></div>
                          <div className="mt-4"><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">Robust system security and user access controls. (ISO 27001 certified).</p></div>
                        </div>
                      </div>
                    </div>

                  </div>   {/* <!---shadow end here---> */}

                 {/* <!-------------------------------sim questionnaire end---------------------------------> */}

                 {/* <!====================================text end================================>  */}


                 {/* ==========================faqlist start============================ */}

                  {faqData && (
                  <div className="col-lg-6 m-auto py-3">
                    <FaqLists {...faqData}/>
                  </div>
                  )}

                  {/* ==========================faqlist end============================ */}


                 <div className="container bgshadow">
                 {/* <!==============================Product Cards start============================> */}
                 <ClsProductCards></ClsProductCards>
                 </div>

                 {/* <!==============================Product Cards end==============================> */}

                {/* <!==============================case study with city start==============================>  */}
                <div className="container-fluid mt-5 p-0 bgimage">
                {caseStudyData[0] && (
                  <CaseStudyTeaser {...caseStudyData[0]} />
                )}
                </div>
                {/* <!==============================case study with city End==============================>  */}

                <BlogHeader />

                {/* <!==============================blog Cards start==============================>   */}

                    <BlogListing bloglist={initialBlogs} />

                {/* <!==============================blog Cards end==============================>   */}



                <CarouselHeader/>

       <div className="container mt-4">
         <div className="container col-lg-12">
           {
             blogCardData&& <MiniCard data={blogCardData} />
           }

         </div>
         </div>



                    <CtaStripe />
              </div>
              </DocumentMeta>
        )
}
export default Etender;
