import React from 'react';
import propTypes from 'prop-types';


class Case_Study_Index extends React.Component{

  render() {

        const {article} = this.props
        let html=[];
        var id = this.props.id
        const link = `/case-study/${article.fields.slug}`
        // ================================Applying For Loop Display Alternative Design===================================

        for(let i=id;  i <=id; i++)
        {
          if(i%2===0)
          {
          html.push(

            <div className="container-fluid bgimage7"  >
            <div className="container mt-5">
              <div className="row ">
                <div className="col-lg-4 m-auto text-center box">
                  <img src={article.fields.logo.fields.file.url} className="img-fluid mob" alt={article.fields.logo.fields.description} />
           <a href={link}> <button className="btn btn-primary my-4">READ THE FULL CUSTOMER STORY NOW</button></a>
                </div>
                <div className="col-lg-8 m-auto">
                  <div className="container-fluid text-center text-white bg-white rad boxshadow">
                    <div className="row tbmargin">
                      <div className="col-lg-5 bgimage2 text-center">
            <p className="text-white text5 p-1 pt-3">{article.fields.nameofquotedperson}<br></br>{article.fields.quotedpersonjobtitle}<br></br>{article.fields.organisation}</p>
                        <div className="row ">
        <div className="col-lg-12 mt-5"><h5 className="clr paddingtext"><i>"{article.fields.calloutquote}"</i></h5></div>
                        </div></div>
                      <div className="col-lg-7 m-auto">
        <h5 class="font-weight-bold pt-3">{article.fields.teaserheading}</h5>
        <p class="clr text-center"><blockquote class="quotebox quotebox--quotes clr">{article.fields.extractforteaser}</blockquote></p>
            </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          )
        }
        else
        {
          (
            html.push(<div className="container-fluid bgimage6">
            <div className="container mt-5">
              <div className="row reverse">
                <div className="col-lg-8 m-auto">
                  <div className="container-fluid text-center text-white bg-white rad boxshadow">
                    <div className="row tbmargin">
                      <div className="col-lg-5 bgimage2 text-center">
            <p className="text-white text5 p-1 pt-3">{article.fields.nameofquotedperson}<br></br>{article.fields.quotedpersonjobtitle}<br></br>{article.fields.organisation}</p>
                        <div className="row">
            <div className="col-lg-12 mt-5"><h5 className="clr paddingtext"><i>"{article.fields.calloutquote}"</i></h5></div>
                        </div></div>
                      <div className="col-lg-7 m-auto">
                      <h5 class="font-weight-bold pt-3">{article.fields.teaserheading}</h5>
                        <p className="clr text-center"><blockquote class="quotebox quotebox--quotes clr">{article.fields.extractforteaser}</blockquote></p></div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 m-auto text-center box">
                  <img src={article.fields.logo.fields.file.url} className="img-fluid mob" alt={article.fields.logo.fields.description} />
                <a href={link}> <button className="btn btn-primary my-4">READ THE FULL CUSTOMER STORY NOW</button></a>
                </div>
              </div>
            </div>
          </div>)
          )
        }
      }

        return (
                 <div>
         {/* <!====================================Case Study Index Page Dynamically Content Start================================>  */}

                         {html}
                 </div>

           /* <!====================================Case Study Index Page Dynamically Content End================================>  */
        );
        }
}
Case_Study_Index.propTypes = {
    article: propTypes.object
    }
export default Case_Study_Index
