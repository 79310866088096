/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const MegaMenuPngComponent = () => (
  // <svg width={790} height={280} viewBox="0 0 1415 540">
  // <svg width={1415} height={540} viewBox="0 0 1415 540">
  <svg width={790} height={280} viewBox="0 0 1415 540">
    <defs>
      <style>
        {
          ".a{clip-path:url(#c);}.b{fill:#1676a6;}.b,.c{stroke:#707070;opacity:0.02;}.c{fill:url(#a);}.d,.j{fill:#078d40;}.d,.e,.f{font-size:57.255px;}.d,.e,.f,.m{font-family:Montserrat-ExtraLight, Montserrat;font-weight:200;}.e,.l{fill:#e4812f;}.f{fill:#b31833;}.g,.h,.i,.o,.p,.s{fill:none;}.g{stroke:#078d40;}.h{stroke:#b31833;}.i{stroke:#e4812f;}.j,.k,.l,.n,.q{font-size:24px;font-family:Montserrat-Regular, Montserrat;}.k{fill:#e53e22;}.m,.n{fill:#03bdff;}.m{font-size:56px;}.o,.p{stroke:#03bdff;}.p{stroke-dasharray:5 5;}.r{stroke:none;}"
        }
      </style>
      <pattern
        id="a"
        preserveAspectRatio="xMidYMid slice"
        width="100%"
        height="100%"
        viewBox="0 0 98 50"
      >
        <image width={98} height={50} xlinkHref="/images/MegaMenu/ComponentTMP_0-image.png" />
      </pattern>
      {/* <clipPath id="c">
        <rect width={1415} height={540} />
      </clipPath> */}
    </defs>
    <g id="b" className="a">
      <g className="b">
        <path
          className="r"
          d="M0,0H694a400,400,0,0,1,400,400V540a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
        />
        <path
          className="s"
          d="M1,.5H694A399.5,399.5,0,0,1,1093.5,400V539a.5.5,0,0,1-.5.5H1a.5.5,0,0,1-.5-.5V1A.5.5,0,0,1,1,.5Z"
        />
      </g>
      <g className="c">
        <path
          className="r"
          d="M0,0H875a540,540,0,0,1,540,540v0a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
        />
        <path
          className="s"
          d="M1,.5H876A538.5,538.5,0,0,1,1414.5,539v0a.5.5,0,0,1-.5.5H1a.5.5,0,0,1-.5-.5V1A.5.5,0,0,1,1,.5Z"
        />
      </g>
      <text className="d" transform="translate(21 61.24)">
        <tspan x={0} y={0}>
          {"Source"}
        </tspan>
      </text>

      <text className="f" transform="translate(342 61.24)">
        <tspan x={0} y={0}>
          {"Supply"}
        </tspan>
      </text>

      <text className="e" transform="translate(668 61.24)">
        <tspan x={0} y={0}>
          {"Product"}
        </tspan>
      </text>


      <line className="g" x2={70} transform="translate(25.5 82.5)" />
      <line className="h" x2={70} transform="translate(342.5 82.5)" />
      <line className="i" x2={70} transform="translate(668.5 82.5)" />

      <a href="/etender">
      <text className="j" transform="translate(21 206.105)">
        <tspan x={0} y={0}>
          {"Supplier Tender"}
        </tspan>
      </text>
      <image
        width={117}
        height={50}
        transform="translate(21 125)"
        // xlinkHref="/images/MegaMenu/ComponentTMP_0-image2.png"
        xlinkHref="/images/MegaMenu/Component eRFX Logo@2x.png"
      />
      </a>

      <a href="/reverse-auction-eauction">
      <text className="j" transform="translate(21 346.709)">
        <tspan x={0} y={0}>
          {"Supplier Negotiation"}
        </tspan>
      </text>
      <image
        width={119}
        height={50}
        transform="translate(21 262)"
        // xlinkHref="/images/MegaMenu/ComponentTMP_0-image3.png"
        xlinkHref="/images/MegaMenu/eAuc Logo@2x.png"
      />
      </a>

      <a href="/sourcing-management">
      <text className="j" transform="translate(21 486.709)">
        <tspan x={0} y={0}>
          {/* {"Strategic Sourcing Management"} */}
          {"Strategic Sourcing"}
        </tspan>
      </text>
      <image
        width={119}
        height={50}
        transform="translate(21 399)"
        xlinkHref="/images/MegaMenu/SSM Menu.png"
      />
      </a>

      <a href="/supplier-information-management">
      <text className="k" transform="translate(342 206.01)">
        <tspan x={0} y={0}>
          {"Supplier Information"}
        </tspan>
      </text>
      <image
        width={97}
        height={50}
        transform="translate(344 125)"
        // xlinkHref="/images/MegaMenu/ComponentTMP_0-image4.png"
        xlinkHref="/images/MegaMenu/SIM Logo@2x.png"
      />
      </a>
      <a href="/contract-management">
      <text className="k" transform="translate(342 346.56)">
        <tspan x={0} y={0}>
          {"Contract Management"}
        </tspan>
      </text>
      <image
        width={108}
        height={50}
        transform="translate(344 266)"
        // xlinkHref="/images/MegaMenu/ComponentTMP_0-image5.png"
        xlinkHref="/images/MegaMenu/SCM Logo@2x.png"
      />
      </a>
      <a href="/supplier-performance-management">
      <text className="k" transform="translate(342 486)">
        <tspan x={0} y={0}>
          {"Supplier Performance"}
        </tspan>
      </text>
      <image
        width={106}
        height={50}
        transform="translate(342 407)"
        // xlinkHref="/images/MegaMenu/ComponentTMP_0-image6.png"
        xlinkHref="/images/MegaMenu/SPM Logo@2x.png"
      />
      </a>
      <a href="/product-information-management">
      <text className="l" transform="translate(668 205.561)">
        <tspan x={0} y={0}>
          {"Product Information"}
        </tspan>
      </text>
      <image
        width={98}
        height={50}
        transform="translate(669 124)"
        // xlinkHref="/images/MegaMenu/ComponentTMP_0-image.png"
        xlinkHref="/images/MegaMenu/PIM Logo@2x.png"
      />
      </a>
      <text className="m" transform="translate(996 60)">
        <tspan x={0} y={0}>
          {"Food Industry"}
        </tspan>
      </text>
      <a href="/food-industry-software">
      <text className="n" transform="translate(1003 206)">
        <tspan x={0} y={0}>
          {"Software for Food Makers"}
        </tspan>
      </text>
      <image
        width={51}
        height={56}
        transform="translate(992 127)"
        xlinkHref="/images/MegaMenu/ComponentTMP_0-image7.png"
      />
      </a>
      <line className="o" x2={70} transform="translate(1002.5 82.5)" />
      <line className="p" y2={225} transform="translate(962.5 16.5)" />
      <text className="q" transform="translate(756 464)">
        <tspan x={27.232} y={23}>
          {"ARCUS is cloud based Software as a Service"}
        </tspan>
      </text>
      <a href="/supplier-management-platform">
      <image
        width={241}
        height={80}
        transform="translate(1056 350)"
        xlinkHref="/images/MegaMenu/ComponentTMP_0-image8.png"
      />
      </a>
    </g>
  </svg>
);

export default MegaMenuPngComponent;
