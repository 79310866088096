import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import ModalVideo from 'react-modal-video'
import CtaStripe from './CtaStripe'
import BlogListing from './BlogListing'
import client from './Contentful'
import ClsProductCards from './ProductCards'
import CaseStudyTeaser from './CaseStudyTeaser'
import PDF from '../Pdf/ARCUS Supplier Performance Management Factsheet.pdf'
import DocumentMeta from 'react-document-meta'
import FaqLists from './FaqLists'
import CarouselHeader from './CarouselHeader'
import MiniCard from './MiniBlogCard'
import Loader from './Loader'

const SupplierPerformance = () => {
  const [relatedBlogs, setRelatedBlogs] = useState([])
  const [extraBlogs,setExtraBlogs] = useState([])
  const [isOpen, setOpen] = useState(false)
  const [caseStudy, setCaseStudy] = useState([])
  const [extraCaseStudy, setExtraCaseStudy] =  useState([])
  const location = useLocation()

  const faqMappingUrl = location.pathname;
    const faqListQuery = gql`
    query FaqLists ($url: String!){
      addOnFaqListCollection(where:{addOnFaqListSlug: $url}){
        items{
          faqList{
            title
            listCollection{
              items{
                question
                answerText
              }
            }
          }
        }
      }
    }
    `


    const blogpostCardMiniQuery = gql`
    query BlogpostCardMini {
      blogPostCollection(where:{blogPostProduct_contains_all:"SPM" ,SEOIndex:true} order:[publishDate_DESC]){
        total
        items{
          title
          slug

        }
      }

    }
    `

    const pageBuildQuery = gql`
query PageBuildQuery{
pageBuilderCollection(where: {SEOIndex:true, pageBuilderProduct_contains_all:"SPM"} ){
 total
 items{
   title
   slug
   __typename
   pageBanner {
     bannerText
   }
 }
}
}`






    const { data: faqDataMain } = useQuery(faqListQuery, {
      skip: !location || !location.pathname,
      variables: {
        url:
          location && location.pathname
            ? faqMappingUrl.slice(1)
            : null,
      },
    });



  const { data:blogGraphData } = useQuery(blogpostCardMiniQuery)

  const {data: pagebuilderData} = useQuery(pageBuildQuery)




    const faqData = faqDataMain?.addOnFaqListCollection?.items[0]?.faqList

    const seoData = faqDataMain?.addOnFaqListCollection?.items[0]?.faqList?.listCollection?.items


    let mainEntity = []
     seoData&&
      seoData.map((res,id)=> {
      return mainEntity[id] = {
          "@type": "Question",
          "name": res.question,
          "acceptedAnswer": {
            "@type": "Answer",
            "text": res.answerText,
          }
        }
      })

let schema = mainEntity.length === 0 ?  null : {
     "@context": "https://schema.org",
     "@type": "FAQPage",
     "mainEntity": mainEntity
   }

  const path = location.pathname
  const url = path.substr(path.lastIndexOf('/') + 1)
  localStorage.setItem('SupplierPerformance',`/${url}`)

  useEffect(() => {

    client.getEntries({
      content_type: 'blogPost',
      order:'-fields.publishDate',
      'fields.blogPostProduct[nin]': 'SPM',
      limit: 3
    })
    .then((response)=>{
     const latestBlogs = response.items.map(blg => {
        return {
          title: blg.fields.title,
          date: blg.fields.publishDate,
          shortDesc: blg.fields.description,
          bloglink: blg.fields.slug,
          imageSrc: blg.fields.heroImage.fields.file.url,
          altDesc: blg.fields.heroImage.fields.description,
          estimatedreadtime: blg.fields.estimatedreadtime
        }
      })
      setExtraBlogs(latestBlogs)
    }).catch(console.error)

    client.getEntries({
      content_type: 'blogPost',
      order:'-fields.publishDate',
      'fields.blogPostProduct': 'SPM',
      limit: 3
    })
    .then((response)=>{
     const initialBlogs = response.items.map(blg => {
        return {
          title: blg.fields.title,
          date: blg.fields.publishDate,
          shortDesc: blg.fields.description,
          bloglink: blg.fields.slug,
          imageSrc: blg.fields.heroImage.fields.file.url,
          altDesc: blg.fields.heroImage.fields.description,
          estimatedreadtime: blg.fields.estimatedreadtime
        }
      })
      setRelatedBlogs(initialBlogs)
    }).catch(console.error)

    client.getEntries({
      content_type: 'casestudy',
      order: 'fields.casestudyorderpriority',
      'fields.casestudyproduct': 'SPM',
      limit: 1
    })
    .then((response) => {
      const caseStudyData = response.items.map(csItem => {
        return {
          slug: csItem.fields.slug,
          nameofquotedperson:csItem.fields.nameofquotedperson,
          quotedpersonjobtitle:csItem.fields.quotedpersonjobtitle,
          organisation:csItem.fields.organisation,
          calloutquote:csItem.fields.calloutquote,
          extractforteaser:csItem.fields.extractforteaser
        }
      })
      setCaseStudy(caseStudyData[0])
    } )
    .catch(console.error)

    client.getEntries({
      content_type: 'casestudy',
      order: 'fields.casestudyorderpriority',
      limit: 1
    })
    .then((response) => {
      const extraCaseStudyData = response.items.map(csItem => {
        return {
          slug: csItem.fields.slug,
          nameofquotedperson:csItem.fields.nameofquotedperson,
          quotedpersonjobtitle:csItem.fields.quotedpersonjobtitle,
          organisation:csItem.fields.organisation,
          calloutquote:csItem.fields.calloutquote,
          extractforteaser:csItem.fields.extractforteaser
        }
      })
      setExtraCaseStudy(extraCaseStudyData[0])
    } )
    .catch(console.error)
  },[])

const bloglist = (relatedBlogs.length < 3) ? [...relatedBlogs,...extraBlogs].slice(0, 3) : relatedBlogs
const finalCaseStudyTeaser = (typeof caseStudy !== 'undefined' && caseStudy.length > 0) ? caseStudy : extraCaseStudy



if (!faqDataMain || !blogGraphData || !pagebuilderData) {
  return <Loader />;
}


const blogBoxData = blogGraphData?.blogPostCollection?.items

const pageBuildData = pagebuilderData?.pageBuilderCollection?.items

const blogCardData = [...blogBoxData, ...pageBuildData]




    const downloadPDF = () => {
      fetch(PDF)
        .then(response => {
          response.blob().then(blob => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            var sub = PDF.substr(14);
            a.download =sub;
            a.click();
          });
      });
    }

    const meta = {
      title: 'Rasp: Automate Supplier Performance Management With ARCUS',
      description: 'Automated supplier performance management, tracking and gap analysis with ARCUS SPM. Our module engages suppliers and stakeholders across your organisation.',
      meta: {
        charset: 'utf-8',
        name: {
          keywords: 'Supplier Performance Management'
        }
      }
    }

    return(
      <DocumentMeta {...meta}>
           {schema && (
              <div
              dangerouslySetInnerHTML={{
                __html: `
                  <script type="application/ld+json">${JSON.stringify(
                    schema
                  )}</script>
                  `
                }}
              />
           )}
      <div>
      {/* <!====================================text start================================>  */}

      <div className="bgshadow3">{/*-shadow start here-*/}
        <div className="container mt-5 text-center">
          <div className="row">
            <div className="col-lg-6 m-auto"><img src="images/Product Logos/SPM Logo.svg" className="img-fluid mt-2" alt="ARCUS Supplier Performance Management Logo" /></div>
            <div className="col-lg-6 mt-2 mt-sm-4 text-left center">
              <h2 className="font-weight-bold"><hr id="arcus" className="m-lg-0 mt-n5 pb-2" />ARCUS SPM</h2>
              <h3>Supplier Performance Management</h3>
            </div>
          </div>

      {/*-----------horizontal divider-------------------------------*/}

      <div className="container"><hr /></div>
        <div className="row">
          <div className="col-lg-4 ">
            <p className="font-weight-normal text-left px-lg-5 text6">Automated Vendor and Contractor Performance Tracking and Gap Analysis</p>
          </div>
          <div className="col-lg-8">
            <p className="font-weight-lighter text-left font-italic clr  px-lg-5 text6">"ARCUS® SPM gives us a complete view of our supply base, and we are able to easily evaluate the performance of our suppliers against the KPIs that matter the most to us. Our scorecards were configured quickly and are so simple to use, and we now feel that we can make more informed purchasing decisions thanks to the software." <br /><br />
              <span className="small text3">Harvey Marston, Head of Procurement, Four Seasons Health Care</span></p>
          </div>
        </div>
        <div className="text-center mt-5">
                    <h3 className="font-weight-bold "><hr id="arcus" />What is it?</h3>
                    </div>
                    <div className="text-left center mt-5 col-lg-12">
                      <p className="clr">ARCUS Supplier Performance Management is a powerful supplier performance management tool that allows businesses to proactively monitor and analyse the performance of existing suppliers. Using a collaborative and 360° approach, the ARCUS Supplier Performance Management module engages suppliers and stakeholders across the organisation to complete questionnaires and surveys relating to supplier performance. This scoring and comparable data gives improved visibility of the performance of each supplier, helping to benchmark supplier performance, implement best practice performance strategies, improve supplier performance and make more informed sourcing decisions.</p>
                    </div>

                   </div>


    {/*---------------------------------sim dashboard start-----------------------------------*/}
    <div className="container-fluid p-0">
        <div className="mt-5">
          <div className="row reverse">
            <div className="col-lg-6 px-5 mt-lg-5">
              <div className="row">
                <div className="col-lg-2 hide-at" />
                <div className="col-lg-10">
                <h3 className="font-weight-bold "><hr id="arcus-left" />How does it work?</h3>
                  <p className="ml-xs-3 clr mt-lg-5">Based on a client's current or planned supplier performance process(es) we actively map and build a Scorecard template questionnaire. Each supplier is assigned an individual scorecard and suppliers and stakeholders from across the organisation are invited to rate supplier performance against pre-determined criteria. Using the information gathered companies are able to run side-by-side comparison reports, highlighting differences in scoring between the business and suppliers. Supplier Performance Management enforces a streamlined approach to collating supplier information providing a central storage of consistent and comparable data, early identification of supplier issues and more detailed and accurate sourcing decisions.</p>
                </div></div></div>
            <div className="col-lg-6 text-center p-0"><img src="images/SPM Dashboard Graphic.png" className="img-fluid" alt="Supplier Performance Management charts" />
            <ModalVideo channel='youtube' isOpen={isOpen} videoId="yYKavHCUc04" onClose={() => setOpen(false)} />
            <button className="btn btn-primary my-5 spacing Rbtnmargin video-btn" onClick={()=> setOpen(true)}>VIEW DEMO</button>
            </div>
          </div>
        </div>
      </div>

    {/*---------------------------------sim dashboard end-----------------------------------*/}

    {/*-----------------------------sim questionnaire start-----------------------------*/}

    <div className="container-fluid">
        <div className="row reverse">
          <div className="col-lg-6 text-center p-0">
            <img src="images/SPM Questionnaire Graphic.png" className="img-fluid mt-5" alt="Supplier Performance Management questionnaire" />
            <button type="button" onClick={downloadPDF} className="btn btn-primary my-5 Lbtnmargin">DOWNLOAD FACTSHEET</button>
          </div>
          <div className="col-lg-6 mt-3 px-3 m-auto">
            <div className="mx-4 mt-sm-4 text-left center">
              <h2 className="font-weight-bold">Benefits of ARCUS SPM</h2>
            </div>
            <div className="mt-4"><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">A more proactive approach to monitoring and tracking supplier performance.</p></div>
            <div className="mt-4"><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">A streamlined, consistant and transparent process.</p></div>
            <div className="mt-4"><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">Genuine visibility of supplier performance and capabilities.</p></div>
            <div className="mt-4"><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">Early identification of supplier issues.</p></div>
            <div className="mt-4"><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">Single source of supplier information to be shared across the organisation.</p></div>
            <div className="mt-4"><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">Score suppliers at automatically scheduled intervals or by project.</p></div>
            <div className="mt-4"><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">Enable 360 degree feedback and gap analysis with supplier self assessment.</p></div>
          </div>
        </div>
      </div>
      {/*-shadow end here-*/}
  </div>

  {/* <!-------------------------------sim questionnaire end--------------------------------->



     <!====================================text end================================>  */}
      {/* ==========================faqlist start============================ */}

          {faqData && (<div className="col-lg-6 m-auto py-3">
            <FaqLists {...faqData}/>
          </div>)
          }

      {/* ==========================faqlist end============================ */}

<div className="container bgshadow">
 {/* <!==============================Product Cards start============================>  */}

      <ClsProductCards></ClsProductCards></div><br/><br/>

     {/* <!==============================Product Cards End============================>  */}

     {/* <!==============================case study with city start==============================>  */}


     {finalCaseStudyTeaser && (
        <CaseStudyTeaser {...finalCaseStudyTeaser} />
     )}

     {/* <!==============================case study with city end==============================>  */}

     <div className="container text-center pt-5"><h3 className="font-weight-bold "><hr id="arcus" />Read Our Latest Insights</h3>
        <p className="clr">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed <br /> do eiusmod tempor incididunt ut labore et dolore</p>
      </div>

     {/* <!==============================blog Cards start==============================>   */}

     <BlogListing bloglist={bloglist} />

     {/* <!==============================blog Cards end==============================>   */}


     <CarouselHeader/>

       <div className="container mt-4">
         <div className="container col-lg-12">
           {
             blogCardData&& <MiniCard data={blogCardData} />
           }

         </div>
         </div>


      <CtaStripe />
     </div>
     </DocumentMeta>
  )
}
export default SupplierPerformance;
