import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import ModalVideo from 'react-modal-video'
import CtaStripe from './CtaStripe'
import BlogListing from './BlogListing'
import BlogHeader from './BlogHeader'
import client from './Contentful'
import ClsProductCards from './ProductCards';
import CaseStudyTeaser from './CaseStudyTeaser'
import PDF from '../Pdf/ARCUS eNegotiation and eRFx Functionality.pdf'
import DocumentMeta from 'react-document-meta'
import FaqLists from './FaqLists'

import CarouselHeader from './CarouselHeader'
import MiniCard from './MiniBlogCard'
import Loader from './Loader'


const ReverseAuction = () => {
  const [relatedBlogs, setRelatedBlogs] = useState([])
  const [extraBlogs,setExtraBlogs] = useState([])
  const [caseStudy, setCaseStudy] =  useState([])
  const [isOpen, setOpen] = useState(false)
  const location = useLocation()

   const faqMappingUrl = location.pathname;
    const faqListQuery = gql`
    query FaqLists ($url: String!){
      addOnFaqListCollection(where:{addOnFaqListSlug: $url}){
        items{
          faqList{
            title
            listCollection{
              items{
                question
                answerText
              }
            }
          }
        }
      }
    }
    `


  const blogpostCardMiniQuery = gql`
  query BlogpostCardMini {
    blogPostCollection(where:{blogPostProduct_contains_all:"eAuction" ,SEOIndex:true} order:[publishDate_DESC]){
      total
      items{
        title
        slug

      }
    }

  }
  `

  const pageBuildQuery = gql`
query PageBuildQuery{
pageBuilderCollection(where: {SEOIndex:true, pageBuilderProduct_contains_all:"eAuction"} ){
 total
 items{
   title
   slug
   __typename
   pageBanner {
     bannerText
   }
 }
}
}`







    const { data: faqDataMain } = useQuery(faqListQuery, {
      skip: !location || !location.pathname,
      variables: {
        url:
          location && location.pathname
            ? faqMappingUrl.slice(1)
            : null,
      },
    });



  const { data:blogGraphData } = useQuery(blogpostCardMiniQuery)

  const {data: pagebuilderData} = useQuery(pageBuildQuery)




    const faqData = faqDataMain?.addOnFaqListCollection?.items[0]?.faqList

    const seoData = faqDataMain?.addOnFaqListCollection?.items[0]?.faqList?.listCollection?.items


    let mainEntity = []
     seoData&&
      seoData.map((res,id)=> {
      return mainEntity[id] = {
          "@type": "Question",
          "name": res.question,
          "acceptedAnswer": {
            "@type": "Answer",
            "text": res.answerText,
          }
        }
      })

let schema = mainEntity.length === 0 ?  null : {
     "@context": "https://schema.org",
     "@type": "FAQPage",
     "mainEntity": mainEntity
   }

  const path = location.pathname
  const url = path.substr(path.lastIndexOf('/') + 1)
  localStorage.setItem('reverseAuction',`/${url}`)

  useEffect(() => {

    client.getEntries({
      content_type: 'blogPost',
      order:'-fields.publishDate',
      'fields.blogPostProduct[nin]': 'eAuction',
      limit: 3
    })
    .then((response)=>{
     const latestBlogs = response.items.map(blg => {
        return {
          title: blg.fields.title,
          date: blg.fields.publishDate,
          shortDesc: blg.fields.description,
          bloglink: blg.fields.slug,
          imageSrc: blg.fields.heroImage.fields.file.url,
          altDesc: blg.fields.heroImage.fields.description,
          estimatedreadtime: blg.fields.estimatedreadtime
        }
      })
      setExtraBlogs(latestBlogs)
    }).catch(console.error)

    client.getEntries({
      content_type: 'blogPost',
      order:'-fields.publishDate',
      'fields.blogPostProduct': 'eAuction',
      limit: 3
    })
    .then((response)=>{
     const initialBlogs = response.items.map(blg => {
        return {
          title: blg.fields.title,
          date: blg.fields.publishDate,
          shortDesc: blg.fields.description,
          bloglink: blg.fields.slug,
          imageSrc: blg.fields.heroImage.fields.file.url,
          altDesc:blg.fields.heroImage.fields.description,
          estimatedreadtime: blg.fields.estimatedreadtime
        }
      })
      setRelatedBlogs(initialBlogs)
    }).catch(console.error)

    client.getEntries({
      content_type: 'casestudy',
      order: 'fields.casestudyorderpriority',
      'fields.casestudyproduct': 'eAuction',
      limit: 1
    })
    .then((response) => {
      const caseStudyData = response.items.map(csItem => {
        return {
          slug: csItem.fields.slug,
          nameofquotedperson:csItem.fields.nameofquotedperson,
          quotedpersonjobtitle:csItem.fields.quotedpersonjobtitle,
          organisation:csItem.fields.organisation,
          calloutquote:csItem.fields.calloutquote,
          extractforteaser:csItem.fields.extractforteaser
        }
      })
      setCaseStudy(caseStudyData[0])
    } )
    .catch(console.error)
  },[])



  if (!faqDataMain || !blogGraphData || !pagebuilderData) {
    return <Loader />;
  }


  const blogBoxData = blogGraphData?.blogPostCollection?.items

  const pageBuildData = pagebuilderData?.pageBuilderCollection?.items

  const blogCardData = [...blogBoxData, ...pageBuildData]



  const bloglist = (relatedBlogs.length < 3) ? [...relatedBlogs,...extraBlogs].slice(0, 3) : relatedBlogs
  const downloadPDF = () => {

    fetch(PDF)
      .then(response => {
        response.blob().then(blob => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          var sub = PDF.substr(14);
          a.download =sub;
          a.click();
        });
    });
  }

  const meta = {
    title: 'Rasp: Drive Down Costs With Our Reverse Auction Software',
    description: 'Our reverse auction software, ARCUS eAUC, helps you enjoy an average of 14% cost savings against buyer expectations. We help you improve procurement efficiency.',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'Reverse Auction software'
      }
    }
  }

        return(
      <DocumentMeta {...meta}>

        {schema && (
          <div
          dangerouslySetInnerHTML={{
            __html: `
              <script type="application/ld+json">${JSON.stringify(
                schema
              )}</script>
              `
            }}
          />
        )}

      <div>

      {/* <!====================================text start================================>  */}

      <div className="bgshadow3 pt-5">{/*-shadow start here-*/}
      <div className="container mt-5 text-center">
        <div className="row">
            <div className="col-lg-6 m-auto"><img src="images/Product Logos/eAuction Logo.svg" className="img-fluid mt-2" alt="ARCUS Reverse Auction logo" /></div>
            <div className="col-lg-6 mt-2 mt-sm-4 text-left center">
            <h2 className="font-weight-bold"><hr id="arcus" className="m-lg-0 mt-n5 pb-2 text-left" />ARCUS e-Auctions </h2>
            <h3>Live Price Negotiations</h3>
            </div>
        </div>

        {/* <!-------------horizontal divider---------------------------------> */}
        <div className="container"><hr /></div>

        <div className="mt-5">
        <div className="row">
          <div className="col-lg-1" />
          <div className="col-lg-4 mt-3">
            <button className="btn btn-warning font-weight-lighter btnedit py-lg-5 p-md-4 p-4 text-white"><span className="eAuctxt mt-3">14%</span> <br /><span className="eAuctxt2 mt-1 mb-3">Average Cost <br /> Savings*</span></button>
          </div>
          <div className="col-lg-1" />
          <div className="col-lg-5 mt-2">
            <div className="m-auto d-block d-lg-none"><p className="small text-left clr pl-lg-4 mt-4">*Average savings achieved against buyers expectations over more than 1,000 ARCUS e-Auctions (UK, Aus, NZ).</p></div>
            <p className="clr text-left font-italic fs pt-2 pt-lg-0 pl-lg-5">"It absolutely exceeded our expectations and was unbelievably successful – the IT department were thrilled" <br /><br /> "Rasp made it all so easy and went absolutely above and beyond. I just had to provide documentation. It was easy and hands off."</p>
          </div>
          <div className="col-lg-1" />
        </div>
        <div className="row">
          <div className="col-lg-1" />
          <div className="col-lg-4 m-auto d-none d-lg-block"><p className="small text-left clr px-lg-5">*Average savings achieved against buyers expectations over more than 1,000 ARCUS e-Auctions (UK, Aus, NZ).</p></div>
          <div className="col-lg-1" />
          <div className="col-lg-5"><p className="small text-left clr pl-lg-5">Alison De La Garde, Regional Category Manager, ANZ Bank</p></div>
          <div className="col-lg-1" />
        </div>
      </div>

        <div className="text-center mt-5">
          <h3 className="font-weight-bold "><hr id="arcus" />What is it?</h3>
        </div>
        <div className="text-left center mt-5">
          <p className="clr">The ARCUS price negotiation service (variously known as <a href="/insights/my-first-reverse-e-auction" target="_blank" >e-Auction, Reverse Auction </a> or e-Negotiation) is used by procurement teams to make significant savings in the purchase of a wide variety of goods and services. The key ingredients for a successful reverse auction are clearly defined requirements and a competitive market.</p>
        </div>

      </div>

      {/* <!-----------------------------------sim dashboard start-------------------------------------> */}

       <div className="container-fluid p-0">
        <div className="mt-5">
          <div className="row reverse">
            <div className="col-lg-6 m-auto">
              <div className="row">
                <div className="col-lg-2 hide-at" />
                <div className="col-lg-10">
                  <p className="ml-3 clr">Simplistically, the question '...and your best price is?' represents the primary job of e-Negotiations or reverse auctions. It is a price negotiation tool - with benefits.
                  </p>
                  <p className="ml-3 clr">Rasp are experts in the field and provide a fully managed reverse auction service to maximise your savings and minimise all risks. We'll let you know up front whether your category will work or not. We have delivered hundreds of very successful e-Auctions for our clients including some of Australia and New Zealand's largest banks, corporate and public sector organisations.</p>
                  <p className="ml-3 clr">If there is good competition between suppliers, any well specified product or service can be auctioned. This includes goods for resale, services, consultants, contractor rates, recruitment agency margins, raw materials, hire and maintenance contracts and secondary materials such as packaging, or indirect company supplies.</p>
                  <p className="ml-3 font-italic">“In terms of savings and benefits, it made good business sense”</p>
                  <p className="ml-3 clr font-italic">Katie Kilpatrick, Contracts Office, Qld Health commenting on their $1.8M savings achieved through reverse auction.</p>
                </div></div>
            </div>
            <div className="col-lg-6 text-center p-0"><img src="images/Reverse Auction Chart on screen@2x.png" className="img-fluid" alt="Reverse auction bid chart" />
            <ModalVideo channel='vimeo' autoplay isOpen={isOpen} videoId="570818809" onClose={() => setOpen(false)} />
            <button className="btn btn-primary my-5 spacing Rbtnmargin video-btn" onClick={()=> setOpen(true)}>VIEW DEMO</button>
            </div>
          </div>
        </div>
      </div>

       {/* <!-----------------------------------sim dashboard end------------------------------------->

      <!-------------------------------sim questionnaire start------------------------------->   */}

<div className="container-fluid">
        <div className="row reverse">
          <div className="col-lg-6 text-center p-0">
            <img src="images/e-Auction Screen 2@2x.png" className="img-fluid mt-5" alt="Reverse auction setup screen" />
            <button type="button"  onClick={downloadPDF}  className="btn btn-primary my-5 Lbtnmargin">DOWNLOAD FACTSHEET</button>
          </div>
          <div className="col-lg-6 mt-3 px-5 m-auto">
            <div className="mx-4 mt-sm-4 text-left center">
              <h2 className="font-weight-bold"><hr id="arcus" className="m-lg-0 mt-n5 pb-2 text-left" />Benefits of e-Auctions</h2>
            </div>
            <div className="mt-4"><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2 " alt="" /><p className="clr ml-5">Lower Purchase Costs by 14% on average - Live head-to-head bidding intensifies competition between suppliers</p></div>
            <div className="mt-4"><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">
                Beat Target Savings - Traditional negotiations usually conclude once target savings are reached. e-Auctions usually conclude only when each supplier reaches their bottom line.
              </p></div>
            <div className="mt-4"><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">
                Time Savings - reduces negotiation period from days or weeks down to minutes or hours.
              </p></div>
            <div className="mt-4"><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">
                Strong Probity Characteristics - The buyer is simply an observer during the negotiation.
              </p></div>
            <div className="mt-4"><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">
                Transparency - Increases transparency for suppliers as they all have exactly the same information at the same time.
              </p></div>
            <div className="mt-4"><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">
                Measurability - Tangible and measurable results for the buyer to demonstrate savings.
              </p></div>
            <div className="mt-4"><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">
                Highly secure and public sector probity compliant.
              </p></div>
          </div>
        </div>
      </div>

      {/*-shadow end here-*/}
   </div>

  {/* <!-------------------------------sim questionnaire end--------------------------------->



     <!====================================text end================================>  */}

  {/* ==========================faqlist start============================ */}

          {faqData && (<div className="col-lg-6 m-auto py-3">
            <FaqLists {...faqData}/>
          </div>)
          }

  {/* ==========================faqlist end============================ */}

<div className="container bgshadow">
    {/* <!==============================Product Cards start============================>  */}

      <ClsProductCards></ClsProductCards></div><br/><br/>

     {/* <!==============================Product Cards End============================>  */}

     {/* <!==============================case study with city start==============================>  */}

     {caseStudy && (
       <CaseStudyTeaser {...caseStudy} />
      )}

     {/* <!==============================case study with city end==============================>  */}

    <BlogHeader />

     {/* <!==============================blog Cards start==============================>   */}

     <BlogListing bloglist={bloglist} />

     {/* <!==============================blog Cards end==============================>   */}



     <CarouselHeader/>

       <div className="container mt-4">
         <div className="container col-lg-12">
           {
             blogCardData&& <MiniCard data={blogCardData} />
           }

         </div>
         </div>




       <CtaStripe />
     </div>
  </DocumentMeta>
        )
}
export default ReverseAuction;
