import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import ModalVideo from 'react-modal-video'
import CtaStripe from './CtaStripe'
import BlogListing from './BlogListing'
import BlogHeader from './BlogHeader'
import client from './Contentful'
import ClsProductCards from './ProductCards';
import CaseStudyTeaser from './CaseStudyTeaser'
import PDF from '../Pdf/ARCUS Supplier Contract Management Fact Sheet.pdf'
import DocumentMeta from 'react-document-meta'
import FaqLists from './FaqLists'

import CarouselHeader from './CarouselHeader'
import MiniCard from './MiniBlogCard'
import Loader from './Loader'

const ContactManagement = () => {

    const [relatedBlogs, setRelatedBlogs] = useState([])
    const [extraBlogs,setExtraBlogs] = useState([])
    const [caseStudy, setCaseStudy] =  useState([])
    const [extraCaseStudy, setExtraCaseStudy] =  useState([])
    const [isOpen, setOpen] = useState(false)
    const location = useLocation()

    const faqMappingUrl = location.pathname;
    const faqListQuery = gql`
    query FaqLists ($url: String!){
      addOnFaqListCollection(where:{addOnFaqListSlug: $url}){
        items{
          faqList{
            title
            listCollection{
              items{
                question
                answerText
              }
            }
          }
        }
      }
    }
    `


  const blogpostCardMiniQuery = gql`
  query BlogpostCardMini {
    blogPostCollection(where:{blogPostProduct_contains_all:"SCM" ,SEOIndex:true} order:[publishDate_DESC]){
      total
      items{
        title
        slug

      }
    }

  }
  `


  const PageBuildQuery = gql`
  query pageBuildQuery{
 pageBuilderCollection(where: {SEOIndex:true, pageBuilderProduct_contains_all:"SCM"} ){
   total
   items{
     title
     slug
     __typename
     pageBanner {
       bannerText
     }
   }
}
}`



    const { data:faqDataMain } = useQuery(faqListQuery, {
      skip: !location || !location.pathname,
      variables: {
        url:
          location && location.pathname
            ? faqMappingUrl.slice(1)
            : null,
      },
    });



  const { data:blogGraphData } = useQuery(blogpostCardMiniQuery)

  const {data: pagebuilderData} = useQuery(PageBuildQuery)


    const faqData = faqDataMain?.addOnFaqListCollection?.items[0]?.faqList

    const seoData = faqDataMain?.addOnFaqListCollection?.items[0]?.faqList?.listCollection?.items


    let mainEntity = []
     seoData&&
      seoData.map((res,id)=> {
      return mainEntity[id] = {
          "@type": "Question",
          "name": res.question,
          "acceptedAnswer": {
            "@type": "Answer",
            "text": res.answerText,
          }
        }
      })

let schema = mainEntity.length === 0 ?  null : {
     "@context": "https://schema.org",
     "@type": "FAQPage",
     "mainEntity": mainEntity
   }

    const path = location.pathname
    const url = path.substr(path.lastIndexOf('/') + 1)
    localStorage.setItem('ContractManagement',`/${url}`)

    useEffect(() => {

      client.getEntries({
        content_type: 'blogPost',
        order:'-fields.publishDate',
        'fields.blogPostProduct[nin]': 'SCM',
        limit: 3
      })
      .then((response)=>{
       const latestBlogs = response.items.map(blg => {
          return {
            title: blg.fields.title,
            date: blg.fields.publishDate,
            shortDesc: blg.fields.description,
            bloglink: blg.fields.slug,
            imageSrc: blg.fields.heroImage.fields.file.url,
            altDesc:blg.fields.heroImage.fields.description,
            estimatedreadtime: blg.fields.estimatedreadtime
          }
        })
        setExtraBlogs(latestBlogs)
      }).catch(console.error)

      client.getEntries({
        content_type: 'blogPost',
        order:'-fields.publishDate',
        'fields.blogPostProduct': 'SCM',
        limit: 3
      })
      .then((response)=>{
       const initialBlogs = response.items.map(blg => {
          return {
            title: blg.fields.title,
            date: blg.fields.publishDate,
            shortDesc: blg.fields.description,
            bloglink: blg.fields.slug,
            imageSrc: blg.fields.heroImage.fields.file.url,
            altDesc:blg.fields.heroImage.fields.description,
            estimatedreadtime: blg.fields.estimatedreadtime
          }
        })
        setRelatedBlogs(initialBlogs)
      }).catch(console.error)

      client.getEntries({
        content_type: 'casestudy',
        order: 'fields.casestudyorderpriority',
        'fields.casestudyproduct': 'SCM',
        limit: 1
      })
      .then((response) => {
        const caseStudyData = response.items.map(csItem => {
          return {
            slug: csItem.fields.slug,
            nameofquotedperson:csItem.fields.nameofquotedperson,
            quotedpersonjobtitle:csItem.fields.quotedpersonjobtitle,
            organisation:csItem.fields.organisation,
            calloutquote:csItem.fields.calloutquote,
            extractforteaser:csItem.fields.extractforteaser
          }
        })
        setCaseStudy(caseStudyData[0])
      } )
      .catch(console.error)

      client.getEntries({
        content_type: 'casestudy',
        order: 'fields.casestudyorderpriority',
        limit: 1
      })
      .then((response) => {
        const extraCaseStudyData = response.items.map(csItem => {
          return {
            slug: csItem.fields.slug,
            nameofquotedperson:csItem.fields.nameofquotedperson,
            quotedpersonjobtitle:csItem.fields.quotedpersonjobtitle,
            organisation:csItem.fields.organisation,
            calloutquote:csItem.fields.calloutquote,
            extractforteaser:csItem.fields.extractforteaser
          }
        })
        setExtraCaseStudy(extraCaseStudyData[0])
      } )
      .catch(console.error)
    },[])


    if (!faqDataMain || !blogGraphData || !pagebuilderData) {
      return <Loader />;
    }


    const blogBoxData = blogGraphData?.blogPostCollection?.items

    const pageBuildData = pagebuilderData?.pageBuilderCollection?.items

    const blogCardData = [...blogBoxData, ...pageBuildData]


    const bloglist = (relatedBlogs.length < 3) ? [...relatedBlogs,...extraBlogs].slice(0, 3) : relatedBlogs
    const finalCaseStudyTeaser = (typeof caseStudy !== 'undefined' && caseStudy.length > 0) ? caseStudy : extraCaseStudy

   const downloadPDF = () => {

        fetch(PDF)
          .then(response => {
            response.blob().then(blob => {
              let url = window.URL.createObjectURL(blob);
              let a = document.createElement('a');
              a.href = url;
              var sub = PDF.substr(14);
              a.download =sub;
              a.click();
            });
        });
      }

      const meta = {
        title: 'Rasp: Explore our Contract lifecycle management system',
        description: 'Our contract lifecycle management system automates contract lifecycles, streamlines processes, enforces best practices, and reduces off-contract spending.',
        meta: {
          charset: 'utf-8',
          name: {
            keywords: 'Online contract management'
          }
        }
      }

    return(
      <DocumentMeta {...meta}>
        {schema && (
          <div
          dangerouslySetInnerHTML={{
            __html: `
              <script type="application/ld+json">${JSON.stringify(
                schema
              )}</script>
              `
            }}
          />
        )}
      <div>
          {/* <!====================================text start================================>  */}
          <div className="bgshadow3 pt-5"> {/* <!---shadow start here---> */}
          <div className="container mt-5 text-center">
          <div className="row">
            <div className="col-lg-6 m-auto"><img src="/images/Product Logos/SCM Logo.svg" className="img-fluid mt-2" alt="ARCUS Contract Management software logo" /></div>
            <div className="col-lg-6 mt-2 mt-sm-4 text-left center">
            <h2 className="font-weight-bold"><hr id="arcus" className="m-lg-0 mt-n5 pb-2" />ARCUS SCM</h2>
            <h3>Contract Lifecycle Management</h3>
            </div>
          </div>

          {/* <!-------------horizontal divider---------------------------------> */}

          <div className="container"><hr /></div>

            <div className="row">
                <div className="col-lg-6 ">
                <p className="font-weight-normal text-left px-lg-5 text6">ARCUS Contract Manager automates contract lifecycles, streamlining processes, enforcing best practice and reducing off contract spend.</p>
                </div>
                <div className="col-lg-6">
                <p className="font-weight-lighter text-left font-italic clr  pl-lg-5 text6">"The SCM and SIM modules have made such an impact to our operations in such a short space of time. The software is so easy to use and the workflows with the built-in alerts make it easy to ensure everything is kept up to date."<br /><br />
                    <span className="small text3">Nick Smith, Purchasing Director at The Restaurant Group</span></p>
                </div>
            </div>

            <div className="text-center mt-5">
                <h3 className="font-weight-bold "><hr id="arcus" />What is it?</h3>
            </div>


          </div>

          {/* <!-----------------------------------sim dashboard start-------------------------------------> */}

          <div className="container-fluid p-0">
            <div className="mt-5">
            <div className="row reverse">
                <div className="col-lg-6 m-auto">
                <div className="row">
                    <div className="col-lg-3 hide-at" />
                    <div className="col-lg-9">
                    <p className="clr ml-3 mt-4">  ARCUS Contract Manager is a centralised and collaborative system for managing the entire lifecycle of contracts from creation through to expiry. As well as providing central storage for contractual agreements, it ensures you have full control over the <a href="/insights/contract-lifecycle-management" target="_blank">contract lifecycle</a> with automated alerts against key events, the ability to track contract renewals and the monitoring of approvals. <br /><br /> The Contract Manager Dashboard provides you with instant and live visibility of the status of all of your contracts. All information is available in the cloud and easily accessible to current and future stakeholders.<br /><br /> SCM enables contract managers to proactively manage and track contract lifecycles via automated alerts, emails and reminders, all of which can be monitored from the central dashboard.</p>
                    </div></div></div>
                <div className="col-lg-6 text-center p-0"><img src="/images/SCM Dashboard Graphic@2x.png" className="img-fluid" alt="Contract management dashboard" />
                <ModalVideo channel='vimeo' autoplay isOpen={isOpen} videoId="494007853" onClose={() => setOpen(false)} />
                <button className="btn btn-primary my-5 spacing Rbtnmargin video-btn" onClick={()=> setOpen(true)}>VIEW DEMO</button>
                </div>
            </div>
            </div>
        </div>

        {/* <!-----------------------------------sim dashboard end-------------------------------------> */}

        {/* <!-------------------------------sim questionnaire start-------------------------------> */}

                <div className="container-fluid">
                        <div className="row reverse">
                        <div className="col-lg-6 text-center p-0">
                            <img src="/images/SCM Questionnaire Graphic@2x.png" className="img-fluid mt-5" alt="Contract management questionnaire" />
                            <button type="button" onClick={downloadPDF} className="btn btn-primary my-5 Lbtnmargin">DOWNLOAD FACTSHEET</button>
                        </div>
                        <div className="col-lg-6 mt-3 px-3 m-auto">
                            <div className="mx-4 mt-sm-4 text-left center">
                            <h2 className="font-weight-bold"><hr id="arcus" className="m-lg-0 mt-n5 pb-2" />How does it work?</h2>
                            </div>

                            <div className="mt-4"><img src="/images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">
                                More streamlined, consistent and transparent process.
                            </p></div>
                            <div className="mt-4"><img src="/images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">
                                Genuine visibility of all supplier agreements via the Contract Manager Dashboard.
                            </p></div>
                            <div className="mt-4"><img src="/images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">
                                More proactive approach to monitoring contractual agreements by tracking the status of all contracts in a single dashboard.
                            </p></div>
                            <div className="mt-4"><img src="/images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">
                                Robust review and approval processes.
                            </p></div>
                            <div className="mt-4"><img src="/images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">
                                Programmable alerts to notify of key terms, milestones, tasks, renewal dates and events.
                            </p></div>
                            <div className="mt-4"><img src="/images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">
                                Reminders sent via email when tasks are due.
                            </p></div>
                            <div className="mt-4"><img src="/images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">
                                Track changes to original documentation for audit trail purposes.
                            </p></div>
                            <div className="mt-4"><img src="/images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">
                                Real time access to up to date supplier information.
                            </p></div>
                            <div className="mt-4"><img src="/images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">
                                Single source of supplier information to be shared across the organisation.
                            </p></div>
                            <div className="mt-4"><img src="/images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">
                                Minimises supplier risk and reduces off contract spend.
                            </p></div>
                        </div>
                        </div>
                </div>

          </div>{/* <!---shadow end here---> */}

          {/* <!-------------------------------sim questionnaire end---------------------------------> */}
          {/* ==========================faqlist start============================ */}

          {faqData && (<div className="col-lg-6 m-auto py-3">
            <FaqLists {...faqData}/>
          </div>)
          }

          {/* ==========================faqlist end============================ */}
          {/* <!====================================text end================================>  */}

          <div className="container bgshadow">

          {/* <!==============================Product Cards start============================> */}
          <ClsProductCards></ClsProductCards>
          </div><br/><br/>

          {/* <!==============================Product Cards end==============================> */}

        {/* <!==============================case study with city start==============================>  */}

        {finalCaseStudyTeaser && (
          <CaseStudyTeaser {...finalCaseStudyTeaser} />
        )}

        {/* <!==============================case study with city End==============================>  */}

       <BlogHeader />

        {/* <!==============================blog Cards start==============================>   */}

        <BlogListing bloglist={bloglist} />

        {/* <!==============================blog Cards end==============================>   */}



     <CarouselHeader/>

       <div className="container mt-4">
         <div className="container col-lg-12">
           {
             blogCardData&& <MiniCard data={blogCardData} />
           }

         </div>
         </div>


        <CtaStripe />
      </div>
    </DocumentMeta>
)

}
export default ContactManagement;
