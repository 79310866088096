import React from "react"
import Slider from "react-slick"

const MiniCard = (props) => {
 const {data} = props
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <img src="../images/Arrows/left-arrow.svg" alt="prevArrow" {...props} />
  )

  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <img src="../images/Arrows/right-arrow.svg" alt="nextArrow" {...props} />
  )
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      initialSlide: 0,
      swipeToSlide: true,
      adaptiveHeight: true,
      // rows:1,
      // slidesPerRow:1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            // dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ],
      prevArrow: <SlickArrowLeft />,
      nextArrow: <SlickArrowRight />,
    }
    return (
     <div className="">
   <Slider {...settings} className="" style={{backgroundColor:''}}>
          {data.map((item, index) => {
            return (
                <div className="p-3 h-25">
                <div className="card mini-blogcard-wrapper text-center shadow shadow-offset-down-md shadow-offset-left-sm">
               {
                 item.__typename==="BlogPost"?

                <a href={`/insights/${item.slug}`}>
              <div
              className="card-body "
              key={index}
              >
                <div className="card-text  text-center text-truncate-container" style={{backgroundColor:''}}>
                <p>{item.title}</p>
                </div>
              </div>
              </a>
            :
            <a href={`/page/${item.slug}`}>
            <div
            className="card-body "
            key={index}
            >
              <div className="card-text  text-center text-truncate-container" style={{backgroundColor:''}}>
              <p>{item.pageBanner.bannerText}</p>
              </div>
            </div>

            </a>
            }
              </div>
             </div>
            )
          })}
        </Slider>
     </div>
    )
  }
export default MiniCard
