import React from 'react'
import CaseStudyIndex from './CaseStudyIndexChild'
import client from './Contentful'

 class CaseStudy extends React.Component{
    constructor (){
        super()
        this.state = {articles:[]}
    }
   componentDidMount () {

       client.getEntries({content_type: 'casestudy' , order:'fields.casestudyorderpriority'}).then((response)=>{
           this.setState({articles: response.items})

       })


   }
    render (){
         const articles =  this.state.articles.map((article , i)=> <CaseStudyIndex Key={i} id={i} article={article} />)
        return (
       <div>
           <div>
                {articles}
          </div>
          </div>
        )
    }
}
export default CaseStudy;
