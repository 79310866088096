import React from 'react';
import Faq from 'react-faq-component';
import "./index.css";


 const FaqLists = (props) => {
    const faqData = props.listCollection.items;
    // console.log("faqdat:", faqData)
    const faqArray = faqData.map(elm =>
        ({
          title: elm.question,
          content: elm.answerText
        })
      );
  // console.log("resultedArray", faqArray)
  let  faqData1= []


  faqData1 = {
          rows: faqArray
    }

  const styles = {
     bgColor: 'none',
    titleTextColor: '#2976a6',
    rowTitleColor: "#2976a6",
    rowContentColor: '#a2a6ac',
    arrowColor: '#f1873b',
  };

  const config = {
      // animate: true,
      // arrowIcon: "V",
      // tabFocus: true
  };
    return (
      <div className="faq-style-wrapper container">
         <h3 className="font-weight-bold faq-title"><hr id="arcus" />Frequently Asked Questions</h3>
         <Faq data={faqData1} styles={styles} config={config} />
      </div>
    )
  }

  export default FaqLists
