import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import CtaStripe from './CtaStripe'
import CaseStudyTeaser from './CaseStudyTeaser'
import BlogListing from './BlogListing'
import BlogHeader from './BlogHeader'
import client from './Contentful'
import DocumentMeta from 'react-document-meta'
import FaqLists from './FaqLists'
import CarouselHeader from './CarouselHeader'
import MiniCard from './MiniBlogCard'
import Loader from './Loader'

const FoodIndustry =() => {

  const [relatedBlogs, setRelatedBlogs] = useState([])
  const [extraBlogs,setExtraBlogs] = useState([])
  const [caseStudy, setCaseStudy] = useState([])

  const location = useLocation()

  const faqMappingUrl = location.pathname;
  const faqListQuery = gql`
  query FaqLists ($url: String!){
    addOnFaqListCollection(where:{addOnFaqListSlug: $url}){
      items{
        faqList{
          title
          listCollection{
            items{
              question
              answerText
            }
          }
        }
      }
    }
  }
  `


  const blogpostCardMiniQuery = gql`
query BlogpostCardMini {
  blogPostCollection(where:{blogPostProduct_contains_all:"PIM" ,SEOIndex:true} order:[publishDate_DESC]){
    total
    items{
      title
      slug

    }
  }

}
`
const pageBuildQuery = gql`
query PageBuildQuery{
pageBuilderCollection(where: {SEOIndex:true, pageBuilderProduct_contains_all:"PIM"} ){
 total
 items{
   title
   slug
   __typename
   pageBanner {
     bannerText
   }
 }
}
}`


  const { data:faqDataMain } = useQuery(faqListQuery, {
    skip: !location || !location.pathname,
    variables: {
      url:
        location && location.pathname
          ? faqMappingUrl.slice(1)
          : null,
    },
  });



  const { data:blogGraphData } = useQuery(blogpostCardMiniQuery)
  const {data: pagebuilderData} = useQuery(pageBuildQuery)

  const faqData = faqDataMain?.addOnFaqListCollection?.items[0]?.faqList

  const seoData = faqDataMain?.addOnFaqListCollection?.items[0]?.faqList?.listCollection?.items


  let mainEntity = []
   seoData&&
    seoData.map((res,id)=> {
    return mainEntity[id] = {
        "@type": "Question",
        "name": res.question,
        "acceptedAnswer": {
          "@type": "Answer",
          "text": res.answerText,
        }
      }
    })

 let schema = mainEntity.length === 0 ? null : {
     "@context": "https://schema.org",
     "@type": "FAQPage",
     "mainEntity": mainEntity
   }

  useEffect(() => {
    client.getEntries({
      content_type: 'blogPost',
      order:'-fields.publishDate',
      'fields.blogPostProduct[nin]': 'PIM',
      limit: 3
    })
    .then((response)=>{
     const latestBlogs = response.items.map(blg => {
        return {
          title: blg.fields.title,
          date: blg.fields.publishDate,
          shortDesc: blg.fields.description,
          bloglink: blg.fields.slug,
          imageSrc: blg.fields.heroImage.fields.file.url,
          altDesc: blg.fields.heroImage.fields.description,
          estimatedreadtime: blg.fields.estimatedreadtime
        }
      })
      setExtraBlogs(latestBlogs)
    }).catch(console.error)

    client.getEntries({
      content_type: 'blogPost',
      order:'-fields.publishDate',
      'fields.blogPostProduct': 'PIM',
      limit: 3
    })
    .then((response)=>{
     const initialBlogs = response.items.map(blg => {
        return {
          title: blg.fields.title,
          date: blg.fields.publishDate,
          shortDesc: blg.fields.description,
          bloglink: blg.fields.slug,
          imageSrc: blg.fields.heroImage.fields.file.url,
          altDesc: blg.fields.heroImage.fields.description,
          estimatedreadtime: blg.fields.estimatedreadtime
        }
      })
      setRelatedBlogs(initialBlogs)
    }).catch(console.error)

    client.getEntries({
      content_type: 'casestudy',
      order: 'fields.casestudyorderpriority',
      'fields.casestudyproduct': 'PIM',
      limit: 1
    })
    .then((response) => {
      const caseStudyData = response.items.map(csItem => {
        return {
          slug: csItem.fields.slug,
          nameofquotedperson:csItem.fields.nameofquotedperson,
          quotedpersonjobtitle:csItem.fields.quotedpersonjobtitle,
          organisation:csItem.fields.organisation,
          calloutquote:csItem.fields.calloutquote,
          extractforteaser:csItem.fields.extractforteaser
        }
      })
      setCaseStudy(caseStudyData[0])
    } )
    .catch(console.error)

  },[])




  if (!faqDataMain || !blogGraphData || !pagebuilderData) {
    return <Loader />;
  }


  const blogBoxData = blogGraphData?.blogPostCollection?.items

  const pageBuildData = pagebuilderData?.pageBuilderCollection?.items

  const blogCardData = [...blogBoxData, ...pageBuildData]




    const bloglist = (relatedBlogs.length < 3) ? [...relatedBlogs,...extraBlogs].slice(0, 3) : relatedBlogs

    const meta = {
      title: 'Rasp: Food Industry Software - Product & Supplier Management',
      description: 'Our food industry software ensures exceptional supplier and product management with the collection of data, documentation, and certifications from suppliers.',
      meta: {
        charset: 'utf-8',
        name: {
          keywords: 'Food industry software'
        }
      }
    }

        return (
          <DocumentMeta {...meta}>
            {schema && (
              <div
              dangerouslySetInnerHTML={{
                __html: `
                  <script type="application/ld+json">${JSON.stringify(
                    schema
                  )}</script>
                  `
                }}
              />
            )}
        <div>
               <div className="bg-food">
        <br /><br /><br />
        <div className="container">
          <div className="row">
            <div className="col-lg-9 txt mt-3 ml-3 mx-lg-3 mx-xl-3">
              <p className="row ml-lg-1 pl-lg-0 pl-md-5 ml-md-5"><h3><hr id="arcus" className="m-lg-0 mt-n5 pb-2" />ARCUS in the Food Industry</h3><i className="fa fa-utensils fa-2x mt-sm-3 pt-lg-0 pt-sm-3 ml-3" /></p>
              <h4 className="mt-2">Software For Food Makers</h4>
            </div>
            <div className="col-lg-3" /></div>
        </div>
        <div className="container">
          <div className="col-lg-8 txt mt-4 mx-lg-2 mx-xl-2">
            <div className="row d-none d-sm-inline-flex">
              <div className="col-sm-6 col-xs-12">
              <img src="images/Product Logos/PIM Logo.svg" className="img-fluid mt-2" alt="ARCUS Product Information Management logo" />
              </div>
               {/*Button below used to visible only in mobile <button type="button" class="btn btn-block btn-primary d-lg-none   mt-n2 mb-4">READ THE CUSTOMER STORY</button>*/}
              <div className="col-sm-6 col-xs-12">
              <img src="images/Product Logos/SIM Logo.svg" className="img-fluid mt-2" alt="ARCUS Supplier Information Management Logo" />
              </div>
            </div>
            {/* For mobile only */}
            <div className="row d-inline-flex d-sm-none">
            <div className="col-sm-6 col-xs-12">
              <img src="images/SIM PIM Vertical Align.svg" className="img-fluid mt-2" alt="ARCUS Product Information Management and Supplier Information Management logos" />
              </div>
            </div>
            <div className="container col-lg-4" />
          </div>
        </div>

        <div className="container mt-3">
          <div className="col-lg-7 txt  text-justify">
            <p className="clr">
               ARCUS is pioneering software for food makers. Through the ARCUS suite’s real-time Product Information Management module and its endless customisation options, more organisations within the Food Industry are turning to ARCUS to improve operational practices and to support the quality, compliance and integrity of ingredients and supplies.
            </p>
          </div>
        </div>
      </div>

      <div className="container mt-4">
      <div className="text-center mt-5">
        <h3 className="font-weight-bold "><hr id="arcus" />What is it?</h3>
      </div>
      <div className="text-left center mt-5">
          <p className="clr">ARCUS supplier and product management software provides quality and technical professionals within the food and drink sector with hands-off collection and verification of large amounts of information, documentation and certifications from suppliers. This ensure that approved suppliers deliver the services, ingredients or products promised, whilst complying with government legislation, industry guidelines and company policies at the best market value.</p>
      </div>

         <div className="container-fluid p-0">
            <div className="mt-5">
            <div className="row reverse">
                <div className="col-lg-6 m-auto">
                <div className="row">
                    <div className="col-lg-10">
                    <div className="mt-5 ml-3">
                      <h3 className="font-weight-bold "><hr id="arcus-left" />The Challenge</h3>
                    </div>
                    <p className="clr ml-3 mt-4">Companies in the food and beverage sector often have long and complex supply chains, meaning that managing these and ensuring compliance throughout the supply base can be a difficult and time-consuming process. By the very nature of the business, food and beverage companies source ingredients, products and services from across the globe to ensure best value and quality to customers whilst minimising health risks. This requires a substantial amount of administration, including paperwork, telephone calls and emails to suppliers to highlight and verify supply chain risks. Some of these compliance risks include allergens, modern slavery, anti-bribery, sustainability, nutrition, veganism, Halal and Kosher, as well as supplier manufacturing site accreditation and insurances.</p>
                    </div></div></div>
                    <div className="col-lg-6">
                    <div className="col-xs-12 text-right">
                      <img src="images/DominosLogo.png" className="img-fluid mt-2" alt="Dominos Logo" />
                    </div>
                  <p className="font-weight-lighter text-left font-italic clr  pl-lg-5 text6">"As a team we now have one data source instead of a number of local spreadsheets, this is fully searchable and means we can immediately access approved supplier and product information."<br /><br />
                    <span className="small text3">Helen Allan, Head of Technical, Domino’s Pizza UK</span></p>
                </div>
            </div>
            </div>
         </div>
      </div>
    <div className="container bgshadow">
      <div className="container">
        <div className="row reverse">
        <div className="col-lg-5 text-center  p-sm-1">
          <div className="col-xs-12 text-right">
            <img src="images/AAKlogo.svg" width="150" className="img-fluid mt-2" alt="AAK Logo" />
          </div>
          <p className="font-weight-lighter text-left font-italic clr mt-lg-3 pl-lg-5 text6">"Using SIM and the plugins, we now have the ability to quickly and efficiently find all the information we have on a supplier, their sites and the products they supply. We can demonstrate to our customers and regulatory bodies during external audits that we have collected and evaluated the information. The audit trail of information is crucial in demonstrating we have taken the steps to mitigate risks and to ensure the highest standards of quality and ethnical sourcing in our business"<br /><br />
          <span className="small text3">Gunilla Gustafsson, Quality and Food Safety, AAK Sweden</span></p>
        </div>
        <div className="col-lg-5 mt-3 px-3 m-auto">
            <div className="mx-4 mt-sm-4 text-left center">
            <h2 className="font-weight-bold"><hr id="arcus" className="m-lg-0 mt-n5 pb-2" />Benefits To Food Makers</h2>
            </div>

            <div className="mt-4"><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">
            Save Time and Improve Accuracy - Automate supplier on-boarding with no re-keying of data required
            </p></div>
            <div className="mt-4"><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">
            Transparency - Central repository for supplier information at a supplier, manufacturing site and product level
            </p></div>
            <div className="mt-4"><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">
            Control - Bi-directional approval workflows increase efficiency of supplier approvals and management
            </p></div>
            <div className="mt-4"><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">
            Reduce Risk - Protect brand reputation and manage supplier risk
            </p></div>
            <div className="mt-4"><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">
            Visibility - Full visibility across sourcing, supplier relationships and products in a single platform
            </p></div>
            <div className="mt-4"><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">
            Integration with key industry systems including recipe management, risk and contract tools
            </p></div>
        </div>
        </div>
      </div>

      <div className="container-fluid mt-lg-4">
        <div className="row reverse">
        <div className="col-lg-6 text-center  p-sm-1">
          <div className="col-xs-12 text-lg-right">
            <img src="images/Group 192.png" className="img-fluid mt-2" alt="Two food quality experts taste testing" />
          </div>
        </div>
        <div className="col-lg-6 mt-3 px-3 m-auto">
            <div className="mx-4 mt-sm-4 text-left center">
            <h2 className="font-weight-bold"><hr id="arcus" className="m-lg-0 mt-n5 pb-2" />ARCUS gives you this</h2>
            </div>

            <div className="mt-4"><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">
            A fully auditable product management system
            </p></div>
            <div className="mt-4"><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">
            A robust product and supplier approval database
            </p></div>
            <div className="mt-4"><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">
            Seamless collaboration between departments
            </p></div>
            <div className="mt-4"><img src="images/Icons/Orange Tick.svg" className="float-left img-fluid mt-2" alt="" /><p className="clr ml-5">
            Elemination of silios of product information
            </p></div>
        </div>
        </div>
      </div>
    </div>
      <div className="container mt-4">
      <div className="mt-5">
        <h3 className="font-weight-bold "><hr id="arcus-left" />For quality and technical professionals</h3>
      </div>
      <div className="text-left center mt-3 mb-lg-5">
          <p className="clr">ARCUS product and supplier management software has been proven to work in the food and beverage sector, and delivers supply base confidence for the likes of Whitbread, Costa, TGI Fridays, Domino’s, and AAK through a streamlined solution. ARCUS has been developed to provide the additional depth of functionality required by heavily regulated industries such as Food and Beverage.</p>
          <p className="clr">In addition to the core functionality of ARCUS, which works across all industries, food and beverage organisations can benefit from industry-specific plug-ins such as Manufacturing Site Information and Manufacturing Site Audit, and modules such as Product Information Management and New Product Development.</p>
          <p className="clr">ARCUS has been designed and evolved by working with organisations in the food and beverage industry. This has helped us to address some of the major pain points professionals working in quality and technical face when sourcing suppliers and products to meet existing and future needs.</p>
          <p className="clr">This Software as a Service solution enables professionals in multiple departments to collect, evaluate and manage information from their direct suppliers. All the information is stored in the central database which enables all stakeholders and relevant professionals to access the data at any time (based on company controlled access levels) and removes silos of information.</p>
          <p className="clr">It is vital that food and beverage companies evidence the provenance and contents of the products they purchase. This can include the location of production or manufacture, the processes in place at each manufacturing site, potential contamination risks, complete ingredient lists, packaging and storage requirements.</p>
          <p className="clr">Ensuring that suppliers are providing accurate and up-to-date information is essential. Using artificial intelligence, ARCUS® can scan key food and beverage documentation as it is uploaded by suppliers, verifying that the right certificate has been submitted and that the dates and values are valid.</p>
      </div>
      </div>
        {/* ==========================faqlist start============================ */}

          {faqData && (<div className="col-lg-6 m-auto py-3">
            <FaqLists {...faqData}/>
          </div>)
          }

          {/* ==========================faqlist end============================ */}
      {caseStudy && (
        <CaseStudyTeaser {...caseStudy} />
      )}

        {/* <!======================================Milestone 3 End==========================================> <!=====================================Milestone 4 start==========================================>  */}
             <BlogHeader />
        {/*======================================Blogs===============================================*/}

            <BlogListing bloglist={bloglist} />

        {/*======================================Blogs End===============================================*/}


        <CarouselHeader/>

       <div className="container mt-4">
         <div className="container col-lg-12">
           {
             blogCardData&& <MiniCard data={blogCardData} />
           }

         </div>
         </div>

        <CtaStripe />
        </div>
        </DocumentMeta>
        );


}


export default FoodIndustry;
