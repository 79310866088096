import React from 'react'
import PropTypes from 'prop-types'

const CarouselHeader = ({title}) => {

  return (
  <div className="container text-center pt-5"><h5 className="font-weight-bold "><hr id="arcus" />{title}</h5>

    </div>
  )
}

CarouselHeader.defaultProps = {
  title: "More Insights and Information",

}

CarouselHeader.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string
}

export default CarouselHeader
