import React from 'react'
import PropTypes from 'prop-types'
// import {dateFormatter} from '../../Utils/dateFormatter'

const BlogCard = ({altDesc, title , date, shortDesc, imageSrc, bloglink, blogLinkText, estimatedreadtime, viewMode}) => {

  const viewModeClass = viewMode === 'mobile' ? 'col-sm-12 mb-5' : 'col-lg-4 mb-5'
  const viewModeInnerClass = viewMode === 'mobile' ? 'card text-center fixed-height shadow' : 'card text-center mt-5 fixed-height shadow'
  return (
    <div className={viewModeClass}>
      <div className={viewModeInnerClass}>
        <img className="card-img-top img-fluid" src={imageSrc} alt={altDesc} />
          <div className="card-body ">
            <h5 className="card-title text-left">{title}</h5>
            <p className="card-text text-left small">{`Read Time: ${estimatedreadtime} minutes`}</p>
            <p className="card-text text-left text-truncate-container">{shortDesc}</p>
            <a href={`/insights/${bloglink}`} className="btn btn-primary cbutton ">{blogLinkText}</a>
          </div>
      </div>
    </div>
  )
}

BlogCard.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  shortDesc: PropTypes.string,
  bloglink: PropTypes.string,
  blogLinkText: PropTypes.string,
  imageSrc: PropTypes.string,
  viewMode: PropTypes.string
}

BlogCard.defaultProps = {
  blogLinkText: "READ POST",
  bloglink: "#",
  shortDesc: "",
  imageSrc: "images/Clip-Group.png",
  viewMode: "desktop"
}

export default BlogCard
