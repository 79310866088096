import React from 'react'

class ClsProductCards extends React.Component {

    render() {
        return (
            //--------------------------Products Cards----------------------------------------------->
            <div>
        <div className="container col-lg-12 text-center">
        <h3 className="font-weight-bold "><hr id="arcus" />Our Solutions</h3>
                   <p className="clr px-lg-5">Our cloud-based ARCUS Supplier Management platform features the following easily-configurable <br /> modules that are fast to deploy and simple to use.</p>
          <div className="row">
            <div className="col-lg-4 mt-4">
             <a href="/etender"> <div className="card p-2 h-100 shadow">
                <div className="card-header cardheader" />
                <img className="w-75 mx-auto img-fluid" src="/images/Product Logos/eRFX Logo.svg" alt="ARCUS eRFx system logo" />
                <div className="card-body">
                  <p className="card-text">A robust online tender system for Request for Proposals, Tenders, and Quotes. Easy to use, this is a powerful, probity compliant tool to help streamline and centralise your sourcing processes.</p>
                </div>
                <div className="card-footer"><a href="/etender" className="btn btn-primary mb-3">DISCOVER MORE</a></div>
              </div></a></div>
            <div className="col-lg-4 mt-4">
            <a href="/supplier-information-management"><div className="card h-100 p-2 shadow">
                <div className="card-header cardheader" />
                <img className="w-75 mx-auto img-fluid" src="/images/Product Logos/SIM Logo.svg" alt="ARCUS Supplier Information Management Logo" />
                <div className="card-body">
                  <p className="card-text">Effortlessly record and manage information about suppliers and contractors. SIM creates immediate efficiencies on traditionally time-intensive and error-prone manual tasks.</p>
                </div>
                <div className="card-footer"><a href="/supplier-information-management" className="btn btn-primary mb-3">DISCOVER MORE</a></div>
              </div></a>
            </div>
            <div className="col-lg-4 mt-4">
            <a href="/product-information-management"><div className="card h-100 p-2 shadow">
                <div className="card-header food ml-auto">
                  <div className="row">
                    <div className="lh">food <br /> industry</div>
                    <div><i className="fa fa-utensils fa-2x" /></div>
                  </div>
                </div>
                <img className="w-75 mx-auto img-fluid" src="/images/Product Logos/PIM Logo.svg" alt="ARCUS Product Information Management Logo" />
                <div className="card-body">
                  <p className="card-text">Supplier product information automatically driven by the system and self-maintained by your suppliers while providing full visibility across your organisation.</p>
                </div>
                <div className="card-footer"><a href="/product-information-management" className="btn btn-primary mb-3">DISCOVER MORE</a></div>
              </div></a></div>
          </div>
        </div>
        <div className="container col-lg-12 text-center">
          <div className="row">
            <div className="col-lg-4 mt-4">
            <a href="/reverse-auction-eauction"><div className="card h-100 p-2 shadow">
                <div className="card-header cardheader" />
                <img className="w-75 mx-auto img-fluid" src="/images/Product Logos/eAuction Logo.svg" alt="ARCUS Reverse Auction Logo" />
                <div className="card-body">
                  <p className="card-text">Efficiently negotiate prices in real-time through e-Auction. We’re regional leaders within the price negotiation space, having successfully managed hundreds of events.</p>
                </div>
                <div className="card-footer"><a href="/reverse-auction-eauction" className="btn btn-primary mb-3">DISCOVER MORE</a></div>
              </div></a></div>
            <div className="col-lg-4 mt-4">
            <a href="/contract-management"><div className="card h-100 p-2 shadow">
                <div className="card-header cardheader" />
                <img className="w-75 mx-auto img-fluid" src="/images/Product Logos/SCM Logo.svg" alt="ARCUS Supplier Contract Management Logo" />
                <div className="card-body">
                  <p className="card-text">Securely store and manage your supplier contracts in one, central location. A custom contract Lifecycle Management system helping you with important events such as contract expiries and more.</p>
                </div>
                <div className="card-footer"><a href="/contract-management" className="btn btn-primary mb-3">DISCOVER MORE</a></div>
              </div></a></div>
            <div className="col-lg-4 mt-4">
            <a href="/supplier-performance-management"><div className="card h-100 p-2 shadow">
            <div className="card-header cardheader" />
                <img className="w-75 mx-auto img-fluid" src="/images/Product Logos/SPM Logo.svg" alt="ARCUS Supplier Performance Management Logo" />
                <div className="card-body">
                  <p className="card-text">Measure supplier performance against customisable key metrices. Track and evaluate suppliers performance against SLAs and KPIs which matter most to your organisation.</p></div>
                <div className="card-footer"><a href="/supplier-performance-management" className="btn btn-primary mb-3">DISCOVER MORE</a></div>
              </div></a></div>
          </div>
        </div>
      </div>
        );
    }
}
export default ClsProductCards;
