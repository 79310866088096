import React, {useState} from 'react'
import ModalVideo from 'react-modal-video'
import getVideoId from 'get-video-id'
import { Link } from 'react-router-dom'

const Button = ({ ...btnData }) => {
    const buttonFields = btnData.fields !== undefined ? btnData.fields: btnData
    const [isOpen, setOpen] = useState(false)
    const outlineClass = buttonFields.buttonFill ? 'btn-primary' : 'btn-outline-primary speak'
    const downloadData = () => {
     const PDF= (btnData&&btnData.fields)? buttonFields?.buttonActionAsset.fields.file.url : buttonFields?.buttonActionAsset.url
      fetch(PDF)
        .then(response => {
          response.blob().then(blob => {
            let url = window.URL.createObjectURL(blob)
            let a = document.createElement('a')
            a.href = url
            var sub = PDF.substr(92)
            a.download = sub
            a.click()
          });
      });
    }

  if (typeof btnData.fields !== 'undefined') {
    if (typeof btnData.fields !== 'undefined'&& Object.keys(btnData).length !== 0 && (["View Video"].includes(buttonFields.buttonAction[0]))) {
        const { id, service } = getVideoId(buttonFields.buttonActionUrl)
        return (
            <>
        <ModalVideo channel={service} autoplay isOpen={isOpen} videoId={id} onClose={() => setOpen(false)} />
       <button  className = {` ${outlineClass} btn my-5 spacing video-btn`} onClick={()=> setOpen(true)}>{buttonFields.buttonLabel}</button>
        </>)
    }
    else if (typeof btnData.fields !== 'undefined' && Object.keys(btnData).length !== 0 && buttonFields.buttonAction[0] === "Go To URL") {
        return (<div className="container text-center mt-4">
             <Link to={{ pathname:buttonFields.buttonActionUrl}} target='_blank'>
      <button  type="button" className={`${outlineClass} btn`}>{buttonFields.buttonLabel}</button>
      </Link>
        </div>)
    }
    else if (typeof btnData.fields !== 'undefined' && Object.keys(btnData).length !== 0 && buttonFields.buttonAction[0] === "Download Asset (Doc, Preso, Image)") {
        return ( <div className="container text-center mt-4">
                            <button
                              onClick={downloadData}
                              className={` ${outlineClass} btn`}
                            >
                          {buttonFields.buttonLabel}
                            </button>
                        </div>)
      }
  }

  if (btnData && btnData.__typename !== 'undefined') {
    if (btnData.buttonAction !== 'undefined' && (["View Video"].includes(btnData.buttonAction[0]))) {
      const { id, service } = getVideoId(btnData.buttonActionUrl)
      return (
        <>
          <ModalVideo channel={service} autoplay isOpen={isOpen} videoId={id} onClose={() => setOpen(false)} />
          <button className={` ${outlineClass} btn my-5 spacing video-btn`} onClick={() => setOpen(true)}>{btnData.buttonLabel}</button>
        </>)
    }
    else if (btnData.buttonAction[0] !== 'undefined' && btnData.buttonAction[0] === "Go To URL") {
      return (<div className="container text-center mt-4">
        <Link to={{ pathname: btnData.buttonActionUrl }} target='_blank'>
          <button type="button" className={`${outlineClass} btn`}>{btnData.buttonLabel}</button>
        </Link>

      </div>)
    }
    else if (btnData.buttonAction[0] !== 'undefined' && btnData.buttonAction[0] === "Download Asset (Doc, Preso, Image)") {
      return (<div className="container text-center mt-4">
        <button
          onClick={downloadData}
          className={` ${outlineClass} btn`}
        >
          {btnData.buttonLabel}
        </button>
      </div>)
    }
  }
    }
export default Button
