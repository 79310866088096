import React from 'react'
import PropTypes from 'prop-types'
import BlogCard from '../BlogCard'

const BlogListing = ({bloglist, view}) => {

  return (
    <div className="container mt-4">
      <div className="container col-lg-12">
        <div className="row">
        { bloglist.map(blogdata => {
          return <BlogCard {...blogdata} viewMode={view} />
          }) }
          </div>
          </div>
          </div>
  )

}

BlogListing.propTypes = {
  bloglist: PropTypes.arrayOf(PropTypes.any).isRequired,
  view: PropTypes.string
}

BlogListing.defaultProps = {
  view: 'desktop'
}

export default BlogListing
