import React from 'react'

import './loader.css'

const Loader = (props)=> {
    return (
    <>
     <div className="overlay"></div>
        <div className="divout">
         <div className="">
          <div className="divin d-flex justify-content-center align-items-center  w-1/4  p-3 border border-gray-400">
            <div className="lds-roller">
               <div></div>
               <div></div>
               <div></div>
               <div></div>
               <div></div>
               <div></div>
               <div></div>
               <div></div>
            </div>
            <div className="loadtxt">Loading...</div>
          </div>
         </div>
         <div >
        </div>
        </div>
     </>
    )
}
export default Loader
