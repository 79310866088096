import React from 'react'
import { useLocation } from 'react-router-dom'
import { useQuery, gql } from "@apollo/client"
import BlogListing from '../BlogListing'
import CtaStripe from '../CtaStripe'
import BlogHeader from '../BlogHeader'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import DocumentMeta from 'react-document-meta'
import { useHistory } from 'react-router-dom';
import Loader from '../Loader'
import CarouselHeader from '../CarouselHeader'
import MiniCard from '../MiniBlogCard'
import FaqLists from '../FaqLists'


const CaseStudyDetails = () => {
  const location = useLocation();
  const caseUrl = location.pathname
  const history = useHistory()


  const faqMappingUrl = location.pathname



  const currentCaseStudy = gql`
  query CurrentCaseStudy ($url: String!){
    casestudyCollection(where:{slug: $url}){
    total
      items{
        casestudyproduct
        doesPdFexist
        downloadablePdf{
          url
        }
        seoIndex
        titleTag
        metaDescription
        canonical
        mainimage{
          url
        }
        productlogo{
          url
          description
        }
        organisation
        logo{
          url
          description
        }
        title
        category
        location
        suppliers
        strategy
        industry
        backgrounder{
          json
        }
        thechallengeimage{
          url
          description
        }
        challenge{
          json
        }
        solution{
          json
        }
        thesolutionimage{
          url
          description
        }
        theoutcomesimage{
          url
          description
        }
        outcomes{
          json
        }
        doesPdFexist
      }
    }
  }
  `
  //new
  const FaqListsQuery = gql`
  query FaqLists ($url: String!){
    addOnFaqListCollection(where:{addOnFaqListSlug: $url}){
      items{
        faqList{
          title
          listCollection{
            items{
              question
              answerText
            }
          }
        }
      }
    }
  }
  `

  const currentBlog = gql`
  query Currentblog ($url: [String!]){
    blogPostCollection(where:{blogPostProduct_contains_all:$url} order:publishDate_DESC limit:3){
      items{
       slug
        publishDate
        estimatedreadtime
        heroImage{
          url
          description
        }
        description
        title
      }
    }
  }
  `

  const extraBlogsQuery = gql`
  query Extrablogs ($url: [String!]){
    blogPostCollection(where:{blogPostProduct_contains_none:$url} order:publishDate_DESC limit:3){
      items{
       slug
        publishDate
        estimatedreadtime
        heroImage{
          url
          description
        }
        description
        title
      }
    }
  }
  `
  const blogpostCardMiniQuery = gql`
  query BlogpostCardMini ($url: [String!]){
    blogPostCollection(where:{SEOIndex:true, blogPostProduct_contains_all:$url} order:publishDate_DESC){
      total
      items{
        title
        slug
      }
    }
  }
  `
  const PageBuildQuery = gql`
  query pageBuildQuery ($url: [String!]){
    pageBuilderCollection(where: {SEOIndex:true, pageBuilderProduct_contains_all:$url} ){
     total
     items{
       title
       slug
       __typename
       pageBanner {
         bannerText
       }
     }
    }
    }
`
  const { data:caseData } = useQuery(currentCaseStudy, {
    skip: !location || !location.pathname,
    variables: {
      url:
        location && location.pathname
          ? caseUrl.substr(caseUrl.lastIndexOf('/') + 1)
          : null,
    },
  })
 //new
  const { data:faqFetch } = useQuery(FaqListsQuery, {
    skip: !location || !location.pathname,
    variables: {
      url:
        location && location.pathname
          ? faqMappingUrl.slice(1)
          : null,
    },
  });



  const { data:relatedBlgData } = useQuery(currentBlog, {
    skip: !caseData || !location.pathname,
    variables: {
      url:
      typeof caseData!== 'undefined'
          ? caseData?.casestudyCollection?.items[0]?.casestudyproduct[0]
          : {},
    },
  })


  const { data:extraBlgData } = useQuery(extraBlogsQuery, {
    skip: !caseData  || !location.pathname,
    variables: {
      url:
      typeof caseData!== 'undefined'
          ? caseData?.casestudyCollection?.items[0]?.casestudyproduct[0]
          : null,
    },
  })

  const { data:blogGraphData } = useQuery(blogpostCardMiniQuery, {
    skip: !caseData || !location.pathname,
    variables: {
      url:
      typeof caseData!== 'undefined'
          ? caseData?.casestudyCollection?.items[0]?.casestudyproduct[0]
          : {},
    },
  })

  const {data: pagebuilderData} = useQuery(PageBuildQuery, {
    skip: !caseData || !location.pathname,
    variables: {
      url:
      typeof caseData!== 'undefined'
          ? caseData?.casestudyCollection?.items[0]?.casestudyproduct[0]
          : {},
    },
  })

  if(!caseData || !relatedBlgData  || !extraBlgData ||!blogGraphData|| !faqFetch ||!pagebuilderData) return(<Loader/>)
  //new
  const faqData = faqFetch?.addOnFaqListCollection?.items[0]?.faqList

  const seoData = faqFetch?.addOnFaqListCollection?.items[0]?.faqList?.listCollection?.items



  let mainEntity = []
   seoData&&
    seoData.map((res,id)=> {
    return mainEntity[id] = {
        "@type": "Question",
        "name": res.question,
        "acceptedAnswer": {
          "@type": "Answer",
          "text": res.answerText,
        }
      }
    })

 let schema = mainEntity.length === 0 ? null : {
     "@context": "https://schema.org",
     "@type": "FAQPage",
     "mainEntity": mainEntity
   }

  //end

  const caseStudy = caseData?.casestudyCollection?.items[0]
  const blogBoxData = blogGraphData?.blogPostCollection?.items
  const pageBuildData = pagebuilderData?.pageBuilderCollection?.items

  const blogCardData = [...blogBoxData, ...pageBuildData]

  const {downloadablePdf,seoIndex, titleTag, metaDescription, canonical, mainimage, productlogo,organisation, logo, title, category, location:locationCase, suppliers, strategy, industry, backgrounder, thechallengeimage, challenge, solution, thesolutionimage, theoutcomesimage, outcomes, doesPdFexist} = caseStudy || {}

  const formattedRichTextBackgrounder = (typeof backgrounder !== 'undefined') ? documentToReactComponents(backgrounder.json) : ''
  const formattedRichTextChallenge = (typeof challenge!== 'undefined') ? documentToReactComponents(challenge.json) : ''
  const formattedRichTextSolution = (typeof solution !== 'undefined') ? documentToReactComponents(solution.json) : ''
  const formattedRichTextOutcomes = (typeof outcomes !== 'undefined') ? documentToReactComponents(outcomes.json) : ''

  const relatedBlogs = relatedBlgData?.blogPostCollection?.items.map(blg => {
    return {
        title: blg.title,
        date: blg.publishDate,
        shortDesc: blg.description,
        bloglink: blg.slug,
        imageSrc: blg.heroImage.url,
        altDesc:blg.heroImage.description,
        estimatedreadtime: blg.estimatedreadtime
    }
})

const extraBlogs = extraBlgData?.blogPostCollection?.items.map(blg => {
    return {
        title: blg.title,
        date: blg.publishDate,
        shortDesc: blg.description,
        bloglink: blg.slug,
        imageSrc: blg.heroImage.url,
        altDesc: blg.heroImage.description,
        estimatedreadtime: blg.estimatedreadtime
    }
})


const bloglist = (relatedBlogs?.length < 3) ? [...relatedBlogs,...extraBlogs].slice(0, 3) : relatedBlogs

 const downloadEmployeeData = () => {
  const PDF= downloadablePdf?.url;
    fetch(PDF)
      .then(response => {
        response.blob().then(blob => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          var sub = PDF.substr(92);
          a.download = sub;
          a.click();
        });
    });
  }


const seoMeta = !seoIndex ? { robots: 'noindex, follow' } : {}

  const meta = {
    title: titleTag,
    description: metaDescription,
    canonical: canonical,
    meta: {
      charset: 'utf-8',
      name: seoMeta,
    }
  }

let imgurl = (typeof mainimage !== 'undefined') ? mainimage.url : ""

const dataFetched = caseData?.casestudyCollection?.items?.length!==0? 'render' :null

  return (
    <>
    {schema && (
      <div
      dangerouslySetInnerHTML={{
        __html: `
          <script type="application/ld+json">${JSON.stringify(
            schema
          )}</script>
          `
         }}
      />
     )}

      {dataFetched === null && (history.push('/case-study-index'))}
      {dataFetched === 'render' && (<DocumentMeta {...meta}>
          <div>
          {/* ====================================text start================================ */}
          <div className="container pt-3 mt-5 text-center">
            <div className="row">
              <div className="col-lg-4 m-auto">
                <img
                  src={(typeof productlogo !== 'undefined') ? productlogo.url :""}
                  className="img-fluid mt-2 imgmxw"
                  alt={productlogo.description}
                />
              </div>
              <div className="col-lg-4 text-center center m-auto">
                <h2 className="font-weight-bold">
                  <hr id="arcus" className="text-center" />
                  Customer Story{" "}
                </h2>
                <h3>{organisation}</h3>
              </div>
              <div className="col-lg-4 text-center center m-auto pl-lg-4">
                <img
                  src={(typeof logo !== 'undefined')  ? logo.url : ""}
                  className="img-fluid mt-2 imgmxw"
                  alt={logo.description}
                />
              </div>
            </div>
          </div>
          {/*========================================Background image===========================================*/}
          <div
            className="container-fluid mt-5 bgimage8 position-relative"
            style={{
              // backgroundImage: "url(" + imgurl + ")",
              backgroundImage: "url(" + imgurl + ")",
              backgroundRepeat: "no-repeat",
              height: "550px",
              backgroundPosition: "center top",
            }}
          ></div>
          {/*========================================End Background image===========================================*/}
          <div className="container-fluid text-center curvedtop p-5 position-relative">
            <h4 className="font-weight-bold my-5">
              <hr id="arcus" />
              {title}
            </h4>
            <div className="row">
              <div className="col-lg-1" />
              <div className="col-lg-2 mt-4">
                <div className="card h-100 bg-transparent border-0">
                  <img
                    src="/images/CaseStudyIcons/Category Icon.svg"
                    className="img-fluid mx-auto mb-3"
                    alt="Card"
                  />
                  <div className="card-body pt-1 p-1">
                    <h5>Category</h5>
                    <p className="card-text mb-5">{category}</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 mt-4">
                <div className="card h-100 bg-transparent border-0">
                  <img
                    src="/images/CaseStudyIcons/Location Icon.svg"
                    className="img-fluid mx-auto mb-3"
                    alt="Card"
                  />
                  <div className="card-body pt-1 p-1">
                    <h5>Location</h5>
                    <p className="card-text mb-5">{locationCase}</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 mt-4">
                <div className="card h-100 bg-transparent border-0">
                  <img
                    src="/images/CaseStudyIcons/Suppliers Icon.svg"
                    className="img-fluid mx-auto mb-3"
                    alt="Card"
                  />
                  <div className="card-body pt-1 p-1">
                    <h5>Suppliers</h5>
                    <p className="card-text mb-5">{suppliers}</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 mt-4">
                <div className="card h-100 bg-transparent border-0">
                  <img
                    src="/images/CaseStudyIcons/Strategy Icon.svg"
                    className="img-fluid mx-auto mb-3"
                    alt="Card"
                  />
                  <div className="card-body pt-1 p-1">
                    <h5>Strategy</h5>
                    <p className="card-text mb-5">{strategy}</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 mt-4">
                <div className="card h-100 bg-transparent border-0">
                  <img
                    src="/images/CaseStudyIcons/Industry Icon.svg"
                    className="img-fluid mx-auto mb-3"
                    alt="Cards"
                  />
                  <div className="card-body pt-1 p-1">
                    <h5>Industry</h5>
                    <p className="card-text mb-5">{industry}</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-1" />
            </div>
          </div>
          {/* ==========================background text start============================ */}
          <div className="container mt-5 text-center">
            <h4 className>
              <hr id="arcus" />
              Background
            </h4>
            <p className="clr">{formattedRichTextBackgrounder}</p>
          </div>

          <div className="container-fluid grad">
            <div className="container mt-5">
              <div className="row">
                <div className="col-lg-6 text-center m-auto">
                  <img
                    src={(typeof thechallengeimage !== 'undefined') ? thechallengeimage.url : ""}
                    className="img-fluid caseradius"
                    alt={thechallengeimage.description}
                  />
                </div>
                <div className="col-lg-6 text-center m-auto">
                  <img
                    src="/images/CaseStudyIcons/The Challenge Icon.svg"
                    className="img-fluid my-2"
                    alt=""
                  />
                  <h4>
                    <hr id="arcus" />
                    The Challenge
                  </h4>
                  <p className="clr text-left">{formattedRichTextChallenge}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid grad2">
            <div className="container mt-5">
              <div className="row reverse">
                <div className="col-lg-6 text-center m-auto">
                  <img
                    src="/images/CaseStudyIcons/The Solution Icon.svg"
                    className="img-fluid my-2"
                    alt=""
                  />
                  <h4>
                    <hr id="arcus" />
                    The Solution
                  </h4>
                  <p className="clr  text-left">{formattedRichTextSolution}</p>
                </div>
                <div className="col-lg-6 text-center m-auto">
                  <img
                    src={(typeof thesolutionimage !== 'undefined') ? thesolutionimage.url : ""}
                    className="img-fluid caseradius"
                    alt={thesolutionimage.description}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid grad">
            <div className="container mt-5">
              <div className="row">
                <div className="col-lg-6 text-center m-auto">
                  <img
                    src={(typeof theoutcomesimage !== 'undefined') ? theoutcomesimage.url : ""}
                    className="img-fluid caseradius"
                    alt={theoutcomesimage.description}
                  />
                </div>
                <div className="col-lg-6 text-center m-auto">
                  <img
                    src="/images/CaseStudyIcons/The Outcomes Icon.svg"
                    className="img-fluid my-2"
                    alt=""
                  />
                  <h4>
                    <hr id="arcus" />
                    The Outcomes
                  </h4>
                  <p className="clr text-left">{formattedRichTextOutcomes }</p>
                </div>
              </div>
            </div>
          </div>

          {doesPdFexist === true ? (
            <div className="container text-center mt-5">
              <button
                onClick={downloadEmployeeData}
                className="btn btn-primary p x-5"
              >
                DOWNLOAD THE FULL CUSTOMER STORY NOW
              </button>
            </div>
          ) : null}
           {/* ==========================faqlist start============================ */}

        {faqData?<div className="col-lg-6 m-auto py-3">
          <FaqLists {...faqData}/>
        </div>:''}

        {/* ==========================faqlist end============================ */}

          {/* ==========================background text end============================ */}
          <BlogHeader />
          {/* ==============================blog Cards start============================== */}
        {bloglist&&<BlogListing bloglist={bloglist} />}
          {/* ==============================blog Cards end============================== */}

        <CarouselHeader/>

        <div className="container mt-4">
          <div className="container col-lg-12">
            {
              blogCardData&& <MiniCard data={blogCardData} />
            }

          </div>
          </div>

          <CtaStripe />
        </div>
      </DocumentMeta>

      )
    }
      </>
  )
}

export default CaseStudyDetails
