import React from 'react'

const Footer = () =>{

  return (
    <footer>
      <div className=" mt-5">
        {/* Grid row */}
        <div className="row m-0">
          {/* Grid column */}
          <div className="col-6 col-12 col-md-6 col-lg-3 mx-auto mb-4">
            {/* Content */}
          <a href="./"><img src="/images/RaspLogoOnly360px.png" className="img-fluid w-75" alt="" /></a>
          </div>
          {/* Grid column */}
          <div className="col-6 col-12 col-md-6 col-lg-3 mx-auto mb-4 ">
            {/* Links */}
            <h6 className="text-uppercase font-weight-bold">Products</h6>
            <hr id="arcus" align="left" />
            <p>
              <a href="/supplier-information-management" className="clr2">Supplier Management</a>
            </p>
            <p>
              <a href="/product-information-management" className="clr2">Product Information Management</a>
            </p>
             <p>
              <a href="/sourcing-management" className="clr2">Strategic Sourcing Management</a>
            </p>
            <p>
              <a href="/etender" className="clr2">e-Tender e-RFx</a>
            </p>
            <p>
              <a href="/reverse-auction-eauction" className="clr2">Reverse Auction</a>
            </p>
            <p>
              <a href="/contract-management" className="clr2">Contract Management</a>
            </p>
            <p>
              <a href="/supplier-performance-management" className="clr2">Supplier Performance Management</a>
            </p>
          </div>
          {/* Grid column */}
          <div className="col-6 col-12 col-md-6 col-lg-3 pl-md-5 mb-4">
            {/* Links */}
            <h6 className="text-uppercase font-weight-bold">Menu</h6>
            <hr id="arcus" align="left" />
            <p>
              <a href="/supplier-management-platform" className="clr2">Solutions</a>
            </p>
            <p>
              <a href="/about-us" className="clr2">About</a>
            </p>
            <p>
              <a href="/case-study-index" className="clr2">Customer Stories</a>
            </p>
            <p>
              <a href="/insights" className="clr2">Insights</a>
            </p>
            <p>
              <a href="/contact-us" className="clr2">Contact</a>
            </p>
            <p>
              <a href="/privacy" className="clr2">Privacy Policy</a>
            </p>
          </div>
          {/* Grid column */}
          <div className="col-6 col-12 col-md-6 col-lg-3 mx-auto mb-md-0 mb-4 ">
            {/* Links */}
            <h6 className="text-uppercase font-weight-bold text-left">Contacts</h6>
            <hr id="arcus" align="left" />
            <div className="row">
              <div className="col-1">
                <i className="fas fa-home clr3" /></div>
              <div className="col-10">
                <p>122 Arthur Street North Sydney Australia</p>
              </div>
            </div>
            <div className="row">
              <div className="col-1">
                <i className="fas fa-phone fa-flip-horizontal clr3" /></div>
              <div className="col-10">
                <p>Aus: +61 (0)2 8090 9060</p>
              </div>
            </div>
            <div className="row">
              <div className="col-1">
                <i className="fas fa-phone fa-flip-horizontal clr3" /></div>
              <div className="col-10">
                <p>NZ: +64 (0)9 871 0660</p>
              </div>
            </div>
            <div className="row">
              <div className="col-1">
                <i className="fas fa-envelope clr3" /></div>
              <div className="col-10">
              <a href="/contact-us" ><p>Send us a message</p> </a>
              </div>
            </div>
          </div>
          {/* Grid column */}
        </div>
        {/* Grid row */}
      </div>
      <div className="footer-copyright text-center py-3">Copyright © Rasp. All rights reserved.
      </div>
    </footer>
  )
}

export default Footer
