import React from "react";
import { useLocation } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import DocumentMeta from "react-document-meta";

import BtnStripe from "../BtnStripe";
import BlogListing from "../BlogListing";
import BlogHeader from "../BlogHeader";
import CtaStripe from "../CtaStripe";
import ClsProductCards from "../ProductCards";
import CaseStudyTeaser from "../CaseStudyTeaser";
import FaqLists from "../FaqLists/index";
import IntroBanner from "../IntroBanner/index";
import TextAndImage from "../TextAndImage";
import Loader from "../Loader";


// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import CarouselHeader from "../CarouselHeader";
import MiniCard from "../MiniBlogCard";


const PageBuilder = () => {
  const location = useLocation();
  const path = location.pathname;
  const pageBuilderCol = gql`
    query PageBuilderCol($url: String!) {
      pageBuilderCollection(where: { slug: $url }) {
        items {
          ... on PageBuilder {
            contentSlicesCollection(limit: 12) {
              items {
                __typename
                ... on TextAndMedia {
                  sideImage1: sideImage {
                    url
                    fileName
                  }
                  textBlockTitle1: textBlockTitle
                  textContent1: textContent {
                    json
                  }
                  textLayout1: textLayout
                  btnData1: button {
                    buttonAction
                    buttonActionUrl
                    buttonActionAsset {
                      fileName
                      url
                      title
                      sys {
                        id
                      }
                    }
                    buttonFill
                    buttonInternalId
                    buttonLabel
                  }
                  textLayout1: textLayout
                  textJustification1: textJustification
                }
                ... on FaqList {
                  listCollection(limit: 8) {
                    items {
                      question
                      answerText
                    }
                  }
                }
                ... on PageDividerCta {
                  buttonCollection(limit: 4) {
                    items {
                      buttonAction
                      buttonActionAsset {
                        fileName
                        url
                        title
                        sys {
                          id
                        }
                      }
                      buttonActionUrl
                      buttonFill
                      buttonInternalId
                      buttonLabel
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  const pageBuilderMeta = gql`
    query PageBuilderMeta($url: String!) {
      pageBuilderCollection(where: { slug: $url }, limit: 5) {
        items {
          #pageBuilderData
          ... on PageBuilder {
            title
            slug
            contentSlicesCollection(limit: 3) {
              items {
                ... on TextAndMedia {
                  textContent {
                    json
                  }
                }
              }
            }
            addComponents
            pageBuilderProduct

            #pageMetaData
            titleTag
            metaDescription
            canonical
            seoIndex

            #bannerContent
            pageBanner {
              bannerImage {
                url
              }
              bannerText1: bannerText
              bannerLogo1: bannerLogo {
                url
              }
              bannerTextBox {
                json
              }
            }
          }
        }
      }
    }
  `;

  const blogPostQuery = gql`
    query BlogPosts($productType: [String!]) {
      blogPostCollection(
        where: { blogPostProduct_contains_all: $productType }
        order: publishDate_DESC
        limit: 3
      ) {
        items {
          title
          publishDate
          description
          slug
          heroImage {
            url
          }
          estimatedreadtime
        }
      }
      casestudyCollection(
        where: { casestudytags_contains_some: $productType }
        order: [casestudyorderpriority_ASC]
        limit: 1
      ) {
        items {
          slug
          nameofquotedperson
          quotedpersonjobtitle
          organisation
          calloutquote
          extractforteaser
        }
      }
    }
  `;

  const extraBlogPost = gql`
    query BlogPosts($productType: [String!]) {
      blogPostCollection(
        where: { blogPostProduct_contains_none: $productType }
        order: publishDate_DESC
        limit: 3
      ) {
        items {
          title
          publishDate
          description
          slug
          heroImage {
            url
          }
          estimatedreadtime
        }
      }
      casestudyCollection(
        where: { casestudytags_contains_all: $productType }
        order: [casestudyorderpriority_ASC]
        limit: 1
      ) {
        items {
          slug
          nameofquotedperson
          quotedpersonjobtitle
          organisation
          calloutquote
          extractforteaser
        }
      }
    }
  `;




  const blogpostCardMiniQuery = gql`
  query BlogpostCardMini($productType: [String!]) {
    blogPostCollection(
      where: { blogPostProduct_contains_all: $productType }
      order: publishDate_DESC

    ) {
      items {
        title
        slug
      }
    }
  }
  `

  const extraBlogpostCardMiniQuery = gql`
  query ExtraBlogpostCardMini($productType: [String!]) {
    blogPostCollection(
      where: { blogPostProduct_contains_none: $productType }
      order: publishDate_DESC

    ) {
      items {
        title
        slug
      }
    }

    pageBuilderCollection(where: {SEOIndex:true}){
      total
      items{
        title
        slug
        __typename
        pageBanner {
          bannerText
        }
      }
  }

  }
  `

const faqList = gql`
query PageBuilderCol($url: String!) {
  pageBuilderCollection(where: { slug: $url }) {
    items {
      ... on PageBuilder {
        contentSlicesCollection(limit: 12) {
          items {
            __typename
            ... on FaqList {
              listCollection(limit: 8) {
                items {
                  question
                  answerText
                }
              }
            }
          }
        }
      }
    }
  }
}
`


  const { data: pageBuildCollection } = useQuery(pageBuilderCol, {
    skip: !location || !location.pathname,
    variables: {
      url:
        location && location.pathname
          ? path.substr(path.lastIndexOf("/") + 1)
          : null,
    },
  });

  const { data: pageBuildMeta } = useQuery(pageBuilderMeta, {
    skip: !location || !location.pathname,
    variables: {
      url:
        location && location.pathname
          ? path.substr(path.lastIndexOf("/") + 1)
          : null,
    },
  });

  const { data: blogPostMain } = useQuery(blogPostQuery, {
    skip: !pageBuildMeta || !pageBuildMeta.pageBuilderCollection.items,
    variables: {
      productType:
        pageBuildMeta && pageBuildMeta.pageBuilderCollection
          ? pageBuildMeta.pageBuilderCollection.items[0].pageBuilderProduct[0]
          : null,
    },
  });

  const { data: extraBlogData } = useQuery(extraBlogPost, {
    skip: !pageBuildMeta || !pageBuildMeta.pageBuilderCollection.items,
    variables: {
      productType:
        pageBuildMeta && pageBuildMeta.pageBuilderCollection
          ? pageBuildMeta.pageBuilderCollection.items[0].pageBuilderProduct[0]
          : null,
    },
  });



  const { data:blogGraphData } = useQuery(blogpostCardMiniQuery, {
    skip: !pageBuildMeta || !pageBuildMeta.pageBuilderCollection.items,
    variables: {
      productType:
        pageBuildMeta && pageBuildMeta.pageBuilderCollection
          ? pageBuildMeta.pageBuilderCollection.items[0].pageBuilderProduct[0]
          : null,
    },
  })


  const { data:extraBlogGraphData } = useQuery(extraBlogpostCardMiniQuery, {
    skip: !pageBuildMeta || !pageBuildMeta.pageBuilderCollection.items,
    variables: {
      productType:
        pageBuildMeta && pageBuildMeta.pageBuilderCollection
          ? pageBuildMeta.pageBuilderCollection.items[0].pageBuilderProduct[0]
          : null,
    },
  })

  const { data: faqData } = useQuery(faqList, {
    skip: !location || !location.pathname,
    variables: {
      url:
        location && location.pathname
          ? path.substr(path.lastIndexOf("/") + 1)
          : null,
    },
  });



  if (!pageBuildCollection || !pageBuildMeta || !blogPostMain || !extraBlogData || !blogGraphData || !extraBlogGraphData) {
    return <Loader />;
  }



  const blogBoxData = blogGraphData?.blogPostCollection?.items

  const extraBlogBoxData = extraBlogGraphData?.blogPostCollection?.items

  const pageBuildData = extraBlogGraphData?.pageBuilderCollection?.items
  const { pageBuilderCollection } = pageBuildCollection;
  const textAndMediaData =
    pageBuilderCollection.items[0].contentSlicesCollection.items;
const seoData = faqData.pageBuilderCollection.items[0].contentSlicesCollection.items.filter((fs) => fs.__typename === 'FaqList');


const blgPostMainData = blogPostMain.blogPostCollection;
  const caseStudyDataCurrent = blogPostMain.casestudyCollection;

  const extraBlogPostData = extraBlogData.blogPostCollection;

  const caseStudy = caseStudyDataCurrent.items.map((csItem) => ({
    slug: csItem.slug,
    nameofquotedperson: csItem.nameofquotedperson,
    quotedpersonjobtitle: csItem.quotedpersonjobtitle,
    organisation: csItem.organisation,
    calloutquote: csItem.calloutquote,
    extractforteaser: csItem.extractforteaser,
  }));

  const relatedBlogs = blgPostMainData.items.map((blg) => ({
    title: blg.title,
    date: blg.publishDate,
    shortDesc: blg.description,
    bloglink: blg.slug,
    imageSrc: blg.heroImage.url,
    estimatedreadtime: blg.estimatedreadtime,
  }));

  const extraBlogs = extraBlogPostData.items.map((blg) => ({
    title: blg.title,
    date: blg.publishDate,
    shortDesc: blg.description,
    bloglink: blg.slug,
    imageSrc: blg.heroImage.url,
    estimatedreadtime: blg.estimatedreadtime,
  }));


  let mainEntity = []
  seoData&&
   seoData.map((res)=> {
    const { listCollection } = res;
    return listCollection&& listCollection.items.map((items, key) => {
      return mainEntity[key] = {
        "@type": "Question",
        "name": items.question,
        "acceptedAnswer": {
          "@type": "Answer",
          "text": items.answerText,
        }
      }
    })
   })

   let schema = mainEntity.length === 0 ?  null : {
     "@context": "https://schema.org",
     "@type": "FAQPage",
     "mainEntity": mainEntity
   }


  const bloglist =
    relatedBlogs.length < 3
      ? [...relatedBlogs, ...extraBlogs].slice(0, 3)
      : relatedBlogs;

  const pageD = pageBuildMeta.pageBuilderCollection.items[0];




  const blogCardData = [...blogBoxData, ...extraBlogBoxData, ...pageBuildData]


  const {
    addComponents,
    canonical,
    metaDescription,
    pageBanner,
    titleTag,
    seoIndex,
  } = pageD;

  const seoMeta = !seoIndex ? { robots: "noindex, follow" } : {};

  const meta = {
    title: titleTag,
    description: metaDescription,
    canonical,
    meta: {
      charset: "utf-8",
      name: seoMeta,
    },
  };

  return (
    <DocumentMeta {...meta}>
      {schema && (
      <div
      dangerouslySetInnerHTML={{
        __html: `
          <script type="application/ld+json">${JSON.stringify(
            schema
          )}</script>
          `
         }}
      />
     )}

      <IntroBanner {...pageBanner} />
      <div className="bgshadow3">
        {textAndMediaData &&
          textAndMediaData.map((item) => {
            if (item.__typename === "TextAndMedia") {
              return <TextAndImage {...item} />;
            }
            if (item.__typename === "FaqList") {
              return (
                <div className="col-lg-6 m-auto py-3">
                  <FaqLists {...item} />
                </div>
              );
            }
            if (item.__typename === "PageDividerCta") {
              const button = item.buttonCollection.items;
              return <BtnStripe buttons={button} />;
            }
            return true;
          })}
      </div>
      {/* <!---shadow end here---> */}

      {/* <!-------------------------------sim questionnaire end---------------------------------> */}

      {/* <!====================================text end================================>  */}
      {addComponents && addComponents.includes("Product Cards Panel") && (
        <div className="container text-center mt-5 bgshadow">
          <ClsProductCards />
        </div>
      )}
      {/* <!==============================Product Cards end==============================> */}

      {addComponents && addComponents.includes("Case Study Teaser") && (
        <div className="container-fluid mt-5 p-0 bgimage">
          {caseStudy && <CaseStudyTeaser {...caseStudy[0]} />}
        </div>
      )}

      {/* <!==============================case study with city start==============================>  */}

      <div className="container-fluid mt-5 p-0 bgimage">
        {/*                 {caseStudy && <CaseStudyTeaser {...caseStudy} />} */}
      </div>

      {/* <!==============================case study with city End==============================>  */}

      {/* <!==============================blog Cards start==============================>   */}
      {addComponents && addComponents.includes("Insights (Blog) Teasers") && (
        <>
          <BlogHeader />
          <BlogListing bloglist={bloglist} />
        </>
      )}

      {/* <!==============================blog Cards end==============================>   */}
        <CarouselHeader/>

       <div className="container mt-4">
         <div className="container col-lg-12">
           {
             blogCardData&& <MiniCard data={blogCardData} />
           }

         </div>
         </div>
      <CtaStripe />
    </DocumentMeta>
  );
};
export default PageBuilder;
