import React from 'react'
import CaseStudy from '../Component/CaseStudyIndexParent'
import DocumentMeta from 'react-document-meta'

export default class CaseStudy_Index extends React.Component{

    render(){
        const meta = {
            title: 'Rasp: Explore Our Customer Stories To See Our System In Action',
            description: 'Explore our customer stories to see our supplier management and procurement modules in action. We help businesses around the world optimise their supply chains.',
          }
        return(
            <DocumentMeta {...meta}>
              <div>
                <div class="bgshadoCS">
                <div className="text-center mt-5">
                <hr id="arcus" /><h1>Customer Stories</h1>
                </div>
                {/*==================================== CaseStudyIndex ===============================*/}
                <CaseStudy/>
                </div>
                {/*==================================== CaseStudyIndex End===============================*/}
              </div>
           </DocumentMeta>
        );
    }
}
