/**
 *
 * @param {string} width
 *
 * @return {string} calculated px to scroll
 */

export const amountToScroll = (width) => {
  let scrollToPx = 0
  if (width > 1023) {
    // avg tile height is 500 and 9 tiles took 3 rows.
    scrollToPx = -500 * 3
  } else if (width < 1023 && width > 991) {
    //avg tile height is 600 and 9 tiles took 9 rows.
    scrollToPx = -600 * 9
  } else if (width < 991 && width > 323) {
    //avg tile height is 430 and 9 tiles took 9 rows.
    scrollToPx = -430 * 9
  } else {
    // handle extremely small devices
    scrollToPx = -575 * 9
  }

  return scrollToPx
}
