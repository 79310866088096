import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './web.config';
const { REACT_APP_SPACE_ID, REACT_APP_CDA_TOKEN } = process.env;
/* eslint-disable import/first */

import {ApolloProvider, ApolloClient, InMemoryCache} from '@apollo/client'

const client = new ApolloClient({
    cache: new InMemoryCache(),
    uri: `https://graphql.contentful.com/content/v1/spaces/${REACT_APP_SPACE_ID}`,
    headers: {
        'Authorization': `Bearer ${REACT_APP_CDA_TOKEN}`
    }
})

ReactDOM.render(
    <ApolloProvider client={client}>
        <App />

    </ApolloProvider>

    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
