import React from 'react'
import PropTypes from 'prop-types'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import {useMediaQuery} from '../../Utils/getScreenSize'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const IntroBanner = (props) => {

  const {bannerText1, bannerText, bannerLogo1, bannerLogo, bannerImg, bannerImage, bannerBodyText,  bannerTextBox} = props
  let divStyle = {
    ...(bannerImg && { backgroundImage: 'url(' + bannerImg + ')' }),
    ...(bannerImage && { backgroundImage: 'url(' + bannerImage.url + ')' }),

}
const [width] = useMediaQuery()
const isDesktop = (width > 1023)
 divStyle = (isDesktop) ? divStyle : []
  return (
    <div className="bg-intro-banner"  style={divStyle}>
    <br /><br /><br />
    <div className="container">
      <div className="col-lg-8 txt mt-4 mx-lg-2 mx-xl-2">
        <div className="row">
          {
          bannerLogo?
              <img src={bannerLogo} className="img-fluid mt-2 logo-img" alt="" />	:<></>
          }
          {
            bannerLogo1?
              <img src={bannerLogo1.url} className="img-fluid mt-2 logo-img" alt={bannerLogo1.description} />	:<></>
          }
        </div>
        <div className="container col-lg-4" />
      </div>
    </div>
    <div className="container">
      <div className="row m-3 mt-lg-5">
        <div className="col-lg-5">
        <>
          {
          bannerText?
            <div className="row ml-lg-1 pl-lg-0 ml-md-5"><h2><hr id="arcus" className="m-lg-0 mt-n5 pb-2" />{bannerText}</h2></div> :<></>
          }
          {
          bannerText1?
            <div className="row ml-lg-1 pl-lg-0 ml-md-5"><h2><hr id="arcus" className="m-lg-0 mt-n5 pb-2" />{bannerText1}</h2></div> : <></>
          }
         </>
         <>
         {
            bannerBodyText?
              <div className="mt-2" dangerouslySetInnerHTML={{ __html: documentToHtmlString(bannerBodyText) }}></div>  :<></>
          }
          {
            bannerTextBox?
              <div className="mt-2">{documentToReactComponents(bannerTextBox.json)}</div>   :<></>
          }
         </>
        </div>
        <div className="col-lg-7" /></div>
    </div>
  </div>

  )
}

IntroBanner.propTypes = {
  bannerBodyText: PropTypes.objectOf(PropTypes.any),
  bannerText: PropTypes.string.isRequired,
  bannerImg: PropTypes.string.isRequired,
  bannerLogo: PropTypes.string.isRequired,
}

IntroBanner.defaultProps = {
  bannerBodyText: {}
}

export default IntroBanner
